import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ActivatedRoute, RouterOutlet } from '@angular/router'
import { map } from 'rxjs'
import { FooterComponent } from '../../../dashboard-unregistered/components/footer/footer.component'
import { RegisteredFooterComponent } from '../../../layout/footer/footer.component'
import { MainLayoutComponent } from '../../../layout/main-layout/main-layout.component'
import { UserService } from '../../../misc/services/user/user.service'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    CommonModule,
    MainLayoutComponent,
    RouterOutlet,
    FooterComponent,
    LucilabCommonModule,
    RegisteredFooterComponent,
  ],
})
export class HomeComponent {
  public readonly userIsAnonymous = this.userService.user.anonymous
  public readonly footerEnabled$ = this.activatedRoute.data.pipe(map((data) => data.footerEnabled ?? true))

  constructor(
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}
}
