import { NgModule, Type } from '@angular/core'
import * as Bold from './bold.directive'
import * as Caption from './caption.directive'
import * as Label from './label.directive'
import * as Paragraph from './paragraph.directive'
import * as Title from './title.directive'
import { UnderlineDirective } from './underline.directive'

const components: Type<unknown>[] = [
  Bold.BoldDirective,
  Bold.SemiBoldDirective,
  UnderlineDirective,
  Caption.Caption1Directive,
  Caption.Caption2Directive,
  Caption.Caption3Directive,
  Caption.Caption4Directive,
  Caption.Caption5Directive,
  Label.Label1Directive,
  Label.Label2Directive,
  Label.Label3Directive,
  Label.Label4Directive,
  Paragraph.Paragraph1Directive,
  Paragraph.Paragraph2Directive,
  Paragraph.Paragraph3Directive,
  Title.Title1Directive,
  Title.Title2Directive,
  Title.Title3Directive,
  Title.Title4Directive,
]

@NgModule({
  imports: components,
  exports: components,
})
export class FontModule { }
