import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { DialogWrapperComponent } from '../components/dialog-wrapper/dialog-wrapper.component'

@Component({
  selector: 'app-external-link-dialog',
  templateUrl: './external-link-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    DialogWrapperComponent,
  ],
  standalone: true,
})
export class ExternalLinkDialogComponent {
  checked: boolean = false

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public snoozeDuration,
    public dialogRef: MatDialogRef<ExternalLinkDialogComponent>,
  ) { }
}
