import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { BannerComponent } from '../../banner/banner.component'

@Component({
  selector: 'lib-demo-banner',
  standalone: true,
  imports: [CommonModule, BannerComponent],
  templateUrl: './banner.component.html',
})
export class DemoBannerComponent {

}
