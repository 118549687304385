import { EDomain } from '@types'
import { EMoment } from './../../../objectives/types'

export type TVersion = string

export type TQuestionType = 'radio' | 'radio-image'

export type TQuestionCode = 'frequence' | 'portion' | 'details'

export enum EStatus {
  draft = 'draft',
  submitted = 'submitted',
}

export type TAttemptStatus = 'in-progress' | 'not-tried' | 'tried' | 'success'

export type TItem = {
  icon?: string
  image?: string
  internal_id: string
  name: string
  keywords?: string[]
  details?: string
  example?: string
  isCustom?: true
}

export type TGroup = {
  internal_id: string
  name: string
  category: string
  icon?: string
  image?: string
  items: TItem[]
}

export type TGroupItems = {
  domain: EDomain
  groups: TGroup[]
  version: TVersion
}

export type TFormSubmission<T> = {
  id?: number
  status: EStatus
  answers: T
  version: TVersion
  domain: EDomain
}

export type TIdea = {
  internal_id: string
  labels: string[]
  name: string
  quickFact: string
  example: string
  hints: { title: string; content: string }[]
}

export type TAttempt = {
  id: string
  status: TAttemptStatus
  moment: EMoment
  created: Date
  modified: Date
}

export type TToolbox = {
  id: string
  domain: EDomain
  icon: string
  name: string
  image: string
  bookmarks: string[]
  attempts: TAttempt[]
}
