import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { ButtonModule } from '../button'
import { FontModule } from '../font'

@Component({
  selector: 'lib-banner',
  standalone: true,
  imports: [CommonModule, FontModule, ButtonModule],
  templateUrl: './banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @HostBinding('class') get hostClass() {
    return this.mode
  }

  @Input() show = true
  @Output() showChange = new EventEmitter<boolean>()

  @Input() title: string
  @Input() description: string
  @Input() mode: 'danger' | 'warning' | 'info' = 'warning'

  @Input() primaryBtnLabel?: string
  @Input() secondaryBtnLabel?: string
  @Output() primaryBtnClick = new EventEmitter<void>()
  @Output() secondaryBtnClick = new EventEmitter<void>()


  close() {
    this.show = false
    this.showChange.emit(this.show)
  }
}
