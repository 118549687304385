import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { FacebookShareComponent } from './facebook-share.component'



@NgModule({
  declarations: [FacebookShareComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [FacebookShareComponent],
})
export class FacebookShareModule { }
