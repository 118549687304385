import { Component, Input } from '@angular/core'
import { isMobileDevice } from '../../helpers/is-mobile-device'

@Component({
  selector: 'app-messenger-share',
  templateUrl: './messenger-share.component.html',
  styles: [
  ],
})
export class MessengerShareComponent{

  @Input() url: string

  isMobile = isMobileDevice()

  constructor(
  ) { }

  shareOnMessenger(event?: Event){
    event?.stopPropagation()
    window.location.href = `fb-messenger://share/?link=${this.url}`
  }

}
