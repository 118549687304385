import { inject } from '@angular/core'
import { CanMatchFn, Router } from '@angular/router'
import { of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { getToken } from 'src/app/auth/utils/token-utils'
import { UserService } from '../services/user/user.service'

export const AdvisorGuardFn: CanMatchFn = () => {

  const router = inject(Router)
  const userServ = inject(UserService)

  if (getToken()) {
    return userServ.user$.pipe(
      switchMap(user => {
        if (user.is_coach) {
          return of(true)
        } else {
          router.navigate(['/dashboard'])
          return of(false)
        }
      }),
      take(1),
    )
  } else {
    router.navigate(['/auth'])
    return of(false)
  }

}
