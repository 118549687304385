
interface FeedBack {
  rating: number
  reasons?: string[]
  custom?: string
}

export interface RawFeedBack {
  rating: number,
  imageProblem: boolean
  soundProblem: boolean
  connectionProblem: boolean
  otherProblem: boolean
  comment: string
}


export const RawFeedBackToFeedBack = ( rawFeedBack: RawFeedBack ): FeedBack => {

  const feedBack: FeedBack = {
    rating: rawFeedBack.rating,
    reasons: getReasons(rawFeedBack),
  }

  if(rawFeedBack.comment && rawFeedBack.comment !== ''){
    feedBack.custom = rawFeedBack.comment
  }

  return feedBack
}

const getReasons = ( rawFeedBack: RawFeedBack ): string[] => {
  const reasons = []

  if(rawFeedBack.connectionProblem){
    reasons.push('connection')
  }

  if(rawFeedBack.imageProblem){
    reasons.push('image')
  }

  if(rawFeedBack.otherProblem){
    reasons.push('other')
  }

  if(rawFeedBack.soundProblem){
    reasons.push('sound')
  }

  return reasons
}
