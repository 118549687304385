import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { getToken } from 'src/app/auth/utils/token-utils'
import { UserService } from '../../misc/services/user/user.service'

export const participantGuardFn: CanActivateFn = (route) => {
  const userService = inject(UserService)
  const router = inject(Router)

  const hasToken = !!getToken(false)

  if (!hasToken) {
    return router.createUrlTree(['/public', 'dashboard', 'home', 'detail'], { queryParams: route.queryParams })
  }

  return userService.user$.pipe(
    map(({ is_coach }) => {
      if (is_coach) {
        return router.createUrlTree(['/coach'])
      }

      return true
    }),
  )
}
