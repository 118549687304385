import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'projects/environments/environment'
import { UserService } from '../services/user/user.service'



export const publicGuardFn = () => {
  const userService = inject(UserService)
  const router = inject(Router)

  if (userService.user.is_coach && !location.href.includes('article')) {
    return router.parseUrl('/coach')
  }
  if (environment.env === 'study') {
    return router.parseUrl('/')
  }
  return true
}
