import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[click]',
  standalone: true,
})
export class PendoButtonCtaDirective {
  @Input() set pendoClass(pendoClass: string) {
    const classes = pendoClass.split(' ')
    classes.forEach((currentClass) => {
      if (!!currentClass) {
        this.element.nativeElement.classList.add(`Pendo_${currentClass}`)
      }
    })
  }

  constructor(private element: ElementRef) {}
}
