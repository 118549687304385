import { Component, Input } from '@angular/core'
import { isMobileDevice } from '../../helpers/is-mobile-device'

@Component({
  selector: 'app-share-methods-container',
  templateUrl: './share-methods-container.component.html',
  styleUrls: [
    './share-methods-container.component.sass',
  ],
})
export class ShareMethodsContainerComponent {

  @Input() emailTitle: string
  @Input() emailMessageContent: string
  @Input() urlToShare: string
  isOpen = false
  isMobile = isMobileDevice()

  constructor() { }

}
