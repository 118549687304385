import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'projects/environments/environment'
import { Observable, combineLatest } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Appointment } from 'src/app/shared/interfaces/appointment'
import { RawFeedBack, RawFeedBackToFeedBack } from 'src/app/shared/interfaces/appointment-video-rating'
import { PaginatedObject } from 'src/app/shared/interfaces/paginated-object'
import { VonagePrecall } from 'src/app/shared/interfaces/vonage-precall.interface'
@Injectable({
  providedIn: 'root',
})
export class AppointmentService {

  constructor(private http: HttpClient) { }

  getCoachInDjangoByAcuityId(acuityId) {
    return this.http.get(`${environment.apiUrl}coaches/`)
  }

  getTestVonageToken() {
    return this.http.get<VonagePrecall>(`${environment.apiUrl}appointments/quality_test/`)
  }

  getTypes() {
    return this.http.get(`${environment.apiUrl}appointments/types/`)
  }

  getAllTypes() {
    return this.http.get(`${environment.apiUrl}appointmenttypes/`)
  }

  getAll(initial = false) {
    return this.http.get<PaginatedObject<Appointment>>(`${environment.apiUrl}appointments/?next_booking=${initial}`)
  }

  getAllDefault() {
    return this.http.get<PaginatedObject<Appointment>>(`${environment.apiUrl}appointments/`)
  }

  getAppointmentById(id, user) {
    return this.http.get<Appointment>(`${environment.apiUrl}appointments/${id}/?user=${user}`)
  }

  getAllAppointmentByParticipant(id, force?): Observable<PaginatedObject<Appointment>> {
    const fo = force ? '&force_booking=true' : ''
    return this.http.get<PaginatedObject<Appointment>>(`${environment.apiUrl}appointments/?user=${id}${fo}`)
  }

  addMonths(date, months) {
    const d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    return date
  }

  getAvailableDays(appointmentId?: number) {
    const obs$ = []
    for (let i = 0; i < 3; i++) {
      obs$.push(this.getTypes().pipe(
        switchMap((types: Array<any>) => {
          const typeId: any = appointmentId ? appointmentId : types[0].id
          return this.http.get(
            // tslint:disable-next-line:max-line-length
            `${environment.apiUrl}appointments/dates/?appointment_type=${typeId}&month=${this.addMonths(new Date(), i).getMonth() + 1}&year=${this.addMonths(new Date(), i).getFullYear()}`,
          )
        },
        ),
      ),
      )
    }
    return combineLatest(obs$)
  }

  getTime(date, idAppointment?, selectedType?, otherCoach?) {
    const idApt = idAppointment ? `&appointment_id=${idAppointment}` : ''
    const forceOther = otherCoach ? '&force_other=true' : ''
    if (selectedType) {
      return this.http.get(`${environment.apiUrl}appointments/times/?appointment_type=${selectedType.id}&day=${date}${idApt}${forceOther}`)
    } else {
      return this.getTypes().pipe(
        switchMap(types =>
          this.http.get(`${environment.apiUrl}appointments/times/?appointment_type=${types[0].id}&day=${date}${idApt}${forceOther}`),
        ),
      )
    }
  }

  /**
   *
   * @param appointment
   * @param options
   * forceOther is only for participant side
   * forceBooking is only for coach side
   * validate can be used for both side
   * @returns
   */
  setAppointment(appointment, options: { forceOther?: boolean, validate?: boolean, forceBooking?: boolean }) {
    let params = new HttpParams()
    if (options.forceOther) {
      params = params.set('force_other', options.forceOther)
    }

    if (options.validate) {
      params = params.set('validate', options.validate)
    }

    if (options.forceBooking) {
      params = params.set('force_booking', options.forceBooking)
    }
    return this.getTypes().pipe(
      switchMap(types => {
        if (!appointment?.appointment_type) {
          appointment.appointment_type = types[0].id
        }
        return this.http.post<Appointment>(`${environment.apiUrl}appointments/`, appointment, {
          params,
        })
      }),
    )
  }

  updateAppointment(appointment: Appointment, force = false) {
    const data = {
      date: appointment.date,
    }

    return this.getTypes().pipe(
      switchMap(types => {
        if (!appointment?.appointment_type) {
          appointment.appointment_type = types[0].id
        }

        return this.http.put<Appointment>(`${environment.apiUrl}appointments/${appointment.id}/`, data, { params: { force_other: force } })
      }),
    )
  }

  completeAppointment(appointment: Appointment) {
    const data = {
      action: 'complete',
      date: appointment.date,
    }
    return this.http.put(`${environment.apiUrl}appointments/${appointment.id}/`, data)
  }


  cancelAppointment(appointment, r = 're_scheduled', force?) {
    const fo = force ? '&force_booking=true' : ''
    const data = {
      action: 'cancel',
      date: appointment.date ? appointment.date : null,
      reason: r,
      // tslint:disable-next-line:max-line-length
      appointment_type: (appointment.appointment_type && appointment.appointment_type.id) ? appointment.appointment_type.id : appointment.appointment_type ? appointment.appointment_type : null, // ajout manu to fit gael
    }
    return this.http.put(`${environment.apiUrl}appointments/${appointment.id}/?user=${appointment.user}${fo}`, data)
  }

  realCancelAppointment(appointment, force?) {
    const fo = force ? '&force_booking=true' : ''
    return this.http.delete(`${environment.apiUrl}appointments/${appointment.id}/?user=${appointment.user}${fo}`)
  }

  checkIn(id, user?) {
    return this.http.get<Appointment>(`${environment.apiUrl}appointments/${id}/presence/?user=${user}`)
  }

  getICS(appointmentID) {
    return this.http.get(`${environment.apiUrl}appointments/${appointmentID}/ics/`, { responseType: 'blob' })
  }

  setAvailability(appointmentId, form) {
    return this.http.post(`${environment.apiUrl}appointments/${appointmentId}/availability/`, form)
  }

  checkAptById(id) {
    return this.http.get(`${environment.apiUrl}appointments/${id}/`)
  }

  changeAppointmentCoach(newCoachId: number, appointment) {
    appointment.coach = newCoachId
    return this.rescheduleAppointment({ ...appointment, appointment_type: appointment.appointment_type.id }, true)
  }

  rescheduleAppointment(appointment, force?, validate?) {
    const fo = force ? '&force_booking=true' : ''
    const va = validate ? '&validate=true' : ''
    appointment.action = 'update'
    appointment.reason = 're_scheduled'
    return this.http.put(`${environment.apiUrl}appointments/${appointment.id}/?user=${appointment.user}${fo}${va}`, appointment)
  }

  rateAppointmentVideo(appointmentId: number, feedBack: RawFeedBack) {
    return this.http.post(`${environment.apiUrl}appointments/${appointmentId}/quality_feedback/`, RawFeedBackToFeedBack(feedBack))
  }
}

