<div class='py-9 bg-white rounded-sp-34 mt-4'>
  <div class='px-9'>
    <p i18n class='font-buenos text-2xl text-center mb-6 font-bold'>Qu’avez-vous pensé de cet article?</p>
    <div class='sm:max-w-sm sm:m-auto'>
      <app-star-rating  [(ratingValue)]="rating" [lock]='lockRating'></app-star-rating>
      <div *ngIf='rating !== 0 && !isSubmit' class='my-4 sm:my-6'>
        <ng-container *ngTemplateOutlet='editRating'></ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="rating !== 0 && !isSubmit" class='text-center mb-5'>
    <span i18n *ngIf="rating >= 4; else lowerRatingText" class='font-sans text-base font-medium'>Qu’est-ce que cet
      article vous a apporté?</span>
    <ng-template #lowerRatingText>
      <span i18n class='font-sans text-base font-medium'>Qu'est-ce qui pourrait être amélioré?</span>
    </ng-template>
  </div>
  <form class='px-3 mt-2 sm:px-18 md:px-36 lg:px-48' [formGroup]="commentRatingForm" *ngIf="rating !== 0 && !isSubmit">
    <div class=' grid grid-cols-2 gap-2'>
      <ng-container *ngIf="rating <= 3; else higherRating">
        <app-checkbox-square formControlName='shorterText' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.shorterText" [label]="labels[0]"
          [selectedTextColor]="checkboxSquareColors.selectedTextColor"
          [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='simplify' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.simplify" [label]="labels[1]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='moreInfo' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.moreInfo" [label]="labels[2]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='addIllustrations' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.addIllustrations" [label]="labels[3]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
      </ng-container>

      <ng-template #higherRating>
        <app-checkbox-square formControlName='newInfo' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.newInfo" [label]="labels[4]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='knowMore' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.knowMore" [label]="labels[5]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='suggestions' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.suggestions" [label]="labels[6]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
        <app-checkbox-square formControlName='changeWillingness' [colors]='checkboxSquareColors'>
          <app-label-for-square-checkbox [selected]="commentRatingForm.value.changeWillingness" [label]="labels[7]"
            [selectedTextColor]="checkboxSquareColors.selectedTextColor"
            [defaultTextColor]="checkboxSquareColors.textColor"></app-label-for-square-checkbox>
        </app-checkbox-square>
      </ng-template>
    </div>
    <textarea class='w-full mt-2 p-2 rounded-2xl border border-grey-700 border-solid' formControlName='comment'
      [placeholder]="labels[8]" cols='50' rows='5'></textarea>
  </form>
  <div *ngIf="rating !== 0 && !isSubmit && commentRatingForm.errors === null"
    class='w-full flex justify-end items-center mt-4 px-3 sm:px-18 md:px-36 lg:px-48'>
    <button class='lu-btn lu-btn-orange flex justify-center items-center' (click)="submitComment()" pendoClass='send-article-comment'
      [disabled]="!commentRatingForm.dirty">
      <span i18n>Envoyer</span>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <div *ngIf="isSubmit" class='text-center mt-14'>
    <span i18n class='font-sans text-base font-medium'>Merci pour votre rétroaction!</span>
    <div class='mt-5'>
      <ng-container *ngTemplateOutlet='editRating'></ng-container>
    </div>

  </div>

</div>

<ng-template #editRating>
  <div class='flex justify-center items-center cursor-pointer' (click)="activeEdit()" pendoClass='edit-article-rating'>
    <mat-icon class='text-lg leading-none'>edit</mat-icon>
    <span i18n class='underline'>Éditer</span>
  </div>
</ng-template>
