<app-main-layout>
  <ng-container *ngIf="contentfulForm">

    <div class='bg-grey-1001 min-h-screen pb-12 lg:pb-0 '>

      <div class='bg-white mx-3 px-4 py-10 mt-7 rounded-3xl lg:mx-auto lg:mt-20 lg:max-w-screen-sm relative'>
        <div class='mb-10'>
          <p i18n class="font-buenos font-normal text-2xl text-center"
            [innerHTML]="titleQuestion.text | documentToText">
          </p>
        </div>
        <form class='flex flex-col lg:px-32' [formGroup]='form' (ngSubmit)='onSubmit()' autocomplete='off'>

          <ng-container *ngIf='loading'>
            <div class='overlay'>
              <div class='absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 '>
                <mat-spinner diameter='60' color='accent'></mat-spinner>
              </div>
            </div>
          </ng-container>

          <div formGroupName='age'>
            <mat-form-field class='w-full' appearance="outline">
              <mat-label i18n [innerHTML]="ageQuestion.question | documentToText"></mat-label>
              <mat-select formControlName='value'>
                <mat-option *ngFor='let option of ageQuestion.allowed_answers'
                  [value]='option.label'>{{option.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf='f.age.controls.value.errors?.required'>
                <span i18n>Veuillez choisir </span>
                <span [innerHTML]='(ageQuestion.question | documentToText | lowercase)'></span>
              </mat-error>
            </mat-form-field>
          </div>

          <div formGroupName='gender'>
            <mat-form-field class='w-full' appearance="outline">
              <mat-label i18n [innerHTML]="genderQuestion.question | documentToText"></mat-label>
              <mat-select formControlName='value' i18n-placeholder [placeholder]=''>
                <mat-option *ngFor='let option of genderQuestion.allowed_answers'
                  [value]='option.label'>{{option.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf='f.gender.controls.value.errors?.required'>
                <span i18n>Veuillez choisir </span>
                <span [innerHTML]='(genderQuestion.question | documentToText | lowercase)'></span>
              </mat-error>
            </mat-form-field>

            <mat-form-field class='w-full' *ngIf='f.gender.controls.other.enabled' appearance="outline">
              <mat-label i18n>Veuillez préciser</mat-label>
              <input matInput formControlName='other' />
              <mat-error
                *ngIf="f.gender.controls.other.errors">{{f.gender.controls.other.errors[f.gender.controls.id.value]}}
              </mat-error>
            </mat-form-field>
          </div>

          <mat-label id='customerAcquisition-label' i18n
            [innerHTML]="customerAcquisitionQuestion.question | documentToText"></mat-label>
          <div formGroupName='customerAcquisition'>
            <mat-form-field class='w-full' appearance="outline">
              <mat-select aria-labelledby='customerAcquisition-label' formControlName='value' i18n-placeholder
                [placeholder]=''>
                <mat-option *ngFor='let option of customerAcquisitionQuestion.allowed_answers'
                  [value]='option.label'>{{option.value}}</mat-option>
              </mat-select>
              <mat-error i18n *ngIf='f.customerAcquisition.controls.value.errors?.required'>
                Veuillez choisir votre référence
              </mat-error>
              <mat-error *ngIf="f.customerAcquisition.errors"></mat-error>
            </mat-form-field>

            <mat-form-field class='w-full' *ngIf='f.customerAcquisition.controls.other.enabled' appearance="outline">
              <mat-label i18n>Veuillez préciser</mat-label>
              <input matInput formControlName='other' />
              <mat-error
                *ngIf="f.customerAcquisition.controls.other.errors">{{f.customerAcquisition.controls.other.errors[f.customerAcquisition.controls.id.value]}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class='w-full flex mt-10 mb-3'>
            <button i18n type='submit' class='lu-btn lu-btn-orange pendo_step_two mx-auto'
              [disabled]='!form.valid || loading'>Continuer
            </button>
          </div>
        </form>
      </div>

    </div>
  </ng-container>
</app-main-layout>
