import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MessengerShareComponent } from './messenger-share.component'



@NgModule({
  declarations: [MessengerShareComponent],
  imports: [
    CommonModule,
  ],
  exports: [MessengerShareComponent],
})
export class MessengerShareModule { }
