import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'app-dialog-wrapper',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule],
  templateUrl: './dialog-wrapper.component.html',
})
export class DialogWrapperComponent {

  @Input()
  public canClose = true

  constructor(public dialogRef: MatDialogRef<any>) { }

}
