import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { GetSubmissionByIdPipe } from '../../pipes/get-submission-by-id.pipe'

import { EDomain } from '@types'
import { Observable } from 'rxjs'
import { GetAnswerTranslationPipe } from '../../../../life-habits/diet/pipes/get-answer-translation.pipe'
import {
  TranslationService as DietTranslationService,
  TranslationService
} from '../../../../life-habits/diet/translation.service'
import { LucilabCommonModule } from '../../../lucilab-common/lucilab-common.module'
import { LifeHabitsService } from '../../service'
import { TAssessment, TItem } from '../../types'

import { FindPipe } from '../../../pipes/find.pipe'

@Component({
  selector: 'app-assessment-answers',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, GetSubmissionByIdPipe, GetAnswerTranslationPipe, FindPipe],
  providers: [DietTranslationService, TranslationService],
  templateUrl: './assessment-answers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentAnswersComponent {
  private _assessment: TAssessment

  @Input({ required: true }) set assessment(assessment: TAssessment) {
    this._assessment = assessment

    this.intellectualItems$ = this.lifeHabitsService.getItems(EDomain.intellectual, {
      version: this.assessment.intellectual.version,
    })

    this.physicalItems$ = this.lifeHabitsService.getItems(EDomain.physical, {
      version: this.assessment.physical.version,
    })
  }

  get assessment(): TAssessment {
    return this._assessment
  }

  public intellectualItems$: Observable<TItem[]>

  public physicalItems$: Observable<TItem[]>

  public readonly itemsTranslation = this.translationService.itemTranslation

  constructor(
    private readonly lifeHabitsService: LifeHabitsService,
    private readonly translationService: TranslationService,
  ) {}
}
