import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FontModule } from '../font'
import { TValidationIndicator } from './type'

@Component({
  selector: 'lib-validation-indicator',
  standalone: true,
  imports: [CommonModule, FontModule],
  templateUrl: './validation-indicator.component.html',
  styleUrls: ['./validation-indicator.component.scss'],
})
export class ValidationIndicatorComponent {
  @Input() public outlinedIcon: boolean = false
  @Input() public validationIndicator: TValidationIndicator
}
