import { inject, LOCALE_ID } from '@angular/core'
import { environment } from 'environment/environment'
import { catchError, EMPTY, first, tap } from 'rxjs'
import { UserService } from '../misc/services/user/user.service'
import { User } from '../shared/interfaces/user'
import { CookiesManagementService } from '../shared/services/cookies-management.service'

export function initUserLanguageRedirection(userService: UserService, cookieService: CookiesManagementService) {
  const local_id = inject(LOCALE_ID)
  return () => userService.user$.pipe(
    first(user => !!user),
    tap((user) => {
      if (environment.env !== 'local' && !isCrawler() && Object.keys(user).length > 0 && user.prefered_lang !== local_id && !user.is_coach) {
        cookieService.setCookieByName('nf_lang', user.prefered_lang)
        redirect(user)
      }
    }),
    catchError(() => EMPTY),
  )
}


function redirect(user: User) {
  let url = location.href.split('/')

  if (url?.length > 3) {
    if (url[3].includes('en') || url[3].includes('fr')) {
      if (user.prefered_lang === 'fr') {
        url[3] = url[3].replace('en', 'fr')
      } else {
        url[3] = url[3].replace('fr', 'en')
      }
    }
  }

  location.href = url.join('/')
}

function isCrawler(): boolean {
  return window.navigator.userAgent
    .toLocaleLowerCase()
    .match('google|bot|lighthouse|spider|pinterest|crawler|archiver|flipboardproxy|mediapartners|facebookexternalhit|insights|quora|whatsapp|slurp|s4a') !== null
}
