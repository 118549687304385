import { AbstractControl, ValidationErrors, Validators } from '@angular/forms'

const emailRegexPattern = /^[a-zA-Z0-9._%-]+\+?[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const emailValidator = (control: AbstractControl): ValidationErrors => {

  const value = control.value

  if (!value) {
    return null
  }

  const regex = new RegExp(emailRegexPattern)

  return regex.test(value) && !Validators.email(control) ? null : { email: true }
}
