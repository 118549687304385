import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { environment } from 'projects/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CookiesManagementService {

  constructor(
    private cookieService: CookieService,
  ) { }

  setCookieByName(name: string, value: string): void {
    // remove useless but never know
    if (this.cookieService.get(name)) {
      this.cookieService.delete(name, '/')
    }

    this.cookieService.set(name, value,
      {
        path: '/',
        domain: environment.env === 'local' ? 'localhost' : 'luciapp.ca',
        secure: true,
        sameSite: 'Strict',
      })
  }

  getCookie(name) {
    return this.cookieService.get(name)
  }
}
