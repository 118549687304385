import { Location } from '@angular/common'
import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { catchError, map, of, tap } from 'rxjs'
import { removeQueryParam } from '../../shared/helpers/remove-query-param'
import { AccountService } from '../../shared/services/account.service'


export const CanActivateAccount = (wrongAccountRedirection?: string): CanActivateFn => {

  return (route, state) => {

    const accountService = inject(AccountService)
    const router = inject(Router)
    const location = inject(Location)

    const accountSlug = route.paramMap.get('account') || route.queryParamMap.get('account')

    if (!accountSlug) return true

    return accountService.getBySlug(accountSlug).pipe(
      tap(account => {
        accountService.setLocalAccount(account)
        route.data = { account: account }
      }),
      map(() => true),
      catchError(() => {

        if (wrongAccountRedirection) return of(router.createUrlTree([wrongAccountRedirection]))

        accountService.deleteLocalAccount()
        location.replaceState(removeQueryParam(state.url, 'account'))
        return of(true)
      }),
    )
  }
}
