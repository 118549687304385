import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, forwardRef, HostBinding, Injector, Input, OnDestroy, Provider } from '@angular/core'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { noop } from 'rxjs/internal/util/noop'
import { FontModule } from '../../../font'
import { RadioIndicatorComponent } from '../atoms/radio-indicator/radio-indicator.component'
import { SelectionBoxComponent } from '../atoms/selection-box/selection-box.component'

const RADIO_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DefaultRadioComponent),
  multi: true,
}

@Component({
  selector: 'lib-input-default-radio',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontModule, RadioIndicatorComponent, SelectionBoxComponent],
  templateUrl: './default-radio.component.html',
  styleUrls: ['./default-radio.component.scss'],
  providers: [RADIO_CONTROL_VALUE_ACCESSOR],
})
export class DefaultRadioComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @HostBinding('class') get theme() {
    return this.inverted ? 'inverted' : 'default'
  }

  @Input() inverted: boolean = false
  @Input() disabled: boolean = false
  @Input() label: string
  @Input({ required: true }) value: string

  public checked: boolean = false

  private destroyed$: Subject<void> = new Subject()

  private onChange: (value: string) => void = noop
  private onTouched: () => void = noop

  constructor(protected injector: Injector) {}

  ngAfterViewInit(): void {
    const control = this.injector.get(NgControl, null)
    if (control) {
      control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
        this.checked = value === this.value
      })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  writeValue(value: string): void {
    this.checked = value === this.value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  toggle(): void {
    if (!this.disabled) {
      this.checked = true
      this.onChange(this.value)
      this.onTouched()
    }
  }
}
