import { Component } from '@angular/core'
import { MattermostService } from '../../../shared/services/mattermost.service'

@Component({
  selector: 'app-snackbar-error-mattermost',
  templateUrl: './snackbar-error-mattermost.component.html',
  styleUrls: ['./snackbar-error-mattermost.component.sass'],
})
export class SnackbarErrorMattermostComponent{

  constructor(
    private mattermost: MattermostService,
  ) {
  }
}
