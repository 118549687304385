import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core'
import { environment } from 'projects/environments/environment'
import { of } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { LoginRes } from 'src/app/interfaces/login-res'
import { UserService } from 'src/app/misc/services/user/user.service'
import { Register } from 'src/app/shared/interfaces/register'
import { User } from 'src/app/shared/interfaces/user'

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly url: string = environment.apiAuthUrl

  constructor(
    private http: HttpClient,
    private userServ: UserService,
    private zone: NgZone,
    @Inject(LOCALE_ID) private local_id: string,
  ) { }

  login(form) {
    return this.http.post<User>(`${environment.apiAuthUrl}login/`, form).pipe(
      tap(({ key }: any) => {
        if (form.keep) {
          sessionStorage.removeItem('token')
          localStorage.setItem('token', key)
        } else {
          localStorage.removeItem('token')
          sessionStorage.setItem('token', key)
        }
      }),
      switchMap(() => this.userServ.getUser()),
    )
  }

  register(registerForm: Register) {
    return this.http.post<LoginRes>(`${environment.apiAuthUrl}registration/`, registerForm)
  }

  requiredBirthDate() {
    return this.http.get(`${environment.apiUrl}auth-requirements/`)
  }

  resetPassword(form) {
    return this.http.post(`${environment.apiAuthUrl}password/reset/`, form)
  }

  authTokenStatus(uid, token) {
    return this.http.get(`${environment.apiAuthUrl}password/reset/token_status/?uid=${uid}&token=${token}`).pipe(
      map((res) => res['status']))
  }

  setNewPassword(form, preferred_timezone: string | null) {
    return this.http
      .post(`${environment.apiAuthUrl}password/reset/confirm_login/`, form)
      .pipe(
        tap(res => {
          if (form['keep']) {
            localStorage.setItem('token', res['key'])
          } else {
            sessionStorage.setItem('token', res['key'])
          }
        }),
        switchMap(res => {
          return preferred_timezone ? this.userServ.updateUser({ preferred_timezone }) : of(res)
        }),
      )
  }

  changePassword(form) {
    return this.http.post(`${environment.apiAuthUrl}password/change/`, form)
  }

  getAccounts() {
    return this.http.get(`${environment.apiUrl}accounts/`, {
      params: { ['lang']: this.local_id },
    })
  }

  confirmEmail(uid: string, token: string) {
    return this.http.post(`${this.url}email-status/confirm/`, { uid, token })
  }

  resendEmailConfirmationLink(email: string = null) {

    const url = email !== null ? `${this.url}email-status/request-email/` : `${this.url}email-status/`

    return this.http.post(url, email ? { email } : null)
  }


  clearStorage(): void {
    sessionStorage.clear()
    localStorage.clear()
  }
}
