import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'projects/environments/environment'
import { Article } from '../interfaces/articles'
import { PaginatedObject } from '../interfaces/paginated-object'

@Injectable({ providedIn: 'root' })
export class ArticleService {

  constructor(private http: HttpClient) { }

  updateResourcesInteraction(id: string, event: { type: ArticleInteractionEvent, viewed_completely: boolean }) {
    return this.http.post(`${environment.apiUrl}articles/${id}/interaction/`, event)
  }

  getArticles(params: { article_id: string[] }, url?) {
    return this.http.get<PaginatedObject<Article>>(url ? url : `${environment.apiUrl}articles/`, { params })
  }

  getArticleById(id) {
    return this.http.get<Article>(`${environment.apiUrl}articles/${id}/`)
  }

  addArticleToBookmark(id: string) {
    return this.http.post<void>(`${environment.apiUrl}articles/${id}/bookmark/`, {})
  }

  removeArticleFromBookmark(id: string) {
    return this.http.delete<void>(`${environment.apiUrl}articles/${id}/bookmark/`)
  }
}

export type ArticleInteractionEvent = 'open' | 'ongoing' | 'close'
