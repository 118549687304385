import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { environment } from 'projects/environments/environment'
import { of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { UserService } from 'src/app/misc/services/user/user.service'
import { SnackbarErrorComponent } from '../../misc/components/snackbar-error/snackbar-error.component'
import { IS_SNACKBAR_SHOWED } from '../http-contexts/show-snackbar.context'
import { PaginatedObject } from '../interfaces/paginated-object'
import { ITerm } from '../interfaces/term'

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  constructor(
    private http: HttpClient,
    private userServ: UserService,
    private matSnackBar: MatSnackBar,
  ) { }

  getTerms() {
    return this.http.get<PaginatedObject<ITerm>>(`${environment.apiUrl}terms/`)
  }

  acceptTerms(form: { accept_terms: boolean, version: number }) {
    return this.http.post(`${environment.apiUrl}terms/accept/`, form, {
      context: new HttpContext().set(IS_SNACKBAR_SHOWED, false),
    }).pipe(
      catchError(error => {
        const errors = Object
          .values<{ code: string, message: string }>(error.error)
          .filter(error => error.code !== 'ALREADY_ACCEPTED')

        if (!errors.length) {
          return of(undefined)
        }

        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          verticalPosition: 'top',
          panelClass: 'snackbar-error-wrapper',
          data: errors.map(err => err.message ?? err).join('\n'),
          duration: 3000,
        })
        return throwError(() => new Error(error))
      }),
      tap(() => {
        this.userServ.user.need_accept_terms = false
      }),
    )
  }
}
