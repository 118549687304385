import { HttpParams } from '@angular/common/http'
import { Injectable, LOCALE_ID, inject } from '@angular/core'
import { environment } from 'environment/environment'
import { shareReplay } from 'rxjs'
import { IAccountPresentation } from '../interfaces/account'
import { HttpCacheClient } from './http-cache-client.service'

const ACCOUNT_LOCAL_STORAGE_KEY = 'partner_account'
@Injectable({
  providedIn: 'root',
})
export class AccountService {

  private readonly httpClient = inject(HttpCacheClient)
  private readonly locale = inject(LOCALE_ID)

  private readonly accountUrl = `${environment.apiUrl}accounts/`

  readonly allAccounts$ = this.httpClient.get<{ id: string, name: string, public_name: string }>(`${environment.apiUrl}accounts/`).pipe(shareReplay())

  getBySlug(slug: string) {
    return this.httpClient.get<IAccountPresentation>(`${this.accountUrl}${slug}/`, {
      params: new HttpParams().append('lang', this.locale),
    })
  }

  setLocalAccount(account: IAccountPresentation) {
    localStorage.setItem(ACCOUNT_LOCAL_STORAGE_KEY, JSON.stringify(account))
  }

  getLocalAccount(): IAccountPresentation | null {
    return JSON.parse(localStorage.getItem(ACCOUNT_LOCAL_STORAGE_KEY))
  }

  deleteLocalAccount() {
    localStorage.removeItem(ACCOUNT_LOCAL_STORAGE_KEY)
  }
}
