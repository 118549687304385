<div *ngIf="(backendConfig$ | async) as backendConfig"
  [class]='"flex flex-col gap-4 items-center bg-white sm:flex-row sm:gap-0 sm:justify-around" + config?.containerClass'>
  <mat-icon [svgIcon]="config?.icon" class='overflow-visible -mt-5 !h-11 !w-11'></mat-icon>
  <div [class]='"sm:max-w-[320px] text-center " + config?.textClass'>
    <h4 class='font-buenos !font-bold !text-3xl !m-0' i18n>Vous aimez Luci?</h4>
    <p class='font-buenos !text-xl' i18n>Invitez un proche à participer au programme</p>
  </div>
  <div [class]='"grid grid-cols-2 gap-2 sm:grid sm:grid-cols-3 " + config?.CtaClass'>
    <button class='bg-grey-1001 h-18 w-32 flex justify-center items-center rounded-xl sm:h-18 sm:w-20 cursor-pointer'
      pendoClass='share-program-facebook' (click)="performShareComponentAction($event,'facebook')">
      <app-facebook-share #facebook [sharedUrl]="backendConfig.sharedUrl"></app-facebook-share>
    </button>
    <button *ngIf="isMobile"
      class='bg-grey-1001 h-18 w-32 flex justify-center items-center rounded-xl sm:h-18 sm:w-20 cursor-pointer'
      pendoClass='share-program-messenger' (click)="performShareComponentAction($event,'messenger')">
      <app-messenger-share #messenger [url]="backendConfig.sharedUrl"></app-messenger-share>
    </button>
    <button class='bg-grey-1001 h-18 w-32 flex justify-center items-center rounded-xl sm:h-18 sm:w-20 cursor-pointer'
      pendoClass='share-program-email' (click)="performShareComponentAction($event,'email')">
      <app-email-share #email [subject]="backendConfig.emailSubject" [body]="backendConfig.emailContent"
        class='h-7 w-8'></app-email-share>
    </button>
    <button class='bg-grey-1001 h-18 w-32 flex justify-center items-center rounded-xl sm:h-18 sm:w-20 cursor-pointer'
      pendoClass='share-program-link' (click)="performShareComponentAction($event,'copy')">
      <app-copy-link #copy [url]="backendConfig.sharedUrl" class='h-8 w-8'></app-copy-link>
    </button>
  </div>
</div>
