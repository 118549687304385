import { AfterViewInit, Directive, ElementRef, Inject, Input } from '@angular/core'
import { getRouterComponentNamePageContext } from '../../helpers/get-router-page-context'
import { BasePendoCta } from '../pendo-cta'
import { PENDO_MODULE_CONTEXT } from '../pendo-injection-token'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[href]',
  standalone: true,
})
export class PendoHrefCtaDirective extends BasePendoCta implements AfterViewInit{

  @Input() href: string

  componentContext: string

  constructor(private elementRef: ElementRef, @Inject(PENDO_MODULE_CONTEXT) private moduleContext: string) {
    super(elementRef, 'href')
    this.componentContext = getRouterComponentNamePageContext()
  }

  ngAfterViewInit(): void {

    if(this.href && (typeof this.href) === 'string'){
      const baseUrl = location.origin
      let url: URL = null

      if(this.href.includes('http') || this.href.includes('https')){
        url = new URL(this.href)
      } else {
        url = new URL(this.href, baseUrl)
      }

      const content = [this.moduleContext, this.componentContext, url.pathname.replace('/', '')]

      this.applyClass(content)
    }
  }

}
