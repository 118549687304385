<div class='lg:bg-white lg:rounded-2xl lg:p-3'>

  <div class='mb-8 rounded-2xl lg:bg-white'
    [class.hidden]="hideCats && !this.media.matchMedia('(min-width: 767px)').matches">
    <span class='uppercase mb-4 hidden lg:block' i18n>Mes articles</span>
    <a [routerLink]="['/categories','recommended']" routerLinkActive='!bg-blue-500 !text-white' class='text-blue-500 bg-white lg:border lg:border-blue-500 rounded-2xl px-4 py-3 mb-2 flex
        items-center font-sans w-full pendo_participant_librecommendation_button'>
      <mat-icon class='mr-4'>person</mat-icon>
      <span i18n>Recommandés</span>
    </a>
    <a [routerLink]="['/categories', 'saved']" routerLinkActive='!bg-blue-500 !text-white' class='text-blue-500 bg-white lg:border lg:border-blue-500 rounded-2xl px-4 py-3 mb-2 flex
        items-center font-sans w-full pendo_participant_libbookmark_button'>
      <mat-icon class='mr-4'>bookmark</mat-icon>
      <span i18n>Sauvegardés</span>
    </a>
    <a [routerLink]="['/categories', 'recent']" routerLinkActive='!bg-blue-500 !text-white' class='text-blue-500 bg-white lg:border lg:border-blue-500 rounded-2xl px-4 py-3 flex
        items-center font-sans w-full pendo_participant_librecentread_button'>
      <mat-icon class='mr-4'>watch_later</mat-icon>
      <span i18n>Lus récemment</span>
    </a>
  </div>


  <div class='rounded-2xl bg-white py-2 px-4 lg:p-0 mb-5'>
    <div *ngIf="sideMenuCateTags" class='flex items-center justify-between'>
      <span class='uppercase' i18n>Explorez par sujet</span>
      <button (click)='showTagsOnMobile()' class='lg:hidden'>
        <mat-icon *ngIf='hideTags' class='text-4xl !w-9 !h-9'>expand_more</mat-icon>
        <mat-icon *ngIf='!hideTags' class='text-4xl !w-9 !h-9'>expand_less</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="!hideTags">
      <div class='mt-4 flex flex-col lg:items-start gap-1'>
        <ng-container *ngFor="let cateTag of sideMenuCateTags">
          <app-tag [route]='mainPathSegment ?  ["/categories", mainPathSegment, cateTag.path_segment] : ["/categories", cateTag.path_segment]' [title]="cateTag.title"></app-tag>
        </ng-container>

        <div class='mt-8 flex flex-col lg:items-start gap-1'>
          <ng-container *ngFor="let kTag of keywordTags">
            <app-tag [route]='["/themes", kTag.path_segment]' [title]="kTag.name"></app-tag>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
