import { HttpClient } from '@angular/common/http'
import { inject, Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'projects/environments/environment'
import { Observable, tap } from 'rxjs'
import { LoginRes } from 'src/app/interfaces/login-res'
import { CookiesManagementService } from './cookies-management.service'

@Injectable({
  providedIn: 'root',
})
export class AnonService {

  cookieService = inject(CookiesManagementService)
  router = inject(Router)
  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string) { }

  private readonly url: string = `${environment.apiAuthUrl}anon_signup/`

  setNfCookie(): void {
    this.cookieService.setCookieByName('nf_lang', this.locale)
  }

  anonSignup(): Observable<LoginRes> {
    return this.http.post<LoginRes>(this.url, { browser_data: new Date(), prefered_lang: this.locale }).pipe(
      tap(({ key }) => localStorage.setItem('anonToken', key)),
    )
  }

  anonUpdate(lang) {
    return this.http.patch(`${environment.apiAuthUrl}user/`, { prefered_lang: lang })
  }
}
