import { Component, Input, OnInit } from '@angular/core'
import { ContentfulService } from 'src/app/shared/services/contentful.service'


@Component({
  selector: 'app-table-block',
  templateUrl: './table-block.component.html',
  styleUrls: ['./table-block.component.sass'],
})
export class TableBlockComponent implements OnInit {
  @Input() sectionBackgroundColor
  @Input() content
  @Input() isLast

  @Input() parentsHistory: Array<any>
  @Input() position

  constructor(
    private contentfulService: ContentfulService,
  ) { }

  ngOnInit(): void {
    if (this.content.fields.titleRichText) {
      this.content.fields.titleRichText = this.contentfulService.formatHTML(this.content.fields.titleRichText)
    }
    if (this.content.fields.firstColumnHeaderRichText) {
      this.content.fields.firstColumnHeaderRichText = this.contentfulService.formatHTML(this.content.fields.firstColumnHeaderRichText)
    }
    if (this.content.fields.secondColumnHeaderRichText) {
      this.content.fields.secondColumnHeaderRichText = this.contentfulService.formatHTML(this.content.fields.secondColumnHeaderRichText)
    }

    if (this.content?.fields?.legend && this.content['fields'].legend?.content?.length) {
      this.content.fields.legend = this.contentfulService.formatHTML(this.content.fields.legend)
    }
    if (this.content?.fields?.listOfRows) {
      this.content?.fields?.listOfRows.forEach((element: any) => {
        if (element?.fields?.leftColumn) {
          element.fields.leftColumn = this.contentfulService.formatHTML(element.fields.leftColumn)
          if (this.content['fields'].firstColumnHeader) {
            element.fields.leftColumn.changingThisBreaksApplicationSecurity = `<span class='md:hidden block text-white uppercase font-bold'>${this.content['fields'].firstColumnHeader}</span> ${element.fields.leftColumn.changingThisBreaksApplicationSecurity}`
          }
        }
        if (element?.fields?.rightColumn) {
          element.fields.rightColumn = this.contentfulService.formatHTML(element.fields.rightColumn)
          if (this.content['fields'].secondColumnHeader) {
            element.fields.rightColumn.changingThisBreaksApplicationSecurity = `<span class='no-white md:hidden block text-blue-500 uppercase font-bold''>${this.content['fields'].secondColumnHeader}</span> ${element.fields.rightColumn.changingThisBreaksApplicationSecurity}`
          }
        }
      })
    }
  }
}
