import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ClickOutsideElementDirective } from './click-outside-element.directive'



@NgModule({
  declarations: [ClickOutsideElementDirective],
  imports: [
    CommonModule,
  ],
  exports: [ClickOutsideElementDirective],
})
export class ClickOutsideElementModule { }
