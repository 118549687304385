import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router'

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if(future.data.reuseComponent !== undefined) {
      return future.data.reuseComponent
    } else {
      return super.shouldReuseRoute(future, curr)
    }
  }
}
