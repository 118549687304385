import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { EmailShareComponent } from './email-share.component'



@NgModule({
  declarations: [EmailShareComponent],
  imports: [
    CommonModule,
    MatIconModule,
    CommonModule,
    LucilabCommonModule,
  ],
  exports: [
    EmailShareComponent,
  ],
})
export class EmailShareModule { }
