import { BreakpointObserver } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export type TScreenSize = 'mobile' | 'tablet' | 'desktop'

@Injectable()
export class ScreenBreakpointService {

  screenBreakpointObserver$: Observable<TScreenSize> = this.breakpointObserver.observe(['(min-width: 767px)', '(min-width: 1220px)'])
    .pipe(
      map(queries => {
        if (queries.breakpoints['(min-width: 1220px)']) return 'desktop'
        if (queries.breakpoints['(min-width: 767px)']) return 'tablet'
        return 'mobile'
      }),
    )

  constructor(private readonly breakpointObserver: BreakpointObserver) { }
}
