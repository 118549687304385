import { CommonModule } from '@angular/common'
import { Component, Input, inject } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { PendoButtonCtaDirective } from '../../../Pendo/directives/pendo-button-cta.directive'
import { LucilabCommonModule } from '../../../lucilab-common/lucilab-common.module'
import { DialogCtaContactCoachComponent } from '../dialog-cta-contact-coach/dialog-cta-contact-coach.component'
import { ContactCoachCtaInfo } from '../type'

@Component({
  selector: 'app-cta-contact-coach',
  templateUrl: './cta-contact-coach.component.html',
  standalone: true,
  imports: [CommonModule, DialogCtaContactCoachComponent, PendoButtonCtaDirective, LucilabCommonModule],
})
export class CtaContactCoachComponent {
  @Input() contactCoachCtaInfo: ContactCoachCtaInfo

  private dialog = inject(MatDialog)

  openContactCoachDialog() {
    this.dialog.open(DialogCtaContactCoachComponent, {
      autoFocus: false,
      panelClass: ['dialog-fullwidth', 'contact-coach-dialog'],

    }).afterClosed()
      .subscribe()
  }
}
