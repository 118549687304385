import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-label-for-square-checkbox',
  templateUrl: './label-for-square-checkbox.component.html',
})
export class LabelForSquareCheckboxComponent{

  @Input() selected: boolean = false
  @Input() label: string
  @Input() selectedTextColor: string = ''
  @Input() defaultTextColor: string = ''

  constructor() { }

}
