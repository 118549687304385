<div id='section-block' class='rounded-3xl relative lg:mb-16'
  [ngClass]='{"bg1": setBackgroundColor() === "#5E7D6C", "bg2": setBackgroundColor() === "#EDEFEC", "bg3": setBackgroundColor() === "#D6D6D6"}'>

  <div *ngFor="let block of allBlock ; index as i; last as isLast; first as isFirst" [class.isLastC]='isLast'
    class='font-sans my-5'>
    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "frames"'>
      <app-frames [content]=' block' [parentsHistory]="setPH(i)" [position]="i"
        [sectionBackgroundColor]='setBackgroundColor()' [isLast]='isLast' [isFirst]="isFirst">
      </app-frames>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "contentText"'>
      <app-text-block [content]='block' [isLast]='isLast' [parentsHistory]="setPH(i)" [position]="i"></app-text-block>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "contentImages"'>
      <app-image-block [content]='block' [isLast]='isLast' [parentsHistory]="setPH(i)" [position]="i"></app-image-block>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "content2Columns"'>
      <app-two-columns [content]='block.fields' [sectionBackgroundColor]='setBackgroundColor()' [isLast]='isLast'
        [parentsHistory]="setPH(i)" [position]="i">
      </app-two-columns>
    </ng-container>

    <ng-container *ngIf="block?.sys?.contentType?.sys?.id === 'article'">
      <app-library-article-card [articleId]="block.sys.id" [content]='block'></app-library-article-card>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "contentContainerImages"'>
      <app-container-image-block [content]='block' [isLast]='isLast' [parentsHistory]="setPH(i)" [position]="i">
      </app-container-image-block>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "article_section"'>
      <app-article-section-block [content]='block' [isLast]='isLast' [parentsHistory]="setPH(i)" [position]="i">
      </app-article-section-block>
    </ng-container>

    <ng-container *ngIf='block?.sys?.contentType?.sys?.id === "contentTable"'>
      <app-table-block [sectionBackgroundColor]='setBackgroundColor()' [content]='block' [isLast]='isLast'
        [parentsHistory]="setPH(i)" [position]="i">
      </app-table-block>
    </ng-container>

  </div>
</div>
