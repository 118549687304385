import { Component } from '@angular/core'
import { MatSnackBarRef } from '@angular/material/snack-bar'

@Component({
  selector: 'app-version-change-snack',
  templateUrl: './version-change-snack.component.html',
})
export class VersionChangeSnackComponent {

  constructor(private ref: MatSnackBarRef<VersionChangeSnackComponent>) { }

  close() {
    this.ref.dismissWithAction()
  }

}
