import { Location } from '@angular/common'
import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable, iif } from 'rxjs'
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { routeParamsName } from '../../../library-main-layout/route-params'
import { updateLocation } from '../../../shared/helpers/update-location'
import { ContentfulService } from '../../../shared/services/contentful.service'
import { LibraryContentService } from '../../services/library-content.service'


@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
})
export class TagsListComponent {


  title$: Observable<string> = this.route.paramMap.pipe(
    map(param => param.get(routeParamsName.tagId) || param.get(routeParamsName.themeId)),
    switchMap(themeId => {
      const splitId = themeId.split('-')
      return iif(
        () => splitId.length > 1,
        this.contentFulService.getThemeDetail(+splitId[0]).pipe(
          map(tag => tag.name),
        ),
        this.contentFulService.getAllTags().pipe(
          map(tags => tags.find(tag => tag.id === themeId)),
          map(tag => tag.name),
        ),
      )
    }),
  )

  baseTags$ = this.route.params.pipe(
    switchMap((params) => {
      if (params.catId) {
        return this.currentGroup$.pipe(
          map(({ tags }: any) => tags),
        )
      } else {
        return this.libraryContentService.baseTags$.pipe(filter(tags => !!tags))
      }
    }),
  )
  baseGroups$ = this.route.params.pipe(
    switchMap((params) => {
      if (params.catId || params.subId) {
        return this.currentGroup$.pipe(
          map(({ main_categories, secondary_categories }: any) => ({ tag: true, results: [...main_categories, ...secondary_categories] })),
        )
      }
      else {
        return this.libraryContentService.baseGroups$.pipe(filter(groups => !!groups))
      }
    }),
  )

  tagArticles$ = this.route.params.pipe(
    switchMap(({ tagId, themeId }) => this.libraryContentService.getArticlesByTag(tagId?.split('-')[0] || themeId.split('-')[0])),
  )

  currentGroup$ = this.route.params.pipe(
    filter(params => !!params.catId || !!params.subId),
    switchMap(params => this.libraryContentService.getArticleGroupFromBackEnd(params.catId || params.subId)),
    tap(libraryGroup => updateLocation(`categories/${libraryGroup.path_segment}`, this.routerLocation)),
    shareReplay(1),
  )

  constructor(
    private libraryContentService: LibraryContentService,
    public route: ActivatedRoute,
    private contentFulService: ContentfulService,
    private routerLocation: Location,
  ) { }

}
