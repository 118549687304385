import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { DEFAULT_THUMBNAIL_URL, nameAttr, propertyAttr, TLink, TMetadata, TTag } from './types'


@Injectable({
  providedIn: 'root',
})
export class MetaService {

  constructor(
    @Inject(LOCALE_ID)
    private readonly locale: string,
    private readonly meta: Meta,
    private title: Title,
  ) { }

  public updatePageMetadata(metadata: TMetadata) {
    this.setTitle(metadata.title)
    this.meta.removeTag('name="robots"')

    const truncateDescriptionFn = (str: string): string => {
      if (str.length <= 155) {
        return str
      }

      const subStr = str.substring(0, 152)
      const lastSpaceIndex = subStr.lastIndexOf(' ')

      const truncatedStr = lastSpaceIndex > 0 ? subStr.substring(0, lastSpaceIndex) : subStr
      return `${truncatedStr}...`
    }
    this.addTag({ attr: 'name', value: 'description' }, truncateDescriptionFn(metadata.description))

    const currentHref = Object.entries(metadata.links).map(([key, value]) => {
      const type = (key === this.locale) ? 'canonical' : 'alternate'
      return {
        type,
        href: this.addLink({ href: value, hreflang: key, type }),
      }
    }).find(link => link.type === 'canonical')

    // Twitter Card data
    this.addTag({ attr: 'name', value: 'twitter:card' }, 'summary')
    this.addTag({ attr: 'name', value: 'twitter:title' }, metadata.title)
    this.addTag({ attr: 'name', value: 'twitter:text:title' }, metadata.title)
    this.addTag({ attr: 'name', value: 'twitter:description' }, metadata.description)
    this.addTag({ attr: 'name', value: 'twitter:image' }, metadata.thumbnailUrl ?? DEFAULT_THUMBNAIL_URL)
    // // Open Graph data
    this.addTag({ attr: 'property', value: 'og:site_name' }, 'Luci')
    this.addTag({ attr: 'property', value: 'og:type' }, metadata.type)
    this.addTag({ attr: 'property', value: 'og:url' }, currentHref.href)
    this.addTag({ attr: 'property', value: 'og:title' }, metadata.title)
    this.addTag({ attr: 'property', value: 'og:description' }, metadata.description)
    this.addTag({ attr: 'property', value: 'og:image' }, metadata.thumbnailUrl ?? DEFAULT_THUMBNAIL_URL)

    if (metadata.keywords) {
      this.addTag({ attr: 'property', value: 'keywords' }, metadata.keywords)
    }

  }

  public clearPageMetadata(): void {
    [...document.getElementsByTagName('link')]
      .filter(link => link.rel === 'canonical' || link.rel === 'alternate')
      .forEach(link => link.remove())

    nameAttr.forEach(attr => {
      this.meta.removeTag(`name="${attr}"`)
    })
    propertyAttr.forEach(attr => {
      this.meta.removeTag(`property="${attr}"`)
    })

    this.title.setTitle('Luci')
    this.addTag({ attr: 'name', value: 'robots' }, 'noindex')
  }

  public setTitle(title: string) {
    this.title.setTitle(title)
  }

  private addLink(link: TLink): string {
    const host = window.location.host
    const element = document.createElement('link')
    element.rel = link.type
    element.href = `https://${host}/${link.hreflang}/${link.href}`
    document.head.appendChild(element)
    return element.href
  }

  private addTag(tag: TTag, content: string): void {
    this.meta.updateTag({ [tag.attr]: tag.value, content: content })
  }

}
