import { Component, Input, OnInit } from '@angular/core'
import { ContentfulService } from 'src/app/shared/services/contentful.service'


@Component({
  selector: 'app-article-section-block',
  templateUrl: './article-section-block.component.html',
  styleUrls: ['./article-section-block.component.sass'],
})
export class ArticleSectionBlockComponent implements OnInit {
  @Input() content
  @Input() isLast

  @Input() parentsHistory: Array<any>
  @Input() position

  constructor(
    private contentfulService: ContentfulService,
  ) {
   }

  ngOnInit(): void {
    if (this.content && this.content['fields'] && this.content['fields'].content &&  this.content['fields'].content?.content?.length) {
      this.content['fields'].content = this.contentfulService.formatHTML(this.content['fields'].content)
    }
  }
}
