<div [@hideShow]='isAccepted ? "hide" : "show"'
  class='cookies-banner theme-dashboard fixed z-50 -bottom-96 w-full py-6 px-4 bg-white shadow-[0px_4px_21px_0px_rgba(0,0,0,0.45)] tablet:px-6'>
  <div class='max-w-[960px] desktop:mx-auto'>
    <p class='header-3' i18n='@@shared.component.cookies-banner.title'>Nous utilisons des témoins de navigation</p>
    <p paragraph-3 class='!mt-2' i18n='@@shared.component.cookies-banner.info'>Ce site utilise des témoins de navigation
      pour maintenir la sécurité, améliorer votre expérience de navigation et analyser notre trafic. En continuant à
      naviguer sur ce site, vous acceptez notre utilisation des témoins. Pour en savoir plus sur la manière dont nous
      utilisons les témoins et vos options, consultez notre <a routerLink='/avis-juridiques/avis-temoins'>avis sur les
        témoins</a> et notre <a routerLink='/avis-juridiques/renseignements-personnels'>politique de protection des
        renseignements personnels</a>.
    </p>
    <button primary medium rounded class='mt-8 !w-full tablet:!w-fit' (click)="acceptCookies()"
      pendoClass="accept-cookies" i18n='@@shared.component.cookies-banner.accept'>Tout accepter</button>
  </div>
</div>
