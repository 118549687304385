import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { UserService } from '../services/user/user.service'



export const guidedTourCompletedGuardFn: CanActivateFn = () => {
  const userService = inject(UserService)
  const router = inject(Router)

  return userService.user$.pipe(
    map(user => {
      return user.guided_tour_done || user.onboarding_done || user.is_coach ? true : router.createUrlTree(['/guided-tour/home'])
    }),
  )
}
