import { NgModule, Type } from '@angular/core'
import { AccordionComponent } from './accordion/accordion.component'
import { BannerComponent } from './banner/banner.component'
import { ButtonModule } from './button/button.module'
import { ScrollToTopComponent } from './button/scroll-to-top/scroll-to-top.component'
import { AddClassWhenVisibleDirective } from './directives/add-class-in-view.directive'
import { ExternalLinkIconDirective } from './directives/external-link-icon.directive'
import { ScrollToTopDirective } from './directives/scroll-to-top.directive'
import { FontModule } from './font/font.module'
import { CheckboxComponent } from './inputs/checkbox/checkbox.component'
import { InputFieldComponent } from './inputs/input/input.component'
import { DefaultRadioComponent } from './inputs/radio/default/default-radio.component'
import { RadioWithTextComponent } from './inputs/radio/with-text/with-text-radio.component'
import { ValidationIndicatorComponent } from './validation-indicator/validation-indicator.component'

const modules: Type<unknown>[] = [
  ButtonModule,
  FontModule,
  AddClassWhenVisibleDirective,
  ExternalLinkIconDirective,
  InputFieldComponent,
  ValidationIndicatorComponent,
  CheckboxComponent,
  AccordionComponent,
  DefaultRadioComponent,
  RadioWithTextComponent,
  BannerComponent,
  ScrollToTopComponent,
  ScrollToTopDirective,
]

@NgModule({
  imports: modules,
  exports: modules,
})
export class UikitModule { }
