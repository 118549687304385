/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[label-1]',
})
export class Label1Directive {
  @HostBinding('class') class = 'label-1'
}

@Directive({
  standalone: true,
  selector: '[label-2]',
})
export class Label2Directive {
  @HostBinding('class') class = 'label-2'
}

@Directive({
  standalone: true,
  selector: '[label-3]',
})
export class Label3Directive {
  @HostBinding('class') class = 'label-3'
}

@Directive({
  standalone: true,
  selector: '[label-4]',
})
export class Label4Directive {
  @HostBinding('class') class = 'label-4'
}

