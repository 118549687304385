import { inject } from '@angular/core'
import { Router } from '@angular/router'

export const getRouterComponentNamePageContext = (): string => {

    const router = inject(Router)

    let currentRoute = router.routerState.root
    while(currentRoute.firstChild){
        currentRoute = currentRoute.firstChild
    }
    return currentRoute.component.name
}