import { Pipe, PipeTransform } from '@angular/core'
import { convertDateTimeToTimezone } from '../../helpers/time-to-timezone'

@Pipe({
  name: 'convertDateTimeToTimezone',
})
export class ConvertDateTimeToTimezonePipe implements PipeTransform {

  transform(dateTime: string, timezone: string): Date {
    return convertDateTimeToTimezone(dateTime, timezone)
  }

}
