import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  getItem<T>(key: string): T | null {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch {
      return null
    }
  }

  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  removeItem(key: string) {
    localStorage.removeItem(key)
  }
}
