import { Pipe, PipeTransform } from '@angular/core'
import { map } from 'rxjs/operators'
import { LibraryContentService } from 'src/app/article-library/services/library-content.service'

@Pipe({
  name: 'contentFulArticleToArticle',
  standalone: true,
})
export class ContentfulArticleToArticlePipe implements PipeTransform {

  constructor(private articleService: LibraryContentService){}

  transform(id: string) {
    return this.articleService.getMoultesArticles(id).pipe(map( (response: any) => response.results[0]))
  }

}
