<figure
  class='mb-8 relative content-content flex flex-col'
  [class.isLastContent]='isLast'
  [ngClass]='[
    content?.fields?.imageSize === "small" ? "lg:w-[122px]" : content?.fields?.imageSize === "medium" ? "lg:w-[190px]" : "lg:w-full",
    content?.fields?.mobileImageSize === "small" ? "w-[122px]" : content?.fields?.mobileImageSize === "medium" ? "w-[190px]" : "w-auto"
  ]'
  >
  <ng-container *ngIf='content?.fields?.icon'>
    <img [src]='content?.fields?.icon?.fields?.file?.url' [title]='content?.fields?.icon?.fields?.title'
      [alt]='content?.fields?.icon?.fields?.description ? content?.fields?.icon?.fields?.description : content?.fields?.icon?.fields?.title'
      class='absolute right-0 h-20 w-20 -top-7' />
  </ng-container>
  <ng-container *ngIf='content?.fields?.mainImage'>
    <picture class='mb-1'>
      <source [srcset]='content?.fields?.mainImage?.fields?.file?.url + "?w=1800&fm=avif"' type="image/avif">
      <source [srcset]='content?.fields?.mainImage?.fields?.file?.url + "?w=1800&fm=webp"' type="image/webp">
      <img [title]='content?.fields?.mainImage?.fields?.title' [alt]='content?.fields?.mainImage?.fields?.description'
        class='md:block hidden w-full rounded-3xl' [src]='content?.fields?.mainImage?.fields?.file?.url + "?w=1800"'
        alt="">
    </picture>
  </ng-container>

  <ng-container *ngIf='content?.fields?.mainImageMobile'>
    <picture>
      <source
        [srcset]='content?.fields?.mainImageMobile?.fields?.file?.url+ "?w=1600&fm=avif 2x , " + content?.fields?.mainImageMobile?.fields?.file?.url + "?w=800&fm=avif 1x"'
        type="image/avif">
      <source
        [srcset]='content?.fields?.mainImageMobile?.fields?.file?.url + "?w=1600&fm=webp 2x , " + content?.fields?.mainImageMobile?.fields?.file?.url + "?w=800&fm=webp 1x"'
        type="image/webp">
      <img [title]='content?.fields?.mainImageMobile?.fields?.title'
        [alt]='content?.fields?.mainImageMobile?.fields?.description' class='block md:hidden w-full rounded-3xl'
        [src]='content?.fields?.mainImageMobile?.fields?.file?.url + "?w=1600 2x , " + content?.fields?.mainImageMobile?.fields?.file?.url + "?w=800 1x"'
        alt="">
    </picture>
  </ng-container>

  <ng-container *ngIf='content?.fields?.legend'>
    <figcaption [innerHTML]='content.fields.legend' class='font-sans text-sm text-center text-blue-400'>
    </figcaption>
  </ng-container>
</figure>
