import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-generic-box',
  templateUrl: './generic-box.component.html',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule],
})
export class GenericBoxComponent {

  @Input() icon = { path: './assets/img/hourglass.svg', alt: 'test' }

  constructor() { }
}
