import { LOCALE_ID, inject } from '@angular/core'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
export function initDayjs() {
  const locale_id = inject(LOCALE_ID)

  return () => {
    dayjs.extend(relativeTime)
    dayjs.extend(updateLocale)
    dayjs.locale(locale_id)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault('America/Toronto')
  }
}
