import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { ExclusionComponent } from './exclusion.component'
import { InterestingOptionsComponent } from './interesting-options/interesting-options.component'
import { SingleInterestingOptionComponent } from './interesting-options/single-interesting-option/single-interesting-option.component'



@NgModule({
  declarations: [
    ExclusionComponent,
    InterestingOptionsComponent,
    SingleInterestingOptionComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    ExclusionComponent,
  ],
})
export class ExclusionModule { }
