import { CommonModule } from '@angular/common'
import { Component, inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router'
import { filter, first, map, Observable, shareReplay, Subscription, tap } from 'rxjs'
import { ConfigService } from 'src/app/misc/config/config.service'
import { UserService } from 'src/app/misc/services/user/user.service'
import { changeLanguage } from 'src/app/shared/helpers/change-lang'
import { User } from 'src/app/shared/interfaces/user'
import { CookiesManagementService } from 'src/app/shared/services/cookies-management.service'
import { MattermostService } from 'src/app/shared/services/mattermost.service'
import { InviteFriendCTAComponent } from '../../shared/components/invite-friend-cta/invite-friend-cta.component'
import { LucilabCommonModule } from '../../shared/lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../shared/Pendo/directives/pendo-button-cta.directive'
import { PendoHrefCtaDirective } from '../../shared/Pendo/directives/pendo-href-cta.directive'
import { PendoRouterLinkCtaDirective } from '../../shared/Pendo/directives/pendo-routerlink-cta.directive'
import { TTheme } from './definitions'

const emptyNavbarRoutes: string[] = ['/terms', '/guided-tour', '/user-extra-info', '/dashboard/form']
@Component({
  selector: 'app-main-layout',
  standalone: true,
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.sass'],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    InviteFriendCTAComponent,
    PendoButtonCtaDirective,
    PendoHrefCtaDirective,
    PendoRouterLinkCtaDirective,
    LucilabCommonModule,
  ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav

  @Input()
  public overideTheme?: TTheme

  public theme?: TTheme

  private userService = inject(UserService)
  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)
  private mattermostServ = inject(MattermostService)
  private configService = inject(ConfigService)
  private cookieService = inject(CookiesManagementService)
  localeId = inject(LOCALE_ID)

  isEmptyNavbar = this.isInEmptyNavbarRoute(this.router.url)
  isAnon = this.userService.user?.anonymous ?? true
  inAppointment = this.isInAppointment(this.router.url)
  isLibraryActive = false
  menuIsOpen = false

  langToggle = false

  private subscriptions: Subscription = new Subscription()

  user$: Observable<User> = this.userService.user$.pipe(shareReplay())

  languages$ = this.configService.config$.pipe(map(({ languages }) => languages))

  userInitals = `${this.userService.user?.first_name?.charAt(0) ?? ''}${this.userService.user?.last_name?.charAt(0) ?? ''}`

  ngOnInit(): void {
    this.subscriptions.add(
      this.mattermostServ.bootChatter()?.subscribe((currentChat: any) => {
        this.mattermostServ.currentChat = { ...this.mattermostServ.currentChat, ...currentChat }
      }),
    )

    this.subscriptions.add(
      this.user$
        .pipe(
          first(({ is_inactive, anonymous }) => !is_inactive && !anonymous),
          tap(() => this.mattermostServ.getMainChannelUnreadSubscribe()),
        )
        .subscribe(),
    )

    this.subscriptions.add(
      this.user$
        .pipe(first(({ is_inactive, coach, anonymous }) => !is_inactive && coach && !anonymous))
        .subscribe(() => this.mattermostServ.websocketConnect()),
    )

    this.subscriptions.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.isEmptyNavbar = this.isInEmptyNavbarRoute(event.url)
        this.inAppointment = this.isInAppointment(event.url)
      }),
    )
    this.subscriptions.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        if (this.sidenav?.opened) {
          this.sidenav.close()
        }
      }),
    )

    this.subscriptions.add(
      this.activatedRoute.url.subscribe((segments) => {
        const libraryRoute = ['articles', 'themes']
        this.isLibraryActive = libraryRoute.includes(segments[0]?.path)
      }),
    )

    this.theme = this.computeTheme()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
    this.mattermostServ.closeWebsocket()
  }

  isInEmptyNavbarRoute(url: string): boolean {
    return emptyNavbarRoutes.some((route) => url.includes(route))
  }

  isInAppointment(url: string): boolean {
    return url.includes('appointment') && !url.includes('first-appointment')
  }

  logout() {
    this.mattermostServ.closeWebsocket()
    this.userService.logout()
  }

  updateLanguage(obj: { key: string; value: string }): void {
    this.userService.updateUser({ prefered_lang: obj.key }).subscribe(() => {
      changeLanguage(obj.key, this.cookieService)
    })
  }

  private computeTheme(): TTheme {
    if (this.overideTheme) return this.overideTheme

    if (this.isEmptyNavbar) return 'emptyNavBar'
    if (this.isAnon) return 'unregistered'
    return 'registered'
  }
}
