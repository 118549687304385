<div class='accordion-component'>
  <cdk-accordion>
    <cdk-accordion-item #accrodionItem='cdkAccordionItem' role='button' [expanded]="expanded">

      <div class='accordion-component-header' (click)="toggle()" [ngClass]="pendoClass">
        <ng-content select="header"></ng-content>
      </div>

      <div class='accordion-component-content' role='region' [style.display]='expanded ? "" : "none"'>
        <div class='accordion-component-content-separator'></div>
        <ng-content select="content"></ng-content>
      </div>

    </cdk-accordion-item>
  </cdk-accordion>
</div>
