
<div>
  <div class='w-fit'>
    <div class='flex bg-white text-xs'>
    <div class='flex gap-2 items-center'>
      <mat-icon>public</mat-icon>
      <div class='flex flex-col'>
         <div class='text-base'>{{selectedTimezone}}</div>
         <div class='font-sans italic text-xs'><ng-container i18n>Heure locale</ng-container> {{currentTime | convertDateTimeToTimezone:selectedTimezone | date:'shortTime' }}</div>
      </div>
      <div *ngIf="time" class='text-base font-semibold'> ( {{time | convertDateTimeToTimezone:selectedTimezone | date:'short'}} )</div>
    </div>
</div>

