import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select'
import { map } from 'rxjs'
import { ConfigService } from 'src/app/misc/config/config.service'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
@Component({
  selector: 'app-language-switch',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatIconModule, LucilabCommonModule],
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitchComponent {
  @Input() mobile: boolean = false
  @Input() twoLettersOnly: boolean = false

  @Output() languageSwitched: EventEmitter<string> = new EventEmitter<string>()

  languages$ = this.configService.config$.pipe(map(({ languages }) => languages))

  constructor(
    private configService: ConfigService,
    @Inject(LOCALE_ID) public locale: string,
  ) { }

}
