<div class='relative'>
  <ng-container *ngIf="tagArticles$ | async as tagArticles; else loading">
    <ng-container *ngIf="title$ | async as title">
      <header class='relative p-5 lg:h-[495px]'>
        <img src='assets/img/library/library_bg.jpeg'
          class='absolute top-0 left-0 w-full h-full object-cover object-center brightness-50'>
        <app-trending-search class='lg:hidden'></app-trending-search>

        <app-new-breadcrumb class='relative z-10 lg:hidden'>
          <span class='border border-grey-1002 rounded-xl px-5 py-1 mr-1 mb-1' levelOne>{{ title }}</span>
        </app-new-breadcrumb>
        <div
          class='relative max-w-screen-xl mx-auto grid-cols-[1fr,3fr] gap-5 lg:grid lg:absolute left-0 right-0 bottom-2.5'>
          <mat-icon class='text-white !w-16 !h-16 !text-[80px] mb-4 lg:ml-auto !overflow-visible'
            fontSet='material-icons-outlined'>sell
          </mat-icon>
          <div>
            <h1 class='text-white !text-4xl !mb-0 capitalize'>{{ title }}</h1>
          </div>
        </div>

      </header>

      <div class='bg-grey-200 pb-32'>
        <main class='lg:grid grid-cols-[1fr,3fr] gap-5 mx-2 lg:mx-auto max-w-screen-xl pt-12'>
          <div *ngIf="baseGroups$ | async as baseGroups">
            <div *ngIf="baseTags$ | async as baseTags" class='lg:sticky top-5'>
              <div class='hidden lg:block'>
                <app-trending-search></app-trending-search>
              </div>
              <app-library-submenu [baseCategories]="baseGroups" [baseTags]="baseTags">
              </app-library-submenu>
            </div>
          </div>

          <div class='mt-16 lg:mt-0'>
            <ng-container *ngIf='route.snapshot.params.catId else simple'>
              <app-new-breadcrumb class='hidden lg:block'>
                <ng-container levelOne>
                  <a routerLink='../../' class='border border-grey-1002 !text-blue-500 rounded-xl px-5 py-1 mr-1 mb-1'>
                    {{ (currentGroup$ | async )?.title }}
                  </a>
                </ng-container>
                <ng-container levelTwo>{{ title }}</ng-container>
              </app-new-breadcrumb>
            </ng-container>
            <ng-template #simple>
              <app-new-breadcrumb class='hidden lg:block'>
                <span class='border border-grey-1002 rounded-xl px-5 py-1 mr-1 mb-1' levelOne>
                  {{ title }}</span>
              </app-new-breadcrumb>
            </ng-template>
            <ng-container *ngIf="tagArticles.results.length > 0; else noArticlesInThisTag">
              <div *ngFor="let tArt of tagArticles.results" class="mb-2 last:mb-0">
                <app-article-card [article]="tArt" cardType='list' />
              </div>
            </ng-container>
            <ng-template #noArticlesInThisTag>
              <p i18n='no-articles-found'>Aucun article trouvé</p>
            </ng-template>
          </div>
        </main>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <mat-spinner color='primary' diameter='60' class='!absolute top-1/2 left-1/2 transform -translate-x-1/2'>
    </mat-spinner>
  </ng-template>
</div>
