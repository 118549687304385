import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environment/environment'

@Injectable()
export class NewsLetterService {

  private readonly apiUrl = `${environment.apiUrl}newsletter`

  constructor(private httpClient: HttpClient) { }

  register(email: string) {
    return this.httpClient.post(`${this.apiUrl}/subscribe/`, { email })
  }
}
