import { AfterViewInit, Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import smoothscroll from 'smoothscroll-polyfill'
import { GaService } from './misc/services/ga/ga.service'
import { PendoService } from './pendo/pendo.service'
import { MetaService } from './shared/meta/meta.service'

smoothscroll.polyfill()

@Component({
  selector: 'app-root',
  template: `
    <app-cookies-banner />
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements AfterViewInit {
  constructor(
    gaService: GaService,
    private readonly pendoService: PendoService,
    readonly router: Router,
    readonly metaService: MetaService,
  ) {
    gaService.init()
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      metaService.clearPageMetadata()
    })
  }

  public ngAfterViewInit(): void {
    // setTimeout(0) to let the Event Loop render the UI and create the "pendo" element
    setTimeout(() => this.pendoService.initialize(), 0)
  }
}
