import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { AbstractControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { LibraryContentService } from 'src/app/article-library/services/library-content.service'
import { IInputColors } from 'src/app/shared/components/checkbox-square/checkbox-square-colors'

@Component({
  selector: 'app-rating-article',
  templateUrl: './rating-article.component.html',
  styles: [
  ],
})
export class RatingArticleComponent implements OnInit, AfterViewInit {

  @Input() articleId: string
  @Input() feedBack

  private _rating: number = 0
  private viewIsInit = false

  @Input() set rating(newRating: number) {

    this._rating = newRating
    if (newRating !== 0) {
      this.lockRating = true
    }

    if (newRating !== 0 && this.viewIsInit) {
      this.libraryService.sendArticleFeedback(this.articleId, { rating: newRating }).subscribe()
    }
  }

  get rating() {
    return this._rating
  }

  lockRating = false

  isSubmit = false

  commentRatingForm: UntypedFormGroup

  labels = [
    $localize`Réduire la longueur du texte`,
    $localize`Simplifier les informations`,
    $localize`Donner plus d’informations`,
    $localize`Ajouter des illustrations`,
    $localize`Des informations nouvelles`,
    $localize`L’envie d’en savoir plus sur le sujet`,
    $localize`Des suggestions concrètes à appliquer`,
    $localize`L'envie de changer mes habitudes`,
    $localize`Autres réponses ou commentaires (optionnel)`,
  ]

  checkboxSquareColors: IInputColors = {
    background: '#EDEFEC',
    border: 'white',
    selectedBackground: '#5E7D6C',
    selectedBorder: '#5E7D6C',
    textColor: '',
    selectedTextColor: 'white',
  }

  constructor(
    private libraryService: LibraryContentService,
  ) { }

  ngOnInit(): void {

    if (this.rating && (Object.keys(this.feedBack).length > 3 || this.feedBack.comment !== '')) {
      this.isSubmit = true
      this.lockRating = true
    } else if (this.rating && Object.keys(this.feedBack).length === 2) {
      this.lockRating = false
    }

    this.commentRatingForm = new FormGroup({
      shorterText: new UntypedFormControl(this.feedBack?.shorterText ? this.feedBack.shorterText : false),
      simplify: new UntypedFormControl(this.feedBack?.simplerInfo ? this.feedBack.simplerInfo : false),
      moreInfo: new UntypedFormControl(this.feedBack?.moreInfo ? this.feedBack.moreInfo : false),
      addIllustrations: new UntypedFormControl(this.feedBack?.addIllustrations ? this.feedBack.addIllustrations : false),
      newInfo: new UntypedFormControl(this.feedBack?.newInfo ? this.feedBack.newInfo : false),
      knowMore: new UntypedFormControl(this.feedBack?.knowMore ? this.feedBack.knowMore : false),
      suggestions: new UntypedFormControl(this.feedBack?.suggestions ? this.feedBack.suggestions : false),
      changeWillingness: new UntypedFormControl(this.feedBack?.changeWillingness ? this.feedBack.changeWillingness : false),
      comment: new UntypedFormControl(this.feedBack?.comment ? this.feedBack.comment : ''),
    }, { validators: this.formValidator })
  }

  ngAfterViewInit(): void {
    this.viewIsInit = true
  }

  onRating(newRating: number) {
    this.rating = newRating
  }

  activeEdit() {
    this.lockRating = false
    this.isSubmit = false
    this.resetForm()
  }

  submitComment() {
    this.isSubmit = true
    this.lockRating = true

    this.libraryService.sendArticleFeedback(
      this.articleId,
      { rating: this._rating, ...this.commentRatingForm.value },
    ).subscribe()
  }

  private resetForm() {
    this.commentRatingForm.reset()
  }

  private formValidator = (control: AbstractControl): ValidationErrors | null => {
    let error = { mustHaveOneSelected: true }

    for (let index = 0; index < Object.keys(control.value).length && error !== null; index++) {
      const value = control.value[Object.keys(control.value)[index]]
      // if value is true or value !== ''
      if (value) {
        error = null
      }
    }
    return error
  }
}
