import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core'

@Directive({
  selector: '[libAddClassWhenVisible]',
  standalone: true,
})
export class AddClassWhenVisibleDirective implements AfterViewInit, OnDestroy {

  @Input('libAddClassWhenVisible') className: string = ''

  @Input() intersectionThreshold: number | number[] = 0

  private observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.elementRef.nativeElement.classList.add(this.className)
        this.observer.disconnect()
      }
    })
  }, { threshold: this.intersectionThreshold })

  constructor(private elementRef: ElementRef<HTMLElement>) { }

  ngAfterViewInit(): void {
    this.observer.observe(this.elementRef.nativeElement)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }
}
