import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { AccordionComponent } from '../../accordion/accordion.component'

@Component({
  selector: 'lib-demo-accordion',
  standalone: true,
  imports: [CommonModule, AccordionComponent],
  templateUrl: './accordion.component.html',
})
export class DemoAccordionComponent {
  public isExpanded = false
  public isSecondExpanded = false
}
