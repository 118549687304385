<ng-container *ngIf="!userIsAnonymous; else unregistered">
  <app-main-layout>
    <router-outlet registered></router-outlet>
  </app-main-layout>
  <app-registered-footer *ngIf="footerEnabled$ | async" />
</ng-container>
<ng-template #unregistered>
  <app-main-layout>
    <router-outlet unregistered></router-outlet>
  </app-main-layout>
  <app-unregistered-footer />
</ng-template>
