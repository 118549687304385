<app-dialog-wrapper>

  <div [ngSwitch]="currentView">
    <div *ngSwitchCase="'callEmailChatSelectionView'" class='flex flex-col items-center justify-center gap-10'>
      <ng-container *ngTemplateOutlet="callEmailChatSeletionView"></ng-container>
    </div>
    <div *ngSwitchCase="'videoOrPhoneSelectionView'" class='flex flex-col items-center justify-center gap-10'>
      <ng-container *ngTemplateOutlet="videoOrPhoneView"></ng-container>
    </div>
    <div *ngSwitchCase="'unavailableCoachView'" class='flex flex-col items-center justify-center gap-10'>
      <ng-container *ngTemplateOutlet="unavailableCoachView"></ng-container>
    </div>
  </div>

</app-dialog-wrapper>

<!-- ********************************* -->
<!-- CALL EMAIL OR CHAT SELECTION VIEW -->
<!-- ********************************* -->
<ng-template #callEmailChatSeletionView>
  <img src="./assets/img/cta/contact-coach-full-icon.png" class='mx-auto' />
  <div>
    <h3 class='!font-buenos !font-bold !text-3xl !mb-1 text-center' i18n>Posez une question</h3>
    <p class='!font-sans !font-medium !text-base !mb-0 text-center' i18n>
      De quelle façon aimeriez-vous contacter l’équipe Luci?
    </p>
  </div>
  <form [formGroup]="form" class='flex flex-col md:flex-row justify-center gap-2'>
    <app-radio-input-container formControlName='callEmailChatSelection' value='call' [colors]="checkboxConfig">
      <ng-container *ngTemplateOutlet="selectionCard; context: { data: selectionCardData.call }"></ng-container>
    </app-radio-input-container>
    <app-radio-input-container formControlName='callEmailChatSelection' value='email' [colors]="checkboxConfig">
      <ng-container *ngTemplateOutlet="selectionCard; context: { data: selectionCardData.email }"></ng-container>
    </app-radio-input-container>
    <app-radio-input-container formControlName='callEmailChatSelection' value='chat' [colors]="checkboxConfig">
      <ng-container *ngTemplateOutlet="selectionCard; context: { data: selectionCardData.chat }"></ng-container>
    </app-radio-input-container>
  </form>
  <div class='theme-promo'>
    <button primary [shape]='"rounded"' [icon]='"arrow-right"' [iconPosition]='"right"' (click)="nextView()" pendoClass='next-action-ask-question-team-lucilab'
      [disabled]="!form.controls.callEmailChatSelection.value" i18n>
      Suivant
    </button>
  </div>
</ng-template>

<!-- ********************** -->
<!-- VIDEO OR PHONE VIEW -->
<!-- ********************** -->
<ng-template #videoOrPhoneView>
  <img src="./assets/img/cta/contact-coach-full-icon.png" class='mx-auto' />
  <h3 class='!text-center !font-buenos !font-bold !text-3xl !mb-1' i18n>Choisissez le type d’appel</h3>
  <form [formGroup]="form" class='flex flex-col md:flex-row justify-center gap-2'>
    <app-radio-input-container formControlName='videoOrCallSelectionView' value='phone' [colors]="checkboxConfig">
      <ng-container *ngTemplateOutlet="selectionCard; context: { data: selectionCardData.phone }"></ng-container>
    </app-radio-input-container>
    <app-radio-input-container formControlName='videoOrCallSelectionView' value='video' [colors]="checkboxConfig">
      <ng-container *ngTemplateOutlet="selectionCard; context: { data: selectionCardData.video }"></ng-container>
    </app-radio-input-container>
  </form>
  <div class='w-full md:w-full flex flex-col gap-2 justify-center items-center md:flex-row-reverse md:justify-around theme-promo'>
    <button secondary [shape]='"rounded"' [icon]='"arrow-right"' [iconPosition]='"right"' (click)='nextView()' [disabled]="!form.controls.videoOrCallSelectionView.value" pendoClass='cta-contact-call-team-lucilab' i18n>
      Suivant
    </button>
    <button primary [shape]='"rounded"' [icon]='"arrow-left"' [iconPosition]='"left"' (click)='previousView()' pendoClass='return-to-type-selection' i18n>
      Précédent
    </button>
  </div>
</ng-template>

<!-- ********************** -->
<!-- UNAVAILABLE COACH VIEW -->
<!-- ********************** -->
<ng-template #unavailableCoachView>
  <img src="./assets/img/clock.svg" class='mx-auto' />
  <h3 class='!font-buenos !font-bold !text-3xl !mb-1 text-center' i18n>Personne de notre équipe n’est disponible pour le
    moment</h3>
  <p class='!font-sans !font-medium !text-base !mb-0 text-center' i18n>
    Envoyez-nous votre question par courriel et nous vous
    contacterons
    dans les plus brefs délais
  </p>

  <div class='w-full md:w-fit flex flex-col items-center gap-2 theme-promo'>
    <button primary [shape]='"rounded"' (click)="sendQuestionByEmail()" i18n>Envoyer ma question par
      courriel</button>
    <button secondary [shape]='"rounded"' (click)="dialogRef.close()" class='w-full' i18n>Quitter</button>
  </div>

</ng-template>


<!-- ************** -->
<!-- SELECTION CARD -->
<!-- ************** -->
<ng-template #selectionCard let-data='data'>
  <div
    class='theme-promo flex gap-4 items-center md:block w-full py-4 md:w-48 md:h-44 pt-4 px-2 bg-grey-1001 rounded-2xl shadow overflow-visible'>
    <img [src]="data.image" class='h-14 w-14 tablet:mx-auto tablet:mb-2' />

    <div>
      <p label-3 class='md:text-center'>{{data.title}}</p>
      <ng-container *ngIf='data.subTitle'>
        <p label-4 class='md:text-center'>{{ data.subTitle }}</p>
      </ng-container>
    </div>
  </div>
</ng-template>
