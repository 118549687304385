import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    LucilabCommonModule,
  ],
})
export class StarRatingComponent {

  @Input() set ratingValue(value: number) {
    this.rating.setValue(value)
  }

  @Output() ratingValueChange = new EventEmitter<number>()

  @Input() set lock(isLock: boolean) {
    isLock ? this.ratingForm.disable() : this.ratingForm.enable()
  }

  @Input() starColor = '#5E7D6C'

  constructor(private fb: FormBuilder) { }

  ratingForm = this.fb.group({
    hover: [],
    rating: [0, Validators.required],
  })

  get rating() {
    return this.ratingForm.get('rating')
  }

  get hover() {
    return this.ratingForm.get('hover')
  }

  changeValue(newValue: number) {
    if (this.ratingForm.enabled) {
      this.rating.setValue(newValue)
      this.ratingValueChange.emit(newValue)
    }
  }

}

