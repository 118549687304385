import { NgModule } from '@angular/core'
import { LinkDirective } from './link.directive'
import { LinkService } from './link.service'

@NgModule({
  declarations: [
    LinkDirective,
  ],
  exports: [
    LinkDirective,
  ],
  providers: [
    LinkService,
  ],
})
export class LinkModule {

}
