<section id='disclaimer'>
  <div class='mb-7'>
    <ng-container *ngIf='content?.fields?.logo'>
      <img [src]='content?.fields?.logo?.fields?.file?.url' [title]='content?.fields?.logo?.fields?.title'
        [alt]='content?.fields?.logo?.fields?.description ? content?.fields?.logo?.fields?.description : content?.fields?.logo?.fields?.title'
        class='logo w-24 mb-4' />
    </ng-container>

    <div [innerHTML]='content?.fields?.content' class='font-sans'>
    </div>
  </div>
</section>
