import { AbstractControl, FormGroup, ɵFormGroupValue, ɵTypedOrUntyped } from '@angular/forms'
import { BehaviorSubject, Observable, catchError, take, throwError } from 'rxjs'

type TFormGroupCtorParameters<T extends { [K in keyof T]: AbstractControl<any> }> = ConstructorParameters<typeof FormGroup<T>>
type TState = 'new' | 'submitting' | 'submitted' | 'error'

export class Form<TControl extends { [K in keyof TControl]: AbstractControl<any> } = any> extends FormGroup<TControl> {

  public get state() {
    return this.stateChanged$.value
  }

  public readonly stateChanged$ = new BehaviorSubject<TState>('new')

  constructor(
    private readonly submitFn: (values: ɵTypedOrUntyped<TControl, ɵFormGroupValue<TControl>, any>) => Observable<unknown>,
    ...params: TFormGroupCtorParameters<TControl>
  ) {
    super(...params)
  }

  public reset() {
    super.reset()
    this.stateChanged$.next('new')
  }

  public submit() {
    if (this.invalid) {
      this.stateChanged$.next('error')
      return
    }

    this.stateChanged$.next('submitting')

    return this.submitFn(this.value).pipe(
      catchError(err => {
        this.stateChanged$.next('error')
        return throwError(() => err)
      }),
      take(1),
    ).subscribe(() => this.stateChanged$.next('submitted'))
  }

}
