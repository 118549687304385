import { CommonModule } from '@angular/common'
import { Component, HostListener, Input } from '@angular/core'
import { ScreenBreakpointService, TScreenSize } from '@utils'
import { ScrollToTopDirective } from '../../directives/scroll-to-top.directive'
import { ButtonModule } from '../button.module'

type TPositions = {
  bottom: string
  right: string
}

@Component({
  selector: 'lib-scroll-to-top',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './scroll-to-top.component.html',
  hostDirectives: [ScrollToTopDirective],
  providers: [ScreenBreakpointService],
})
export class ScrollToTopComponent {

  @Input() positions: { [key in TScreenSize]: TPositions } = {
    mobile: { bottom: '103px', right: '14px' },
    tablet: { bottom: '95px ', right: '25px' },
    desktop: { bottom: '105px', right: '350px' },
  }

  public isVisible = false

  public readonly screenSize$ = this.screenBreakpointService.screenBreakpointObserver$

  @HostListener('window:scroll')
  onScroll() {
    this.isVisible = window.scrollY > 50
  }

  constructor(
    private readonly screenBreakpointService: ScreenBreakpointService,
  ) { }

}
