import { Injectable } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { environment } from 'projects/environments/environment'
import { ConfigService } from '../../config/config.service'

declare let gtag

@Injectable({
  providedIn: 'root',
})
export class GaService {
  constructor(private router: Router, private configService: ConfigService) { }

  public init() {
    this.listenForRouteChanges()

    try {
      const scriptGtag = document.createElement('script')
      scriptGtag.type = 'text/javascript'
      scriptGtag.src = `https://www.googletagmanager.com/gtag/js?id=${environment.ga}`
      const async = true
      scriptGtag.async = async

      const script = document.createElement('script')
      script.innerHTML =
        `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${environment.ga}');
        `
      document.head.appendChild(scriptGtag).appendChild(script)

      //add no script tag
      /* const noScript = document.createElement('noscript')
       const iframeGtag = document.createElement('iframe')
       iframeGtag.src = `https://www.googletagmanager.com/ns.html?id=${environment.ga}`
       iframeGtag.height = '0'
       iframeGtag.width = '0'
       iframeGtag.style.display = 'none'
       iframeGtag.style.visibility = 'hidden'
       document.body.appendChild(noScript).appendChild(iframeGtag)*/
    } catch (ex) {
      console.error('Error appending google analytics')
      console.error(ex)
    }
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart && this.configService.Config && event.url.includes(this.configService.Config.referral_url_query_string)) {
        gtag('config', environment.ga, {
          page_path: event.url,
        })
      }

      if (event instanceof NavigationEnd) {
        gtag('config', environment.ga, {
          page_path: event.urlAfterRedirects,
        })
      }
    })
  }

  public emitEvent(
    name: string,
    category: string,
    action: string,
    label: string = null,
    value: number = null,
  ) {
    gtag('event', name, {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    })
  }
}
