import { MediaMatcher } from '@angular/cdk/layout'
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { ContentfulService } from 'src/app/shared/services/contentful.service'


@Component({
  selector: 'app-frames',
  templateUrl: './frames.component.html',
  styleUrls: ['./frames.component.sass'],
})
export class FramesComponent implements OnInit {
  @Input() content: any | null
  @Input() sectionBackgroundColor // useless
  @Input() parentsHistory: Array<any>
  @Input() position

  bgColor = [
    {color: '#5e7d6c', class: 'bg1'},
    {color: '#2f5057', class: 'bg2'},
    {color: '#d6d6d6', class: 'bg3'},
    {color: '#edefec', class: 'bg4'},
  ]
  @Input() isLast: boolean
  @Input() isFirst: boolean

  constructor(
    private contentfulService: ContentfulService,
    private media: MediaMatcher,
    private sanitize: DomSanitizer,
  ) { }

  ngOnInit(): void {
    if (this.content?.fields?.title?.content?.length) {
      this.content.fields.title = this.sanitize.bypassSecurityTrustHtml(documentToHtmlString(this.content.fields.title))
    }
    if (this.content && this.content['fields'] && this.content['fields'].content_text && this.content['fields'].content_text?.content?.length) {
      this.content['fields'].content_text = this.contentfulService.formatHTML(this.content['fields'].content_text)
    }
    this.setFrameBg()
  }


  setFrameBg() {
    if (this.parentsHistory[0]?.type === 'sections' && !this.media.matchMedia('(min-width: 768px)').matches) {
      const realAvailableColor = this.bgColor.filter( e => e.color?.toUpperCase() !== this.parentsHistory[0]?.bg.toUpperCase())
      this.content['backgroundColor'] = realAvailableColor[(this.position < realAvailableColor.length ? this.position : this.position % realAvailableColor.length)]?.class
    }
  }

  setPH(i: number): Array<any> {
    return [...this.parentsHistory, {rang: this.parentsHistory.length, position: i, type: 'frame'}]
  }
}
