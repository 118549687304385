<div class='container mx-auto hidden lg:grid-cols-2 lg:grid gap-8 items-center' [class.isLastContent]='isLast'>
  <!-- left column -->
  <ng-container *ngIf='content?.leftColumn?.length > 0'>
    <div class="left-col flex justify-center flex-col items-center">
      <div *ngFor="let lcContent of currentEntriesLeftColumn; index as i; last as isLastCc; first as isFirst"
        [class.isLast]="isLastCc">
        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "frames"'>
          <app-frames [content]='lcContent' [parentsHistory]="setPH(i)" [position]="i"
            [sectionBackgroundColor]='sectionBackgroundColor' [isLast]="isLastCc">
          </app-frames>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentText"'>
          <app-text-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-text-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentImages"'>
          <app-image-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-image-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentSections"'>
          <app-section-block [content]='lcContent' [parentsHistory]="setPH(i)" [position]="i"></app-section-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentContainerImages"'>
          <app-container-image-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-container-image-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "article_section"'>
          <app-article-section-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-article-section-block>
        </ng-container>

        <ng-container *ngIf="lcContent?.sys?.contentType?.sys?.id === 'article'">
          <app-library-article-card  [articleId]="lcContent.sys.id" [content]='lcContent'></app-library-article-card>
        </ng-container>

      </div>
    </div>
  </ng-container>

  <!-- right column -->
  <ng-container *ngIf='content?.rightColumn?.length > 0'>
    <div class="right-col flex justify-center flex-col items-center">
      <div *ngFor="let rcContent of currentEntriesRightColumn; index as i; last as isLastCc; first as isFirst"
        [class.isLast]="isLastCc">
        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "frames"'>
          <app-frames [content]='rcContent' [parentsHistory]="setPH(i)" [position]="i"
            [sectionBackgroundColor]='sectionBackgroundColor' [isLast]="isLastCc">
          </app-frames>
        </ng-container>

        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "contentText"'>
          <app-text-block [content]='rcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-text-block>
        </ng-container>

        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "contentImages"'>
          <app-image-block [content]='rcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-image-block>
        </ng-container>

        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "contentSections"'>
          <app-section-block [content]='rcContent' [parentsHistory]="setPH(i)" [position]="i"></app-section-block>
        </ng-container>

        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "contentContainerImages"'>
          <app-container-image-block [content]='rcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-container-image-block>
        </ng-container>

        <ng-container *ngIf='rcContent?.sys?.contentType?.sys?.id === "article_section"'>
          <app-article-section-block [content]='rcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-article-section-block>
        </ng-container>

        <ng-container *ngIf="rcContent?.sys?.contentType?.sys?.id === 'article'">
          <app-library-article-card [articleId]="rcContent.sys.id" [content]='rcContent' ></app-library-article-card>
        </ng-container>

      </div>
    </div>
  </ng-container>
</div>




<!-- mobile -->
<div class='container mx-auto grid grid-cols-1 lg:hidden gap-8' [class.isLastContent]='isLast'>
  <ng-container *ngIf='tmpArr?.length > 0'>
    <div class="mobile-col">
      <div *ngFor="let lcContent of tmpArr; index as i; last as isLastCc; first as isFirst" [class.isLast]="isLastCc">
        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "frames"'>
          <app-frames [content]='lcContent' [parentsHistory]="setPH(i)" [position]="i"
            [sectionBackgroundColor]='sectionBackgroundColor' [isLast]="isLastCc" [isFirst]="isFirst">
          </app-frames>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentText"'>
          <app-text-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-text-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentImages"'>
          <app-image-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)" [position]="i">
          </app-image-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentSections"'>
          <app-section-block [content]='lcContent' [parentsHistory]="setPH(i)" [position]="i"></app-section-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "contentContainerImages"'>
          <app-container-image-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-container-image-block>
        </ng-container>

        <ng-container *ngIf='lcContent?.sys?.contentType?.sys?.id === "article_section"'>
          <app-article-section-block [content]='lcContent' [isLast]="isLastCc" [parentsHistory]="setPH(i)"
            [position]="i"></app-article-section-block>
        </ng-container>

        <ng-container *ngIf="lcContent?.sys?.contentType?.sys?.id === 'article'">
          <app-library-article-card  [articleId]="lcContent.sys.id" [content]='lcContent'></app-library-article-card>
        </ng-container>

      </div>
    </div>
  </ng-container>
</div>
