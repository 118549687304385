import { CommonModule, NgClass, NgIf } from '@angular/common'
import { Component, Input, ViewChild } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { map } from 'rxjs'
import { ConfigService } from 'src/app/misc/config/config.service'
import { isMobileDevice } from '../../helpers/is-mobile-device'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { CopyLinkComponent } from '../copy-link/copy-link.component'
import { CopyLinkModule } from '../copy-link/copy-link.module'
import { EmailShareComponent } from '../email-share/email-share.component'
import { EmailShareModule } from '../email-share/email-share.module'
import { FacebookShareComponent } from '../facebook-share/facebook-share.component'
import { FacebookShareModule } from '../facebook-share/facebook-share.module'
import { MessengerShareComponent } from '../messenger-share/messenger-share.component'
import { MessengerShareModule } from '../messenger-share/messenger-share.module'

const defaultConfig: ShareProgramCTAConfig = {
  containerClass: '',
  CtaClass: '',
  icon: 'heart',
  textClass: '',
}
@Component({
  selector: 'app-share-program-cta',
  standalone: true,
  imports: [CommonModule, NgIf, NgClass, MatIconModule, EmailShareModule, FacebookShareModule, MessengerShareModule, CopyLinkModule, LucilabCommonModule],
  templateUrl: './share-program-cta.component.html',
})
export class ShareProgramCTAComponent {

  @ViewChild('messenger') messengerShare: MessengerShareComponent
  @ViewChild('facebook') facebookShare: FacebookShareComponent
  @ViewChild('email') emailShare: EmailShareComponent
  @ViewChild('copy') copyShare: CopyLinkComponent

  @Input() config: ShareProgramCTAConfig = defaultConfig

  isMobile = isMobileDevice()

  public readonly backendConfig$ = this.configService.config$.pipe(map(config => {
    return {
      sharedUrl: `${location.host}/public/dashboard/home/detail?${config.referral_url_query_string}`,
      emailSubject: config.sharing_email_subject,
      emailContent: encodeURIComponent(config.sharing_email_body),
    }
  }))



  constructor(private configService: ConfigService) { }

  performShareComponentAction(event: Event, actionName: 'messenger' | 'facebook' | 'email' | 'copy') {
    event.preventDefault()
    switch (actionName) {
      case 'messenger': {
        this.messengerShare.shareOnMessenger()
        break
      }

      case 'copy': {
        this.copyShare.copyToClipboard()
        break
      }

      case 'email': {
        window.open(`mailto: ?${this.emailShare.emailContent}`)
        break
      }

      case 'facebook': {
        this.facebookShare.share()
        break
      }
    }
  }
}

export type ShareProgramCTAConfig = {
  containerClass?: string
  icon?: 'heart' | 'heartFull'
  CtaClass?: string
  textClass?: string
}
