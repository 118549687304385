import { Component, Input, OnInit } from '@angular/core'
import { ContentfulService } from 'src/app/shared/services/contentful.service'


@Component({
  selector: 'app-disclaimer-block',
  templateUrl: './disclaimer-block.component.html',
  styleUrls: ['./disclaimer-block.component.sass'],
})
export class DisclaimerBlockComponent implements OnInit{
  @Input() content

  constructor(
    private contentfulService: ContentfulService,
  ) { 
  }

  ngOnInit(): void {
    if (this.content && this.content['fields'] && this.content['fields'].content && this.content['fields'].content?.content?.length) {
      this.content['fields'].content =  this.contentfulService.formatHTML(this.content['fields'].content)
    }
  }
  
}
