import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LabelForSquareCheckboxComponent } from './label-for-square-checkbox.component'



@NgModule({
  declarations: [LabelForSquareCheckboxComponent],
  imports: [
    CommonModule,
  ],
  exports: [LabelForSquareCheckboxComponent],
})
export class LabelForSquareCheckboxModule { }
