import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Meta } from '@angular/platform-browser'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { VersionChangeSnackComponent } from 'src/app/shared/version-change-snack/version-change-snack.component'

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

  private TAG_NAME = 'luci-version'

  private metaTagIsSet = false

  constructor(private snack: MatSnackBar, private metaTagService: Meta) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((res: any) => {
        if (res instanceof HttpResponse) {
          this.setMetaVersion(res.headers.get('Version-FE'))
          if (res.headers.get('Version-FE') && (res.headers.get('Version-FE') !== localStorage.getItem('version'))) {
            if (!localStorage.getItem('version')) {
              localStorage.setItem('version', res.headers.get('Version-FE'))
            } else {
              if (!document.querySelectorAll('.snackbar-success-wrapper').length) {
                this.snack.openFromComponent(VersionChangeSnackComponent, {
                  verticalPosition: 'top',
                  panelClass: 'snackbar-success-wrapper',
                  duration: 10000,
                }).afterDismissed().subscribe(({ dismissedByAction }) => {
                  if (dismissedByAction) {
                    localStorage.setItem('version', res.headers.get('Version-FE'))
                    location.reload()
                  }
                })
              }
            }
          }
        }
      }),
    )

  }

  private setMetaVersion(version: string){
    if(!this.metaTagIsSet){
      this.metaTagService.addTag({name: this.TAG_NAME, content: version}, true)
      this.metaTagIsSet = true
    }
  }
}
