import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'environment/environment'
import { BehaviorSubject, forkJoin, interval, Observable, of, Subject, Subscription, timer } from 'rxjs'
import { delayWhen, filter, map, mergeMap, pluck, retry, retryWhen, share, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'
import { AppointmentService } from 'src/app/dashboard/services/appointment.service'
import { UserService } from '../../misc/services/user/user.service'
import { MattermostChannelMessage } from '../interfaces/mattermost'

@Injectable({
  providedIn: 'root',
})
export class MattermostService {

  private readonly apiUrl: string = environment.mattermostUrl
  private readonly wsUrl: string = environment.mattermostWebsocketUrl

  private headers

  private readonly _infoAppointmentCoach = new BehaviorSubject(null)

  /* ----- Sync Main Channel Notifications for a components with a single Subject ----- */

  private readonly _mainChannelUnread = new BehaviorSubject(0)
  private _mainChannelUnreadSubscription = Subscription.EMPTY
  private readonly _currentChannelId = new BehaviorSubject<string | null>(null)
  private readonly _currentChat: BehaviorSubject<MattermostChannelMessage> = new BehaviorSubject({} as MattermostChannelMessage)
  private readonly _typing: BehaviorSubject<boolean> = new BehaviorSubject(false)
  readonly currentChat$ = this._currentChat.asObservable().pipe(filter(e => Object.keys(e).length > 1))
  readonly typing$ = this._typing.asObservable()
  private chatSocket = webSocket(environment.mattermostWebsocketUrl)

  closeWebSocket$ = new Subject<Boolean>()

  private readonly _toggleChatDisplay = new Subject()
  readonly toggleChatDisplay$ = this._toggleChatDisplay.asObservable()

  private readonly _chatNotif = new Subject()
  readonly chatNotif$ = this._chatNotif.asObservable()

  /* data that's update from websocket */
  private myObjChat = {
    messages: [],
    unread: 0,
    channelID: null,
  }

  private readonly _myObjChat = new BehaviorSubject<MattermostChannelMessage>(null)
  readonly myObjChat$ = this._myObjChat.asObservable().pipe(share())


  private readonly _activeNotif = new BehaviorSubject(null)
  readonly activeNotif$ = this._activeNotif.asObservable().pipe(share())

  setActiveNotif(activeNotifs: Array<any>) {
    this._activeNotif.next(activeNotifs)
  }

  getActiveNotif() {
    return this._activeNotif.asObservable()
  }

  constructor(
    private http: HttpClient,
    private userServ: UserService,
    private appointmentServ: AppointmentService,
    private dom: DomSanitizer,
    private route: ActivatedRoute,
  ) {
    if (this.userServ.user.mattermost_id) {
      this.headers = new HttpHeaders({
        Authorization: `Bearer ${this.userServ.user.mattermost_token}`,
      })
    }
    this.getCurrentChannelId().subscribe(r => {
      this.myObjChat.channelID = r
      this.setMyObjChat(this.myObjChat)
    })
  }

  setInfoAppointmentCoach(coachInfo) {
    this._infoAppointmentCoach.next(coachInfo)
  }

  getInfoAppointmentCoach() {
    return this._infoAppointmentCoach.asObservable()
  }

  setChatNotif(data: any) {
    this._chatNotif.next(data)
  }

  getMainChannelUnread() {
    return this._mainChannelUnread.asObservable()
  }

  setMainChannelUnread(count: number) {
    this._mainChannelUnread.next(count)
    this.myObjChat.unread = count
    this.setMyObjChat(this.myObjChat)
  }

  setMainChannel(mattermostChannel: MattermostChannelMessage) {
    this._mainChannelUnread.next(mattermostChannel.unread)
    this.myObjChat = mattermostChannel
    this.setMyObjChat(mattermostChannel)
  }

  set typing(value) {
    this._typing.next(value)
  }

  get typing() {
    return this._typing.getValue()
  }

  getMainChannelUnreadSubscribe(channel?) {
    if (this.userServ.user.mattermost_id) {
      if (this._mainChannelUnreadSubscription !== Subscription.EMPTY) {
        this._mainChannelUnreadSubscription.unsubscribe()
      }

      const obs$: Observable<number> = timer(0, 60000).pipe(
        switchMap(() => this.userServ.user$),
        takeWhile(user => user.mattermost_id !== undefined),
        tap(user => {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${user.mattermost_token}`,
          })
        }),
        switchMap(user => this.http.get<MatterMostUnreadResponse>(`${this.apiUrl}users/${user.mattermost_id}/channels/${channel || user.mattermost_main_channel_id}/unread`, { headers: this.headers })
          .pipe(this.retryTimer(1000))),
        map((matterMostResponse: MatterMostUnreadResponse) => matterMostResponse.msg_count),
        share(),
      )

      this._mainChannelUnreadSubscription = obs$.subscribe(count => this.setMainChannelUnread(count))
      return obs$
    } else {
      return of(null)
    }

  }

  /* ----- END Sync Main Channel Notifications for a components with a single Subject ----- */

  getTeamUnread() {

    return timer(0, 10000).pipe(
      switchMap(() => this.userServ.user$),
      takeWhile(user => user.mattermost_id !== undefined),
      switchMap(user => this.http.get(`${this.apiUrl}users/${user.mattermost_id}/teams/unread`,
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${user.mattermost_token}`,
          }),
        }).pipe(this.retryTimer(1000)),
      ),
      map((teams: any) => teams),
    )
  }


  setCurrentChannelId(channelId, archived?) {
    if ((channelId !== this.currentChat.channelID)) {
      this.currentChat = { channelID: channelId } as MattermostChannelMessage
      this._currentChannelId.next(channelId)
      if (archived) {
        this.currentChat['archived'] = true
      } else {
        this.currentChat['archived'] = false
      }
    }
  }

  getCurrentChannelId() {
    return this._currentChannelId.asObservable()
  }

  setAppointment(appointment) {
    this.currentChat = { ...this.currentChat, ...appointment }
  }

  set currentChat(currentChat) {
    this._currentChat.next(currentChat)
    this.myObjChat.messages = this.currentChat?.order
    this.setMyObjChat(this.myObjChat)
  }

  get currentChat() {
    return this._currentChat.getValue()
  }

  setMyObjChat(obj) {
    this._myObjChat.next(obj)
  }

  bootChatter() {
    if (this.userServ.user.mattermost_id) {
      return this.getCurrentChannelId().pipe(
        filter(e => e !== null),
        tap(channelId => {
          this.currentChat = { ...this.currentChat, channelID: channelId }
        }),
        switchMap((channelId) => {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${this.userServ.user.mattermost_token}`,
          })
          return this.http.get(`${this.apiUrl}channels/${channelId}/posts?attempt=1`, { headers: this.headers })
        }),
        map((currChat: any) => {
          return this.orderPosts(currChat)
        }),
        mergeMap((currChat: any) => {
          const ah = []
          currChat.order.forEach(post => {
            if (post.file_ids) {
              ah.push(this.getFile(post))
            } else {
              if (!post.type.includes('system')) {
                ah.push(of(post))
              }
            }
          })
          return forkJoin(ah).pipe(map(posts => {
            currChat['order'] = posts
            return currChat
          }))
        }),
        this.retryTimer(2000),
      )
    }
  }

  retryTimer(timerValue) {
    return retryWhen(errors => errors.pipe(delayWhen(() =>
      timer(timerValue)),
    ),
    )
  }

  retryCount(count) {
    return retry(count)
  }

  websocketConnect() {

    const obs$ = this.chatSocket.pipe(
      this.retryTimer(1000),
      share(),
      takeUntil(this.closeWebSocket$),
    )
    let lock = false
    this.chatSocket.next({
      seq: 1,
      action: 'authentication_challenge',
      data: {
        token: this.userServ.user.mattermost_token,
      },
    })

    timer(0, 45000).pipe(
      takeUntil(this.closeWebSocket$),
    ).subscribe(() => this.chatSocket.next({ seq: 1, action: 'get_statuses' }))

    obs$.pipe(
      this.retryTimer(1000),
      map((e: any) => {
        if (e.event === 'posted') {
          if (this.userServ.user.mattermost_id !== e.data.post.user_id) {
            this._chatNotif.next(true)
            this.setMainChannelUnread(this._mainChannelUnread.value + 1)
          }
          if (!(e.data.post instanceof Object)) {
            e.data.post = JSON.parse(e.data.post)
          }

          if (e.data.post.channel_id === this.currentChat.channelID) {
            return e
          }
        }
        if (e.event === 'typing') {
          if (this.currentChat && !this.currentChat['coach'] && !this._infoAppointmentCoach.getValue()) { // participant
            this.http.get(`${environment.apiUrl}appointments/?next_booking=false`).subscribe(et => this.setInfoAppointmentCoach(et['results'][0]['coach']))
          }
          return e
        }
      }),
      tap((e: any) => {
        if (e && e?.event) {
          const tmp = of(e)?.pipe(
            tap((evt: any) => {
              if (evt && evt.event === 'typing') {
                if (this.currentChat && !this.currentChat['coach'] && !this._infoAppointmentCoach.getValue()) { // participant
                  this.http.get(`${environment.apiUrl}appointments/?next_booking=false`).subscribe(et => this.setInfoAppointmentCoach(et['results'][0]['coach']))
                }
                this.typing = true
              }
              return evt
            }),
            delayWhen((evt) => {
              if (!lock) {
                lock = true
                return interval(3000)
              } else {
                return of()
              }
            }),
            tap((evt: any) => {
              this.typing = false
              lock = false
              return evt
            })).subscribe()

          return tmp
        } else {
          return of()
        }
      }),
      mergeMap((e: any) => {
        if (e && e.event === 'posted' && e.data.post.file_ids) {
          return this.getFile(e.data.post).pipe(this.retryTimer(1000), map(post => {
            e.data.post = post
            return e
          }))
        } else {
          return of(e)
        }
      }),
    ).subscribe((evt: any) => {
      if (evt && evt.event === 'posted') {
        const post = evt.data.post
        this.currentChat = { ...this.currentChat, order: [post, ...this.currentChat.order ?? []] }
      }
    })

    return obs$
  }

  setChatSocket() {
    this.chatSocket.next({ close: true })
  }

  closeWebsocket() {
    this.closeWebSocket$.next(true)
  }

  viewChannel() {
    if (document.getElementById('chat-text') && document.getElementById('chat-text').hidden) {
      return of(null)
    }
    const obs$ = this.http.post(`${this.apiUrl}channels/members/${this.userServ.user.mattermost_id}/view`,
      { channel_id: this.currentChat.channelID },
      { headers: this.headers }).pipe(this.retryTimer(1000), share())

    obs$.subscribe(() => {
      this._mainChannelUnread.next(0)
      this.myObjChat.unread = 0
      this.setMyObjChat(this.myObjChat)
    })
    return obs$
  }

  orderPosts(currChat) {
    return currChat = { ...currChat, order: currChat.order.map(ord => ord = currChat.posts[ord]) }
  }

  addPost(message, fileIds?) {
    return this.http.post(`${this.apiUrl}posts`, {
      channel_id: this.currentChat.channelID,
      message,
      file_ids: fileIds,
    }, { headers: this.headers }).pipe(this.retryCount(3))
  }

  setMessageUnread(postId, userId, channelId, teamId, userToken) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
    return this.http.post(`${this.apiUrl}users/${userId}/posts/${postId}/set_unread`,
      {
        team_id: teamId,
        channel_id: channelId,
      },
      { headers: this.headers })
  }


  // a confirmer
  getAllUnread() {
    return this.userServ.user$.pipe(
      switchMap(user => this.http.get(`${this.apiUrl}users/${user.mattermost_id}/posts`).pipe(this.retryTimer(1000))),
    )
  }

  isTyping(mattermostId) {
    this.chatSocket.next({
      action: 'user_typing',
      seq: 2,
      data: {
        userId: mattermostId,
        channel_id: this.currentChat.channelID,
      },
    })
  }

  getPreviousPosts() {
    const order = this.currentChat.order
    const obs$ = this.http.get(`${this.apiUrl}channels/${this.currentChat.channelID}/posts`, {
      params: { before: order[order.length - 1].id },
      headers: this.headers,
    }).pipe(
      this.retryTimer(1000),
      map((currChat: any) => this.orderPosts(currChat)),
      mergeMap((currChat: any) => {
        const ah = []
        currChat.order.forEach(post => {
          if (post.file_ids) {
            ah.push(this.getFile(post))
          } else {
            if (!post.type.includes('system')) {
              ah.push(of(post))
            }
          }
        })
        return forkJoin(ah).pipe(this.retryTimer(1000), map(posts => {
          currChat['order'] = posts
          return currChat
        }))
      }),
    )

    obs$.subscribe(currentChat => {
      this.currentChat = { ...this.currentChat, ...currentChat, order: this.currentChat.order.concat(currentChat.order) }
    })

    return obs$
  }

  closeChat() {
    this.setCurrentChannelId(null)
    this.currentChat = {} as MattermostChannelMessage
  }

  getAllChannels() {
    return this.appointmentServ.getAllDefault().pipe(this.retryTimer(1000), pluck('results'))
  }

  uploadFile(file) {
    const uploadData = new FormData()
    uploadData.append('files', file, file.name)
    uploadData.append('channel_id', this.currentChat.channelID)
    return this.http.post(
      `${this.apiUrl}files`,
      uploadData,
      {
        headers: this.headers,
      },
    ).pipe(
      this.retryTimer(1000),
      pluck('file_infos'),
      map(info => info[0]),
      switchMap(res => this.addPost(res.name.split('\\').pop(), [res.id])),
    )
  }

  getFile(post) {
    return this.http.get(`${this.apiUrl}files/${post.file_ids[0]}/link`, { headers: this.headers })
      .pipe(
        this.retryTimer(1000),
        map((data: { link: string }) => ({
          ...post,
          file_url: data.link,
        })),
      )
  }

  setToggleChatDisplay(val: boolean) {
    this._toggleChatDisplay.next(val)
  }

}

interface MatterMostUnreadResponse {
  channel_id: string
  mention_count: number
  mention_count_root: number
  msg_count: number
  msg_count_root: number
  team_id: string
}
