import { Asset, TagLink } from 'contentful'
import { TApiVideo } from '../content/video/types'

export interface Article {
  article_id: string
  assigned: boolean
  categories: any[]
  data: ArticleData
  domains: string[]
  bookmarked: boolean
  already_opened: boolean
  audio_available?: boolean
  video?: TApiVideo
}
export interface ArticleData {
  excerpt: string
  metadescription?: string
  image: Asset
  pdf: string
  title: string
  url: string
  path_segments: ArticlePathSegments
  short_id: number
  video?: TagLink
}

export const UniformArticle = (article: Article): UniArticle => {
  return {
    ...article,
    ...article.data,
    id: article.article_id,
    already_opened: article.already_opened,
    video: article.data.video,
  }
}

export type UniArticle = Omit<Article, 'data' | 'video'> & ArticleData & { id: string }

export const AssignedArticleToArticle = (assignedArticle: AssignedArticle): Article => {
  return {
    article_id: assignedArticle.article,
    data: assignedArticle.article_data,
    ...assignedArticle,
    assigned: true,
    categories: [],
    domains: [],
    already_opened: assignedArticle.already_opened,
  }
}
export interface AssignedArticle {
  article: string
  article_data: ArticleData
  bookmarked: boolean
  already_opened: boolean
}

export interface ArticlePathSegments {
  fr: string
  en: string
}
