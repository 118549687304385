/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core'
import { ScrollToTopComponent } from '../button/scroll-to-top/scroll-to-top.component'
import { DemoAccordionComponent } from './accordion/accordion.component'
import { DemoBannerComponent } from './banner/banner.component'
import { ButtonComponent } from './button/button.component'
import { FontComponent } from './font/font.component'
import { DemoInputComponent } from './input/input.component'


@Component({
  selector: 'uikit-demo',
  templateUrl: './demo.component.html',
  standalone: true,
  imports: [
    ButtonComponent,
    FontComponent,
    DemoInputComponent,
    DemoAccordionComponent,
    DemoBannerComponent,
    ScrollToTopComponent,
  ],
})
export class DemoComponent {

}
