import { CdkAccordionModule } from '@angular/cdk/accordion'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'lib-accordion',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule],
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input() expanded = false
  @Input() public pendoId: string

  @Output() expandedChange = new EventEmitter<boolean>()

  public toggle(): void {
    this.expanded = !this.expanded
    this.expandedChange.emit(this.expanded)
  }

  public get pendoClass() {
    if (!this.pendoId) {
      return ''
    }

    const statusClass = this.expanded ? `Pendo_${this.pendoId}_close` : `Pendo_${this.pendoId}_open`

    return `Pendo_${this.pendoId} ${statusClass}`
  }
}
