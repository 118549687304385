import { Component, inject, Input, OnInit } from '@angular/core'
import { switchMap, take } from 'rxjs'
import { ContentfulService } from 'src/app/shared/services/contentful.service'
import { ConfigService } from '../../../misc/config/config.service'

@Component({
  selector: 'app-cta-create-account',
  templateUrl: './cta-create-account.component.html',
  styles: [],
})
export class CtaCreateAccountComponent implements OnInit {
  @Input() content: any | null
  confServ = inject(ConfigService)
  contentFullServ = inject(ContentfulService)
  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    if (!this.content?.article_data?.ctaSection) {
      this.confServ.config$.pipe(
        take(1),
        switchMap(configs => this.contentFullServ.getElementById(configs.cta_create_account_unregistered_default, true)),
      ).subscribe((res: any) => {
        this.content = res?.fields
        if (this.content?.title) {
          this.content.title = this.contentfulService.transformHTMLEntities(this.content.title, [{ entity: '&amp;nbsp;', value: '&nbsp;' }])
        }
        if (this.content?.subtitle) {
          this.content.subtitle = this.contentfulService.transformHTMLEntities(this.content?.subtitle, [{ entity: '&amp;nbsp;', value: '&nbsp;' }])
        }
      })
    } else {
      this.content = this.content?.article_data?.ctaSection?.fields
      if (this.content?.title) {
        this.content.title = this.contentfulService.transformHTMLEntities(this.content.title, [{ entity: '&amp;nbsp;', value: '&nbsp;' }])
      }
      if (this.content?.subtitle) {
        this.content.subtitle = this.contentfulService.transformHTMLEntities(this.content?.subtitle, [{ entity: '&amp;nbsp;', value: '&nbsp;' }])
      }
    }
  }

}
