<div class='mx-4 lg:w-3/5 lg:mx-auto'>
  <a routerLink='/auth/login'>
    <img class='block mx-auto w-52' src='assets/img/logo/luci.svg' alt='logo'>
  </a>
  <div class='bg-green-50 rounded-3xl p-8 mt-8 text-center font-sans font-semibold'>
    <h1 i18n>Votre compte a été supprimé avec succès</h1>
    <p i18n>Toutes vos données ont été effacées de nos serveurs.</p>
    <p i18n>Merci d'avoir utilisé l'application Luci.</p>
    <a href='https://luciapp.ca/' i18n i18n-href class='lu-btn lu-btn-orange block mx-auto mt-8 hover:text-white'>
      Retour à Luciapp.ca
    </a>
  </div>
</div>