<div class='theme-dashboard bg-white rounded-3xl m-4 p-4'>
  <div class='flex flex-col justify-center gap-4'>

    <div *ngIf="assessment.intellectual.id | getSubmissionById:assessment.user | async as intellectualAnswers">
      <p title-2 bold class='text-center'>Stimulation intellectuelle</p>
      <table *ngIf="intellectualItems$ | async as intellectualItems">
        <thead>
          <tr>
            <th class='border p-2'>Activitée</th>
            <th class='border p-2'>Fréquence</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of intellectualAnswers.answers.details | keyvalue">
            <td class='border p-2'> {{(intellectualItems | find:'internal_id':detail.key)['name'] }} </td>
            <td class='border p-2 text-center'>{{detail.value.frequency}} fois</td>
          </tr>
          <tr *ngFor="let detail of intellectualAnswers.answers.customActivities?.details | keyvalue">
            <td class='border p-2'> {{detail.key}} </td>
            <td class='border p-2 text-center'>{{detail.value.frequency}} fois</td>
          </tr>
          <tr>
            <td class='border p-2'>Activité de group</td>
            <td class='border p-2 text-center'>{{intellectualAnswers.answers.groupActivity.frequency}} fois</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class='flex flex-col justify-center gap-4 mt-4'
    *ngIf="assessment.physical.id | getSubmissionById:assessment.user | async as physicalAnswers">
    <p title-2 bold class='text-center'>Activité physique</p>
    <table *ngIf="physicalItems$ | async as physicalItems">
      <thead>
        <tr>
          <th class='border p-2'>Activitée</th>
          <th class='border p-2'>Sessions par semaine</th>
          <th class='border p-2'>Minutes par session</th>
          <th class='border p-2'>Intensité</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let detail of physicalAnswers.answers.details | keyvalue">
          <td class='border p-2 text-center'> {{(physicalItems | find:'internal_id':detail.key)['name'] }} </td>
          <td class='border p-2 text-center'>{{detail.value.sessionsPerWeek}}</td>
          <td class='border p-2 text-center'>{{detail.value.minutesPerSession}}</td>
          <td class='border p-2 text-center'>{{detail.value.intensity}}</td>
        </tr>
        <tr *ngFor="let detail of physicalAnswers.answers.customActivities?.details | keyvalue">
          <td class='border p-2 text-center'>{{detail.key}} </td>
          <td class='border p-2 text-center'>{{detail.value.intensity}}</td>
          <td class='border p-2 text-center'>{{detail.value.minutesPerSession}}</td>
          <td class='border p-2 text-center'>{{detail.value.sessionsPerWeek}}</td>
        </tr>
        <tr>
          <td class='border p-2 text-center'>Sedentarité</td>
          <td class='border p-2 text-center'>{{physicalAnswers.answers.sedentaryLifestyle.value}}</td>
        </tr>
      </tbody>
    </table>
  </div>


  <div class='flex flex-col justify-center gap-4 mt-4'
    *ngIf="assessment.nutrition.id | getSubmissionById:assessment.user | async as nutritionAnswers">
    <p title-2 bold class='text-center'>Alimentation</p>
    <table>
      <thead>
        <tr>
          <th class='border p-2'>Group</th>
          <th class='border p-2'>Fréquence</th>
          <th class='border p-2'>Détail</th>
          <th class='border p-2'>Portion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let answer of nutritionAnswers.answers | keyvalue">
          <td class='border p-2'> {{ itemsTranslation[answer.key]}} </td>
          <td class='border p-2' [innerHTML]="answer.value.frequence | getAnswerTranslation"></td>
          <td class='border p-2' [innerHTML]="answer.value?.details | getAnswerTranslation">
          <td class='border p-2'>{{answer.value.portion}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
