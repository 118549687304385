import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-section-block',
  templateUrl: './section-block.component.html',
  styleUrls: ['./section-block.component.sass'],
})
export class SectionBlockComponent implements OnInit {
  @Input() content
  @Input() idArticle

  @Input() parentsHistory: Array<any>
  @Input() position

  allBlock: Array<any>
  isPreview = false

  constructor() { }

  ngOnInit(): void {
    this.allBlock =  this.content.fields.block
  }

  setPH(i: number): Array<any> {
    return [...this.parentsHistory, {rang: this.parentsHistory.length, position: i, type: 'section-block'}]
  }

  setBackgroundColor() {
    if (this.position % 3 === 0 ) {
      return '#5E7D6C' // 1
    } else if ( this.position % 3 === 1 ) {
      return '#EDEFEC' // 2
    } else if ( this.position % 3 === 2 ) {
      return '#D6D6D6' // 3
    }
  }
}
