import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[appClickOutsideEvent]',
})
export class ClickOutsideElementDirective {

  @Output() appClickOutsideEvent = new EventEmitter<void>()

  constructor(
    private elementRef: ElementRef,
  ) {}

  @HostListener('document:mousedown', ['$event'])
  onDocumentClick(event: MouseEvent){
    if(!this.elementRef.nativeElement.contains(event.target)){
     this.appClickOutsideEvent.emit()
    }
  }
}
