import { CommonModule } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component'
import { ShareProgramCTAComponent, ShareProgramCTAConfig } from '../share-program-cta/share-program-cta.component'

@Component({
  selector: 'app-invite-friend-cta',
  standalone: true,
  imports: [CommonModule, MatDialogModule, ShareProgramCTAComponent, MatIconModule, DialogWrapperComponent, LucilabCommonModule],
  templateUrl: './invite-friend-cta.component.html',
})
export class InviteFriendCTAComponent {
  @ViewChild('inviteDialog', { static: true }) inviteDialog: TemplateRef<any>

  shareConfig: ShareProgramCTAConfig = {
    containerClass: '!flex-col sm:!flex-col sm:!gap-4',
    icon: 'heartFull',
    textClass: '!text-center sm:!text-center',
  }

  constructor(private matDialog: MatDialog) { }

  openInviteDialog() {
    this.matDialog.open(this.inviteDialog, {
      width: '90vw',
      maxWidth: '690px',
      panelClass: 'dialog-overflow-visible',
    })
  }

  closeInviteDialog() {
    this.matDialog.closeAll()
  }
}
