import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core'

@Directive({
  selector: '[libExternalLinkIcon]',
  standalone: true,
})
export class ExternalLinkIconDirective implements OnInit {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const iconElement = this.renderer.createElement('img')
    iconElement.setAttribute('src', './assets/img/external-link.svg')
    this.renderer.setStyle(iconElement, 'display', 'inline-block')
    this.renderer.setStyle(iconElement, 'transform', 'translateY(-0.1rem)')
    this.renderer.appendChild(this.elementRef.nativeElement, iconElement)
  }
}
