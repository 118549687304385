import { HttpClient } from '@angular/common/http'
import { Injectable, LOCALE_ID, inject } from '@angular/core'
import { environment } from 'environment/environment'
import { Observable, map, shareReplay } from 'rxjs'
import { IBackendConfigResponse, TBackendConfig } from './interfaces'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {


  private readonly locale = inject(LOCALE_ID)
  public readonly config$ = this.httpClient.get<IBackendConfigResponse>(`${environment.apiUrl}config/`).pipe(map(config => {
    const multilangProperties = [
      'trending_searches',
      'sharing_email_subject',
      'sharing_email_body',
      'contact_email',
    ]

    const translatedConfig = multilangProperties.reduce((acc, property) => {
      const translatedValue = config[property][this.locale]
      return {
        ...acc,
        [property]: translatedValue,
      }
    }, { ...config } as TBackendConfig)

    return translatedConfig
  }), shareReplay())


  private _config: ObservableReturnType<typeof this.config$>

  constructor(private readonly httpClient: HttpClient) {

    this.config$.subscribe((config) => this._config = config)
  }

  /**
   * @deprecated should be removed - you should use `config$` Observable
   */
  get Config() {
    return this._config
  }

}

type ObservableReturnType<T> = T extends Observable<infer Return> ? Return : T
