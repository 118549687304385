import { CommonModule } from '@angular/common'
import { Component, forwardRef, Input, Provider } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { FontModule } from '../../../font'
import { RadioIndicatorComponent } from '../atoms/radio-indicator/radio-indicator.component'
import { SelectionBoxComponent } from '../atoms/selection-box/selection-box.component'
import { DefaultRadioComponent } from '../default/default-radio.component'


const RADIO_TEXT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioWithTextComponent),
  multi: true,
}

@Component({
  selector: 'lib-radio-with-text',
  standalone: true,
  imports: [CommonModule, FontModule, RadioIndicatorComponent, SelectionBoxComponent],
  templateUrl: './with-text-radio.component.html',
  styleUrls: ['./with-text-radio.component.scss'],
  providers: [RADIO_TEXT_CONTROL_VALUE_ACCESSOR],
})
export class RadioWithTextComponent extends DefaultRadioComponent {

  @Input({ required: true }) description: string

}
