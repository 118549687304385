import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core'
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { RouterModule } from '@angular/router'
import { UikitModule } from '@ui'
import { PendoButtonCtaDirective } from '../../../shared/Pendo/directives/pendo-button-cta.directive'
import { NewsLetterService } from './news-letter.service'

type FormStatus = 'new' | 'submitted' | 'submitting' | 'successful'
@Component({
  selector: 'app-unregistered-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, UikitModule, FormsModule, ReactiveFormsModule, PendoButtonCtaDirective, MatFormFieldModule, MatInputModule],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsLetterService],
})
export class FooterComponent {
  readonly now = Date.now()

  readonly newsLetterForm = this.formBuilder.group({
    email: new FormControl('', { validators: [Validators.email, Validators.required] }),
    acceptedTerm: new FormControl(false, { validators: [Validators.required, Validators.requiredTrue] }),
  })

  newsLetterFormStatus: FormStatus = 'new'

  constructor(private newsLetterService: NewsLetterService, private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) { }

  submitToNewsLetter() {
    this.newsLetterFormStatus = 'submitted'
    if (this.newsLetterForm.valid) {
      this.newsLetterFormStatus = 'submitting'
      this.newsLetterService.register(this.newsLetterForm.value.email).subscribe(() => {
        this.newsLetterFormStatus = 'successful'
        this.changeDetectorRef.detectChanges()
      })
    }
  }
}
