
<div class="resource-item-externe inline-flex flex-col p-3 mb-4 md:mr-4  rounded-3xl bg-white md:border md:border-grey-400 w-[414px] h-[282px] max-w-full">
  <div class="mb-4">
    <div class="flex justify-between mb-4">
      <div class="logo w-[72px]"> <img class="!rounded-2xl" [src]="externalArticle.imageUrl"></div>
      <div class="tags h-[29px] flex items-center"></div>
    </div>
    <div class="font-buenos font-bold text-xl mb-2 line-clamp-2">{{externalArticle.title}}</div>
    <div class="text font-medium line-clamp-3"> {{externalArticle.description}}</div>
  </div>
  <div class="action flex justify-end mt-auto">
    <a target="_blank" [href]="externalArticle.externalLink" class="flex !no-underline !text-blue-500"><img class="!rounded-none" src="./assets/img/open_in_new.png" alt=""></a>
  </div>
</div>
