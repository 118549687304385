<div
  class='rounded-3xl bg-gray-200 px-6 py-6 relative mb-10 frame-content frame-style frame-superiority frame-important font-sans container-article-section-block {{content?.backgroundColor}}'
  [class.mt-10]='content?.fields?.icon && !isFirst' [class.isLastContent]='isLast'>

  <!-- optional icon -->
  <ng-container *ngIf='content?.fields?.icon'>
    <img [src]='content?.fields?.icon?.fields?.file?.url' [title]='content?.fields?.icon?.fields?.title'
      [alt]='content?.fields?.icon?.fields?.description ? content?.fields?.icon?.fields?.description : content?.fields?.icon?.fields?.title'
      class='frame-icon absolute right-0 h-20 w-20 -top-7' />
  </ng-container>

  <!-- optional title -->
  <ng-container *ngIf='content?.fields?.title'>
    <p [innerHTML]='content?.fields?.title'></p>
  </ng-container>

  <ng-container *ngIf="content?.fields?.content_text">
    <div [innerHTML]='content?.fields?.content_text'>
    </div>
  </ng-container>

  <ng-container *ngIf="content?.fields?.content_list">
    <div class='mt-4'
      *ngFor="let section of content?.fields?.content_list; let i = index; last as isLast; first as isFirst">
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "content2Columns"' class='font-sans'>
        <app-two-columns [content]='section.fields' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
        </app-two-columns>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "frames"' class='font-sans'>
        <app-frames [content]='section' [position]='i' [isLast]='isLast' [isFirst]="isFirst"
          [parentsHistory]="setPH(i)">
        </app-frames>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentSections"' class='font-sans'>
        <app-section-block [content]='section' [position]='i' [parentsHistory]="setPH(i)">
        </app-section-block>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentText"' class='font-sans'>
        <app-text-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
        </app-text-block>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentImages"' class='font-sans'>
        <app-image-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
        </app-image-block>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "article_section"' class='font-sans'>
        <app-article-section-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
        </app-article-section-block>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentContainerImages"' class='font-sans'>
        <app-container-image-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
        </app-container-image-block>
      </ng-container>
      <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentTable"' class='font-sans'>
        <app-table-block [sectionBackgroundColor]='false' [content]='section' [isLast]='isLast' [position]='i'
          [parentsHistory]="setPH(i)">
        </app-table-block>
      </ng-container>

      <ng-container *ngIf="section?.sys?.contentType?.sys?.id === 'article'">
        <app-library-article-card  [articleId]="section.sys.id" [content]="section"></app-library-article-card>
      </ng-container>

    </div>
  </ng-container>
</div>
