<div class='flex items-start flex-wrap mb-10 mt-4 lg:mt-0 !text-white lg:!text-blue-500'>
  <a [routerLink]='libRootUrl'
    class='!text-white lg:!text-blue-500 border lg:border-blue-500 mr-1 mb-2.5 px-5 py-1 rounded-xl'
    i18n>Bibliothèque</a>
  <mat-icon class='mr-1 md:mr-2.5 mt-[.375rem] text-3xl !flex items-center justify-center !w-[20px] md:!w-auto'>
    chevron_right
  </mat-icon>


  <ng-container *ngFor="let breadcrumbItem of breadcrumbItems; index as index">
    <a [ngClass]="{'pointer-events-none' : breadcrumbItems.length - 1 === index }" [routerLink]="libRootUrl+'/'+breadcrumbItem.url" class='!text-white lg:!text-blue-500 border lg:border-blue-500 mr-1 mb-2.5 px-5 py-1 rounded-xl'>{{ breadcrumbItem.title }}</a>
    <mat-icon *ngIf='breadcrumbItems.length - 1 !== index' class='mr-1 md:mr-2.5 mt-[.375rem] text-3xl !flex items-center justify-center !w-[20px] md:!w-auto'>chevron_right</mat-icon>
  </ng-container>

  <ng-content select='[levelOne]'></ng-content>
  <mat-icon *ngIf='ref.hasChildNodes()' class='mx-2.5 text-3xl !flex items-center'>chevron_right</mat-icon>
  <div #ref>
    <ng-content select='[levelTwo]'></ng-content>
  </div>
</div>
