import { Clipboard } from '@angular/cdk/clipboard'
import { Component, Input } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SnackbarSuccessComponent } from '../../../misc/components/snackbar-success/snackbar-success.component'

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
})
export class CopyLinkComponent{

  @Input() url: string

  constructor(
    private clipboard: Clipboard,
    private matSnackBar: MatSnackBar,
    ) { }

  copyToClipboard(event? :Event){
    event?.stopPropagation()
    const isCopy = this.clipboard.copy(this.url)
    if(isCopy){
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        verticalPosition: 'top',
        panelClass: 'snackbar-success-wrapper',
        duration: 3500,
        data: {customMessage: $localize`Le lien a été copié`},
      })
    }
  }
}
