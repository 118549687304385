import { Pipe, PipeTransform } from '@angular/core'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Document } from '@contentful/rich-text-types'

@Pipe({
  name: 'documentToText',
  standalone: true,
})
export class DocumentTohtmlText implements PipeTransform {

  transform(value: Document): string {
    return documentToPlainTextString(value)
  }

}
