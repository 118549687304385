<section id='reference-block' class='rounded-3xl px-4 lg:px-8 py-12'>
  <div>
    <div class='theme-promo font-bold pb-2 mb-6 flex flex-col'>
      <span class='text-white mr-4 text-6xl'>{{ index + 1 }}</span>
      <ng-container *ngIf='content?.fields?.title else defaultTitle'>
        <h2 class='!text-white !pt-4'>{{ content?.fields?.title }}</h2>
      </ng-container>
      <ng-template #defaultTitle>
        <h2 i18n class='!text-white !pt-4'>Pour en savoir plus</h2>
      </ng-template>
    </div>

    <ng-container *ngIf='content?.fields?.internalResources?.length > 0'>
      <div *ngFor="let item of content?.fields?.internalResources" class="mb-2 last:mb-0">
        <ng-container *ngIf="(item.article_data.article | contentFulArticleToArticle | async) as article">
          <app-article-card [article]='article' cardType="list" />
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf='content?.fields?.externalResources?.length > 0'>
      <div class='external-resource-item mt-8'>
        <div *ngFor='let extRes of content?.fields?.externalResources' class='resource-item overflow-hidden my-4'>
          <ng-container *ngIf='extRes?.fields?.bibliographicUrl'>
            <div class='flex items-center'>
              <a [href]='extRes?.fields?.bibliographicUrl' target='_blank'
                class='w-14 flex flex-col items-center sameWidth'>
                <ng-container *ngIf='extRes?.fields?.bibliographicRecordLogo'>
                  <img [src]='extRes?.fields?.bibliographicRecordLogo?.fields?.file?.url'
                    [alt]='extRes?.fields?.bibliographicRecordLogo?.fields?.title'>
                  <span class='text-white text-center leading-3 text-xs'>
                    {{ extRes?.fields?.bibliographicRecordType }}
                  </span>
                </ng-container>
              </a>
              <div class='text ml-4 md:ml-8 py-2'>
                <div class='res-title' [innerHTML]='extRes?.fields?.bibliographicRecord'></div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf='!extRes?.fields?.bibliographicUrl'>
            <div class='flex items-center'>
              <div class='w-14 flex flex-col items-center'>
                <ng-container *ngIf='extRes?.fields?.bibliographicRecordLogo'>
                  <img [src]='extRes?.fields?.bibliographicRecordLogo?.fields?.file?.url'
                    [alt]='extRes?.fields?.bibliographicRecordLogo?.fields?.title'>
                  <span
                    class='text-white text-center leading-3 text-xs'>{{extRes?.fields?.bibliographicRecordType}}</span>
                </ng-container>
              </div>
              <div class='text p-2 ml-8'>
                <div class='res-title  !mb-0' [innerHTML]='extRes?.fields?.bibliographicRecord'></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</section>
