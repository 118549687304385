/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[caption-1]',
})
export class Caption1Directive {
  @HostBinding('class') class = 'caption-1'
}

@Directive({
  standalone: true,
  selector: '[caption-2]',
})
export class Caption2Directive {
  @HostBinding('class') class = 'caption-2'
}

@Directive({
  standalone: true,
  selector: '[caption-3]',
})
export class Caption3Directive {
  @HostBinding('class') class = 'caption-3'
}

@Directive({
  standalone: true,
  selector: '[caption-4]',
})
export class Caption4Directive {
  @HostBinding('class') class = 'caption-4'
}

@Directive({
  standalone: true,
  selector: '[caption-5]',
})
export class Caption5Directive {
  @HostBinding('class') class = 'caption-5'
}

