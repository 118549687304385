import { AfterViewInit, Directive, ElementRef } from '@angular/core'
import { LinkService } from '../link/link.service'


/**
 * This directive is only to find all the a html tag
 * in the innerHTML directive of Angular and bind a click event
 * to those tag.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[innerHTML]',
})
export class InnerHTMLDirective implements AfterViewInit {

  constructor(
    private element: ElementRef,
    private linkService: LinkService,
  ) {
  }

  ngAfterViewInit(): void {
    for (const key in this.element.nativeElement.children) {
      const element = this.element.nativeElement.children[key]

      if (element && element.querySelectorAll) {

        const aTags = element.querySelectorAll('a')

        aTags.forEach(tag => {
          tag.addEventListener('click', (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()

            this.linkService.open(tag.href)
          })
        })
      }
    }
  }
}
