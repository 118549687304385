<lib-selection-box [selected]="checked">
  <div class="radio-with-text-component"
    [ngClass]="{'radio-with-text-component--checked': checked, 'radio-with-text-component--disabled': disabled}"
    (click)="toggle()">
    <div class='radio-with-text-component-text-wrapper'>
      <label title-4 bold class='radio-with-text-component-label'>{{label}}</label>
      <p caption-4 class='radio-with-text-component-description'>{{description}}</p>
    </div>
    <input class='radio-with-text-component-input' type='radio' [disabled]="disabled" [value]="value" />
    <lib-radio-indicator [checked]="checked" [inverted]="inverted" />
  </div>
</lib-selection-box>
