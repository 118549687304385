import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlSegment } from '@angular/router'
import { map } from 'rxjs'
import { UserService } from '../misc/services/user/user.service'

export const articleUserAuthGuardFn = (publicRoute: boolean): CanActivateFn => (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const userService = inject(UserService)
  const router = inject(Router)

  const getSegmentsFn = (child: ActivatedRouteSnapshot): UrlSegment[] => [...child.url, ...child.children.map(getSegmentsFn).flat()]
  const segments = getSegmentsFn(route).map(segment => segment.path)

  // Apply this guard only on "article" routes
  // TODO : this check should not be done and the guard should be on the selected routes only
  if (!segments.some(segment => segment.includes('article'))) {
    return true
  }

  return userService.user$.pipe(map(({ is_coach, anonymous }) => {
    // Don't apply for coach
    if (is_coach) {
      return true
    }

    if (anonymous === publicRoute) {
      return true
    }

    const computeNewRoute = () => {
      if (!publicRoute) {
        // Redirect the user on the public site
        return ['public', ...segments]
      }

      // Redirect the user on the register site
      return segments.filter(segment => segment !== 'public')
    }

    const newRoute = computeNewRoute()
    return router.createUrlTree(newRoute, { queryParams: route.queryParams })
  }))
}
