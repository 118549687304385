<ng-container *ngIf="show">
  <div class='component-banner'>
    <div class='component-banner-info flex'>
      <img class='component-banner-icon w-[21px] h-[21px]' src='./assets/img/banner_component/{{mode}}.svg'>
      <div class='component-banner-text flex flex-col'>
        <p title-4 bold class='component-banner-title'>{{title}}</p>
        <p caption-4 class='component-banner-description'>{{description}}</p>
        <div *ngIf="primaryBtnLabel || secondaryBtnLabel" class='component-banner-buttons flex'>
          <button *ngIf="primaryBtnLabel" primary small rounded [color]='mode'
            (click)='primaryBtnClick.emit()'>{{primaryBtnLabel}}</button>
          <button *ngIf="secondaryBtnLabel" tertiary small rounded [color]='mode'
            (click)='secondaryBtnClick.emit()'>{{secondaryBtnLabel}}</button>
        </div>
      </div>
      <img class='component-banner-close cursor-pointer' src='./assets/img/banner_component/close-{{mode}}.svg'
        (click)="close()">
    </div>
  </div>
</ng-container>
