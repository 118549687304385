/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[bold]',
})
export class BoldDirective {
  @HostBinding('class') class = 'bold'
}


@Directive({
  standalone: true,
  selector: '[semi-bold]',
})
export class SemiBoldDirective {
  @HostBinding('class') class = 'semi-bold'
}

