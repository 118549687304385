import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import { UserService } from 'src/app/misc/services/user/user.service'

@Component({
  selector: 'app-unsubscribe-notification',
  templateUrl: './unsubscribe-notification.component.html',
  styleUrls: ['./unsubscribe-notification.component.sass'],
})

export class UnsubscribeNotificationComponent {
  error = true
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
    this.route.queryParamMap.pipe(
      switchMap(params => {
        return this.userService.unsubscribeNotification({ uid: params['params']['id'], token: params['params']['token'] })
      }),
    ).subscribe(res => {
      if (res) {
        this.error = false
      }
    })

  }
}
