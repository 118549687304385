import { Directive, HostListener, Input } from '@angular/core'
import { LinkService } from './link.service'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[href]',
})
export class LinkDirective {
  @Input() href: string

  constructor(private linkService: LinkService) { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.linkService.open(this.href)
  }

}
