<app-main-layout>
  <ng-container *ngIf="terms$ |async as terms">
    <div class='h-full w-full flex flex-col ' termsContent>
      <div class='bg-white rounded-lg px-3 py-8 md:p-10 w-full max-w-[60rem] mx-auto mt-11'>
        <div [class.hidden]="videoSeen" [class.block]="!videoSeen">
          <div class='text-center flex flex-col items-center justify-center max-w-[720px] mx-auto'>
            <h1 class='!mb-5 md:px-4' i18n>Politique de protection des renseignements personnels de l’application
              Luci</h1>
            <ng-container *ngIf='terms.confidentiality_policy_description'>
              <div class='bg-grey-1001 flex flex-row items-center justify-start rounded-md mb-5 py-3 px-5'>
                <img src='./assets/img/icon_hint.svg' alt="hint" class='w-[35px] h-[45px]'>
                <p class='pl-4 text-left leading-5 font-medium font-sans !mb-0'>
                  {{ terms.confidentiality_policy_description}}
                </p>
              </div>
            </ng-container>
            <a href='https://lucilab.ca/politique-app/' target='_blank'
              class='underline mb-5 inline-block font-medium font-sans' i18n-href><span i18n>Lisez la politique
                complète ici.</span></a>
            <!-------------------->
            <!-- VIDEO SECTION  -->
            <!-------------------->
            <div class='mb-5 w-full  max-w-[720px] h-full hidden' [class.!block]="terms.confidentiality_policy_video">

              <app-video-player #videoPlayer [video]="{
                src: terms.confidentiality_policy_video.playback_url,
                poster: terms.confidentiality_policy_image.url,
              }" [autoplay]="true" />

            </div>
            <!-------------------->
            <!-- VIDEO SECTION  -->
            <!-------------------->
            <button class='lu-btn lu-btn-orange' pendoClass='continue-to-term' (click)='continueToTerms()'
              i18n>Continuer</button>
          </div>
        </div>

        <div [class.block]="videoSeen" [class.hidden]="!videoSeen">
          <div class='text-center flex flex-col items-center justify-center'>
            <h1 class='!mb-5' i18n>Conditions d’utilisation</h1>
            <ng-container *ngIf='terms.terms_description'>
              <div class='bg-grey-1001 flex flex-row items-center justify-start rounded-md mb-5 py-3 px-5'>
                <img src='./assets/img/icon_hint.svg' alt="hint" class='w-[35px] h-[45px]'>
                <p class='pl-4 text-left leading-5 font-medium font-sans !mb-0'>
                  {{ terms.terms_description}}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf='terms.content'>
              <div class="terms">
                <div
                  class="terms-content md:!mx-11 mt-2 mb-4 p-4 overflow-y-auto rounded-md border-2 border-grey-200 text-sm"
                  [innerHTML]='terms.content'></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf='videoSeen'>
        <form [formGroup]='termsForm' (ngSubmit)='onSubmit()' class='mt-auto'>
          <div>
            <mat-checkbox formControlName='accept_terms' color='primary' class='font-sans font-medium'>
              <ng-container i18n>Je confirme avoir lu et j’accepte les</ng-container>
              <a i18n href='https://lucilab.ca/conditions-utilisation-app' i18n-href target='_blank' rel='noopener'>
                Modalités</a>
            </mat-checkbox>
            <div class="terms-actions flex justify-center gap-3">
              <button class='lu-btn lu-btn-orange' i18n>Accepter</button>
              <a class='lu-btn hover:!text-white' i18n (click)='onLogout()' pendoClass='disconnect-from-term'>Me
                déconnecter</a>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </ng-container>
</app-main-layout>
