import { Component, Input, OnInit } from '@angular/core'
import { ContentfulService } from 'src/app/shared/services/contentful.service'

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.sass'],
})
export class TextBlockComponent implements OnInit{
  @Input() content: any | null
  @Input() isLast
  @Input() sectionNumber

  @Input() parentsHistory: Array<any>
  @Input() position

  constructor(
    private contentfulService: ContentfulService,
  ) {
  }

  ngOnInit(): void {
    if (this.content?.fields?.content_text?.content?.length) {
      this.content.fields.content_text =  this.contentfulService.formatHTML(this.content.fields.content_text, { author: this.content.fields.citation_author, position: this.parentsHistory[0].position})
    }
  }

}
