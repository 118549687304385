import { Location } from '@angular/common'

export const updateLocation = (wantedUrl: string, angularLocation: Location) => {

  const currentLocation = location.href

  if (currentLocation.includes(wantedUrl)) return

  angularLocation.replaceState(wantedUrl)

}
