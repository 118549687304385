/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core'


@Directive({
  standalone: true,
  selector: 'button[primary]',
})
export class PrimaryDirective {
  @HostBinding('class') class = 'primary'
}

@Directive({
  standalone: true,
  selector: 'button[secondary]',
})
export class SecondaryDirective {
  @HostBinding('class') class = 'secondary'
}

@Directive({
  standalone: true,
  selector: 'button[tertiary]',
})
export class TertiaryDirective {
  @HostBinding('class') class = 'tertiary'
}

@Directive({
  standalone: true,
  selector: 'button[borderless]',
})
export class BorderlessDirective {
  @HostBinding('class') class = 'borderless'
}

@Directive({
  standalone: true,
  selector: 'button[danger]',
})
export class DangerDirective {
  @HostBinding('class') class = 'danger'
}

@Directive({
  standalone: true,
  selector: 'button[warning]',
})
export class WarningDirective {
  @HostBinding('class') class = 'warning'
}

@Directive({
  standalone: true,
  selector: 'button[success]',
})
export class SuccessDirective {
  @HostBinding('class') class = 'success'
}

@Directive({
  standalone: true,
  selector: 'button[info]',
})
export class InfoDirective {
  @HostBinding('class') class = 'info'
}

@Directive({
  standalone: true,
  selector: 'button[color]',
})
export class ColorDirective {
  @HostBinding('class') get class() {
    return this.color
  }
  @Input() color?: 'danger' | 'warning' | 'info' | 'success'
}

@Directive({
  standalone: true,
  selector: 'button[rounded]',
})
export class RoundedDirective {
  @HostBinding('class') class = 'rounded'
}

@Directive({
  standalone: true,
  selector: 'button[rectangle]',
})
export class RectangleDirective {
  @HostBinding('class') class = 'rectangle'
}

@Directive({
  standalone: true,
  selector: 'button[shape]',
})
export class ShapeDirective {
  @HostBinding('class') get class(): string {
    return this.shape
  }
  @Input() shape: 'rounded' | 'rectangle' = 'rounded'
}

@Directive({
  standalone: true,
  selector: 'button[icon]',
})
export class IconDirective {
  @Input() icon!: string
  @Input() iconColor: string = 'white'
  @Input() iconPosition?: 'left' | 'right' | 'icon-only'

  @HostBinding('style.--content') get backgroundImage(): string {
    if (!this.positionClass) return ''

    return `url(/assets/img/${this.icon}.svg)`
  }
  @HostBinding('class') get positionClass() {
    switch (this.iconPosition) {
      case 'left':
        return 'icon-left'
      case 'right':
        return 'icon-right'
      case 'icon-only':
        return 'icon-only'
      default:
        return ''
    }
  }
}

@Directive({
  standalone: true,
  selector: 'button[inverted]',
})
export class InvertedDirective {
  @HostBinding('class') get class() {
    return this.inverted ? 'inverted' : ''
  }
  @Input({
    transform: booleanAttribute,
  }) inverted: boolean = true
}

@Directive({
  standalone: true,
  selector: 'button[large]',
})
export class LargeDirective {
  @HostBinding('class') class = 'large'
}

@Directive({
  standalone: true,
  selector: 'button[medium]',
})
export class MediumDirective {
  @HostBinding('class') class = 'medium'
}


@Directive({
  standalone: true,
  selector: 'button[small]',
})
export class SmallDirective {
  @HostBinding('class') class = 'small'
}

@Directive({
  standalone: true,
  selector: 'button[size]',
})
export class SizeDirective {
  @HostBinding('class') get class() {
    return this.size
  }
  @Input() size?: 'large' | 'medium' | 'small'
}
