/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[title-1]',
})
export class Title1Directive {
  @HostBinding('class') class = 'title-1'
}

@Directive({
  standalone: true,
  selector: '[title-2]',
})
export class Title2Directive {
  @HostBinding('class') class = 'title-2'
}

@Directive({
  standalone: true,
  selector: '[title-3]',
})
export class Title3Directive {
  @HostBinding('class') class = 'title-3'
}

@Directive({
  standalone: true,
  selector: '[title-4]',
})
export class Title4Directive {
  @HostBinding('class') class = 'title-4'
}

