import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ClickOutsideElementModule } from '../../directives/click-outside-element/click-outside-element.module'
import { CopyLinkModule } from '../copy-link/copy-link.module'
import { EmailShareModule } from '../email-share/email-share.module'
import { FacebookShareModule } from '../facebook-share/facebook-share.module'
import { MessengerShareModule } from '../messenger-share/messenger-share.module'
import { ShareMethodsContainerComponent } from './share-methods-container.component'



@NgModule({
  declarations: [ShareMethodsContainerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FacebookShareModule,
    CopyLinkModule,
    EmailShareModule,
    MessengerShareModule,
    ClickOutsideElementModule,
  ],
  exports: [ShareMethodsContainerComponent],
})
export class ShareMethodsContainerModule { }
