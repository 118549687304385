import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IInputColors } from './checkbox-square-colors'

@Component({
  selector: 'app-checkbox-square',
  templateUrl: './checkbox-square.component.html',
  styles: [
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxSquareLabeledComponent),
      multi: true,
    },
  ],
})
export class CheckboxSquareLabeledComponent implements ControlValueAccessor {

  @Input() colors: IInputColors = {
    border: 'white',
    background: 'white',
    selectedBorder: '#E68B30',
    selectedBackground: '#FFF5EB',
    textColor: '',
    selectedTextColor: '',
  }

  field = false
  disabled = false
  constructor() { }

  onChange: any = (value) => { }
  onTouch: any = () => { }

  set value(value: boolean) {
    this.field = value
    this.onChange(value)
    this.onTouch(value)
  }

  writeValue(value: any): void {
    this.value = value
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  handleClick() {
    this.value = !this.field
  }

}
