import { Pipe, PipeTransform } from '@angular/core'
import { TranslationService } from '../translation.service'
import { TChoiceInternalId } from '../types'

@Pipe({
  name: 'getAnswerTranslation',
  standalone: true,
})
export class GetAnswerTranslationPipe implements PipeTransform {
  constructor(private readonly translationService: TranslationService) {}

  transform(value: TChoiceInternalId): string {
    return this.translationService.choicesLabels[value]?.formated
  }
}
