<div class='relative cursor-pointer' (appClickOutsideEvent)='isOpen = false'>
  <mat-icon (click)='isOpen = !isOpen' class='text-white lg:text-gray-700' pendoClass='open-share-article-cta'>share</mat-icon>

  <div
    class='absolute w-[90px] h-[186px] lg:w-[80px] lg:h-[164px] bg-white lg:bg-gray-100 shadow-md flex flex-col justify-center items-center rounded-full  left-[-130%] lg:left-[-100%]'
    [ngClass]='{"h-[248px] lg:h-[218px] ": isMobile ,"hidden": !isOpen}'
  >
    <div class='triangle'></div>
      <div class=' w-[26px] lg:w-[23px] h-[80%] flex flex-col justify-around' [ngClass]='{"mt-5": !isMobile }'>
        <app-email-share [subject]='emailTitle' [body]='emailMessageContent'></app-email-share>
        <app-facebook-share [sharedUrl]='urlToShare'></app-facebook-share>
        <app-copy-link [url]='urlToShare'></app-copy-link>
        <app-messenger-share *ngIf="isMobile" [url]='urlToShare' ></app-messenger-share>
      </div>
  </div>
</div>
