import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { PendoButtonCtaDirective } from '../../Pendo/directives/pendo-button-cta.directive'
import { PendoRouterLinkCtaDirective } from '../../Pendo/directives/pendo-routerlink-cta.directive'
import { RecommendedForAnonymComponent } from './recommended-for-anonym.component'



@NgModule({
  declarations: [RecommendedForAnonymComponent],
  imports: [
    CommonModule,
    RouterModule,
    PendoButtonCtaDirective,
    PendoRouterLinkCtaDirective,
  ],
  exports: [
    RecommendedForAnonymComponent,
  ],
})
export class RecommendedForAnonymModule { }
