import { Component, ViewChild, inject } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { map, share, tap } from 'rxjs'
import { UserService } from 'src/app/misc/services/user/user.service'
import { TermsService } from '../../services/terms.service'
import { VideoPlayerComponent } from '../video-player/video-player.component'

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.sass'],
})
export class TermsComponent {
  @ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent | null

  private router = inject(Router)
  private fb = inject(UntypedFormBuilder)
  private termsService = inject(TermsService)
  private userServ = inject(UserService)
  user$ = this.userServ.user$.pipe(share())
  videoSeen: boolean = false

  termsForm = this.fb.group({
    version: [],
    accept_terms: [],
  })

  full: boolean = false

  terms$ = this.termsService.getTerms().pipe(
    map(terms => terms.results[0]),
    tap((term) => { this.termsForm.patchValue({ version: term.version }) }))

  constructor() { }

  onLogout(): void {
    this.userServ.logout()
  }

  onSubmit(): void {
    this.termsService
      .acceptTerms(this.termsForm.value)
      .subscribe(() => {
        const user = this.userServ.user
        if (user.is_coach) {
          this.router.navigate(['/coach'])
        } else {
          this.router.navigate(['/dashboard'])
        }
      })
  }

  continueToTerms() {
    this.videoPlayer?.pause()
    this.videoSeen = true
  }

  logout() {
    this.userServ.logout()
  }
}
