<form class='p-7 flex flex-col gap-2' [formGroup]="form" (submit)="form.submit()">
  <lib-input-field type='text' formControlName='name' label='Nom' helpText="Votre nom" [required]="true">
    <lib-validation-indicator class='mb-2' [validationIndicator]="{label: 'Name Error', type: 'error'}" />
  </lib-input-field>

  <lib-input-field type='text' formControlName='email' label='Email' [required]="true" helpText="Votre email"
    placeholder="Email">
    <lib-validation-indicator class='mb-2' [validationIndicator]="{label: 'Email Error', type: 'error'}" />
  </lib-input-field>

  <lib-input-field type='password' formControlName='password' label='Password' [required]="true" placeholder="Password"
    [passwordLabels]="{ hidden: 'AFFICHER', visible: 'MASQUER' }">
    <lib-validation-indicator *ngIf="form.controls.password.errors"
      [validationIndicator]='{label: "Le mot de passe doit contenir au moins 8 caractères", type: "error"}' />
  </lib-input-field>

  <lib-input-field type="integer" formControlName="age" label="Age" [required]="true" helpText="Age" [placeholder]="0">
    <lib-validation-indicator *ngIf="form.controls.age.errors?.required"
      [validationIndicator]='{label: "Age error required", type: "error"}' />
    <lib-validation-indicator *ngIf="form.controls.age.errors?.min"
      [validationIndicator]='{label: "Age error min", type: "error"}' />
    <lib-validation-indicator *ngIf="form.controls.age.errors?.max"
      [validationIndicator]='{label: "Age error max", type: "error"}' />
  </lib-input-field>

  <lib-input-field type='integer' formControlName='number' label='Just a number' [required]="true" [placeholder]="50"
    [min]='22' [max]='222'>
    <lib-validation-indicator *ngIf="form.controls.number.errors?.required"
      [validationIndicator]='{label: "Number error required", type: "error"}' />
    <lib-validation-indicator *ngIf="form.controls.number.errors?.min"
      [validationIndicator]='{label: "Number error min", type: "error"}' />
    <lib-validation-indicator *ngIf="form.controls.number.errors?.max"
      [validationIndicator]='{label: "Number error max", type: "error"}' />
  </lib-input-field>

  <lib-input-field type='text' formControlName='disabled' label='Disabled' helpText="Disabled input"
    errorLabel="erreur" />

  <div class='flex flex-col gap-2'>
    <lib-input-checkbox formControlName='checkbox' label='Disable Radio' />

    <lib-input-default-radio formControlName='radio' label='Oui' value='yes' [disabled]='form.value.checkbox' />
    <div class='bg-grey-600'>
      <lib-input-default-radio formControlName='radio' label='Non' value="no" [inverted]='true'
        [disabled]='form.value.checkbox' />
    </div>
    <lib-radio-with-text formControlName='radio' label='Légère'
      description='Je bouge doucement. Je ressens peu de fatigue. Je ne suis pas essouflé·e.' name="radio"
      value='legere' [disabled]='form.value.checkbox' />
  </div>


  <button primary type='submit' size="medium">soumettre</button>
</form>
{{ form.value | json }}
