import { LOCALE_ID, inject } from '@angular/core'
import { debounceTime, switchMap, take, tap } from 'rxjs'
import { getToken } from '../auth/utils/token-utils'
import { UserService } from '../misc/services/user/user.service'
import { User } from '../shared/interfaces/user'
import { AnonService } from '../shared/services/anon.service'


const excludedRoutesForAnonymousSignIn = 'auth'


export function initUser(userService: UserService, anonymousService: AnonService) {
  const token = getToken()
  const locale = inject(LOCALE_ID)

  return () => userService.user$.pipe(
    take(1),
    switchMap(() => {
      if (token !== null) {
        return userService.getUser()
      }

      if (location.href.includes(excludedRoutesForAnonymousSignIn)) {

        return userService.user$.pipe(
          debounceTime(10),
          tap(() => userService.user = { anonymous: true, prefered_lang: locale } as User),
          take(1),
        )
      }

      return anonymousService.anonSignup().pipe(
        switchMap(() => userService.getUser()),
      )
    }),
  )
}


