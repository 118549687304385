<ng-container *ngIf='!data'>
  <p i18n>Votre profil a été mis à jour avec succès.</p>
</ng-container>

<ng-container *ngIf='data && !data.customMessage'>
  <p i18n>Votre rendez-vous est confirmé.</p>
</ng-container>

<ng-container *ngIf="data && data.customMessage">
  <p>{{data.customMessage}}</p>
</ng-container>
