import { CommonModule } from '@angular/common'
import { Component, LOCALE_ID, inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { ConfigService } from '../../../../misc/config/config.service'
import { PendoButtonCtaDirective } from '../../../../shared/Pendo/directives/pendo-button-cta.directive'
import { DialogWrapperComponent } from '../../../../shared/components/dialog-wrapper/dialog-wrapper.component'
import { LucilabCommonModule } from '../../../lucilab-common/lucilab-common.module'
import { IInputColors } from '../../checkbox-square/checkbox-square-colors'
import { RadioInputContainerComponent } from '../../radio-input-container/radio-input-container.component'

@Component({
  selector: 'app-dialog-cta-contact-coach',
  templateUrl: './dialog-cta-contact-coach.component.html',
  standalone: true,
  imports: [CommonModule, DialogWrapperComponent, PendoButtonCtaDirective, RadioInputContainerComponent, FormsModule, ReactiveFormsModule, MatIconModule, LucilabCommonModule],
})
export class DialogCtaContactCoachComponent {

  private config = inject(ConfigService)
  private locale = inject(LOCALE_ID)
  private formBuilder = inject(FormBuilder)
  public dialogRef = inject(MatDialogRef<DialogCtaContactCoachComponent>)

  readonly checkboxConfig: IInputColors = {
    border: 'white',
    background: 'white',
    selectedBorder: '#5E7D6C',
    selectedBackground: '#FFF5EB',
    textColor: '',
    selectedTextColor: '',
  }

  readonly selectionCardData: TCardData = {
    call: { image: './assets/img/cta/contact-by-phone.svg', title: $localize`Poser ma question par téléphone ou par appel vidéo` },
    chat: { image: './assets/img/cta/contact-by-chat.svg', title: $localize`Poser ma question par clavardage` },
    email: { image: './assets/img/cta/contact-by-mail.svg', title: $localize`Envoyer ma question par courriel` },
    phone: { image: './assets/img/cta/phone-icon.svg', title: $localize`Appel téléphonique`, subTitle: $localize`Un·e membre de l'équipe Luci vous appellera au jour et à l'heure sélectionnés` },
    video: { image: './assets/img/cta/video-icon.svg', title: $localize`Appel vidéo`, subTitle: $localize`Vous recevrez un lien pour rejoindre l'appel au jour et à l'heure sélectionnés` },
  }

  currentView: ContactCoachViews = 'callEmailChatSelectionView'

  readonly form: FormGroup<{ callEmailChatSelection: FormControl<AppointmentType>, videoOrCallSelectionView: FormControl<CallAppointmentType> }> = this.formBuilder.group({
    callEmailChatSelection: new FormControl<AppointmentType>(null),
    videoOrCallSelectionView: new FormControl<CallAppointmentType>(null),
  })

  nextView() {
    switch (this.currentView) {
      case 'callEmailChatSelectionView':
        this.handleAppointmentTypeSelection(this.form.controls.callEmailChatSelection.value)
        break
      case 'videoOrPhoneSelectionView':
        this.handleVideoOrCallSelection(this.form.controls.videoOrCallSelectionView.value)
        break
    }
  }

  previousView() {
    if (this.currentView === 'videoOrPhoneSelectionView') {
      this.currentView = 'callEmailChatSelectionView'
    }
  }

  sendQuestionByEmail() {
    location.href = `mailto:${this.config.Config.contact_email}`
    this.dialogRef.close()
  }

  private handleAppointmentTypeSelection(selectedAppointment: AppointmentType) {
    switch (selectedAppointment) {
      case 'call':
        this.currentView = 'videoOrPhoneSelectionView'
        break
      case 'chat':
        this.currentView = 'unavailableCoachView'
        break
      case 'email':
        this.sendQuestionByEmail()
        break
    }
  }

  handleVideoOrCallSelection(callOrVideo: CallAppointmentType) {
    const urlMapper: { [type in CallAppointmentType]: string } = {
      phone: this.config.Config?.[`entrypoint_external_chat_${this.locale}`],
      video: this.config.Config?.[`entrypoint_external_video_${this.locale}`],
    }

    window.open(urlMapper[callOrVideo])
    this.dialogRef.close()
  }

}

type AppointmentType = 'call' | 'chat' | 'email'
type CallAppointmentType = 'video' | 'phone'
type ContactCoachViews = 'callEmailChatSelectionView' | 'videoOrPhoneSelectionView' | 'unavailableCoachView'

interface SelectionCard {
  image: string
  title: string
  subTitle?: string
}

type TCard = AppointmentType | CallAppointmentType
type TCardData = { [type in TCard]: SelectionCard }
