<div class='flex flex-col gap-1'>
  <lib-banner mode='danger' title='Danger'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' />
  <lib-banner mode='danger' title='Danger'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    primaryBtnLabel="Ok" secondaryBtnLabel="Annuler" />
  <lib-banner mode='warning' title='Warning'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' />
  <lib-banner mode='warning' title='Warning'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    primaryBtnLabel="Ok" secondaryBtnLabel="Annuler" />
  <lib-banner mode='info' title='Info'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' />
  <lib-banner mode='info' title='Info'
    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    primaryBtnLabel="Ok" secondaryBtnLabel="Annuler" />
</div>
