<form [formGroup]='ratingForm' (mouseleave)='hover.setValue(null)' class='grid grid-cols-5 gap-5 mb-5'>

    <svg
      (click)='changeValue(i+1)' (mouseenter)='rating.enabled && hover.setValue(i+1)'
      *ngFor="let s of [].constructor(5); index as i"
      class='w-[40px] md:w-[60px] lg:w-[70px] aspect-square cursor-pointer' 
      [ngStyle]='{"color": starColor}'
      >
      <use *ngIf="hover?.value >= i+1 || (rating?.value >= i+1 && !hover.value); else unselected" xlink:href='assets/img/rating/selected-star.svg#star'></use>
      <ng-template #unselected>
        <use xlink:href='assets/img/rating/unselected-star.svg#star'></use>
      </ng-template>
    </svg>
  
</form>

