import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { EDomain } from '@types'
import { LucilabCommonModule } from '../../../shared/lucilab-common/lucilab-common.module'

type TDomainConfig = {
  caption: string
}

@Component({
  selector: 'app-objective-create-cta',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, RouterLink],
  templateUrl: './create-cta.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectiveCreateCtaComponent {
  @Input({ required: true }) public domain: EDomain

  public readonly domainConfig: { [key in EDomain]: TDomainConfig } = {
    [EDomain.intellectual]: {
      caption: $localize`:@@objectives.shared.objectif-create-cta.caption.nutrition:Découvrez différentes façons de stimuler votre cerveau`,
    },
    [EDomain.nutrition]: {
      caption: $localize`:@@objectives.shared.objectif-create-cta.caption.intellectual:Découvrez différentes façons de manger mieux`,
    },
    [EDomain.physical]: {
      caption: $localize`:@@objectives.shared.objectif-create-cta.caption.physical:Découvrez différentes façons de bouger plus`,
    },
  }
}
