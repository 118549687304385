<div class='unsubscribe'>
  <a routerLink='/' id='logo'>
    <img src="assets/img/logo/luci.svg" alt="">
  </a>
  <div class='unsubscribe centered'>
    <ng-container *ngIf='error === false else errorMsg'>
      <p i18n>
        Votre adresse email à bien été enlevée de notre liste de notifications.
      </p>
    </ng-container>
    <ng-template #errorMsg>
      <p i18n class='error'>
        Une erreur est survenue lors de la suppression de votre adresse email de notre liste de notifications.
      </p>
    </ng-template>

    <a i18n routerLink='/' class='gobackToHome lu-btn lu-btn-green !text-white'>
      retourner à l'accueil
    </a>

  </div>
</div>
