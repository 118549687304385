import { Component, Input, OnInit } from '@angular/core'
import { ContentfulService } from 'src/app/shared/services/contentful.service'
@Component({
  selector: 'app-image-block',
  templateUrl: './image-block.component.html',
  styles: [
  ],
})
export class ImageBlockComponent implements OnInit {
  @Input() content
  @Input() isLast

  @Input() parentsHistory: Array<any>
  @Input() position

  constructor(
    private contentfulService: ContentfulService,
  ) {}

  ngOnInit(): void {
    if (this.content && this.content['fields'] && this.content['fields'].legend && this.content['fields'].legend?.content?.length) {
      this.content['fields'].legend = this.contentfulService.formatHTML(this.content['fields'].legend)
    }
  }
}
