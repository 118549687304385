<app-dialog-wrapper>
  <div class="flex flex-col max-w-[360px]">
    <img src="./assets/img/quit_icon.svg" alt="" class="w-[50px] mb-2">
    <p class="!mb-4 font-bold text-xl" i18n>Vous vous apprêtez à quitter Luci</p>
    <p class="!mb-4" i18n>Ce lien mène vers un site tiers (externe à Luci). Il s'ouvrira dans un nouvel onglet.</p>
    <mat-checkbox (change)="checked = $event.checked" class="mb-6">
      <span i18n>Ne plus afficher ce message pendant {{snoozeDuration }} jour(s)</span>
    </mat-checkbox>
    <div class="flex flex-row items-center justify-center">
      <button (click)="dialogRef.close()" class='lu-btn lu-btn-border-green mr-2'
        pendoClass='cancel-external-redirection' i18n>Annuler</button>
      <button (click)="dialogRef.close(checked)" class='lu-btn lu-btn-orange ml-2'
        pendoClass='open-external-redirection' i18n>Continuer</button>
    </div>
  </div>
</app-dialog-wrapper>
