import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../Pendo/directives/pendo-button-cta.directive'
import { LocalStorageService } from '../../services/local-storage.service'

const animation = trigger('hideShow', [
  state(
    'show',
    style({
      bottom: '0',
      opacity: 1,
    }),
  ),
  state(
    'hide',
    style({
      bottom: '-400px',
      opacity: 0,
    }),
  ),
  transition('* => *', [animate('1s')]),
])

const STORAGE_KEY = 'cookies-accepted'

@Component({
  selector: 'app-cookies-banner',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, PendoButtonCtaDirective, RouterLink],
  providers: [CookieService],
  templateUrl: './cookies-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [animation],
})
export class CookiesBannerComponent {
  public isAccepted = this.localStorageService.getItem<string>(STORAGE_KEY) === 'accepted'

  constructor(private readonly localStorageService: LocalStorageService) {}

  public acceptCookies(): void {
    this.localStorageService.setItem(STORAGE_KEY, 'accepted')
    this.isAccepted = true
  }
}
