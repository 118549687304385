import { Component, Input } from '@angular/core'
import { ArticleAsset, ExternalArticle } from 'src/app/shared/services/contentful.service'

@Component({
  selector: 'app-library-article-card',
  templateUrl: './library-article-card.component.html',
})
export class LibraryArticleCardComponent{

  @Input() articleId: string
  @Input() set content (contentFulobject: ArticleAsset){

    if(contentFulobject.fields.url){
      this.externalArticle = {
        id: contentFulobject.sys.id,
        title: contentFulobject.fields.title,
        description: contentFulobject.fields.excerpt,
        externalLink: contentFulobject.fields.url,
        imageUrl: contentFulobject.fields.logo.fields.file.url,
      }
    }
  }

  articleUrlContextStart = location.href.includes('/public/') ? '/public' : ''
  externalArticle?: ExternalArticle

  constructor() { }

}


