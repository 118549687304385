export type TMetadata = {
  /** links without host and language */
  links: {
    fr: string
    en: string
  },
  title: string
  description: string
  thumbnailUrl?: string
  keywords?: string
  /** @see https://ogp.me/#types */
  type: string
}

export const nameAttr = [
  'robots',
  'description',
  'twitter:card',
  'twitter:title',
  'twitter:text:title',
  'twitter:description',
  'twitter:image',
] as const
type TNameTag = {
  attr: 'name',
  value: typeof nameAttr[number]
}

export const propertyAttr = [
  'og:title',
  'og:type',
  'og:url',
  'og:image',
  'og:description',
  'og:site_name',
  'keywords',
] as const
type TPropertyTag = {
  attr: 'property',
  value: typeof propertyAttr[number]
}

export type TTag = TNameTag | TPropertyTag

export type TLink = { hreflang: string, href: string, type: 'alternate' | 'canonical' }

export const DEFAULT_THUMBNAIL_URL = 'https://images.ctfassets.net/3swy9p38ookx/5jfW6bYt2TgflkkX1kGEFr/e0d918ce4514703b62fb2e99b0183f02/LucilabFacebook.png'
