import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'
import { environment } from 'environment/environment'
import { AppModule } from './app/app.module'
if (environment.production) {
  enableProdMode()
}

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: [/.luciapp.ca\/api\//gm],
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,
  environment: environment.env,
  release: environment.sentry.release,
  enabled: environment.sentry.enable,
})

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
