import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { RouterLink } from '@angular/router'
import { DialogWrapperComponent } from '../../../../shared/components/dialog-wrapper/dialog-wrapper.component'
import { LucilabCommonModule } from '../../../../shared/lucilab-common/lucilab-common.module'

@Component({
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, DialogWrapperComponent, RouterLink, MatDialogModule],
  templateUrl: './update-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentUpdateDialogComponent {}
