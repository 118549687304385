import { Component, Input } from '@angular/core'
import { ExternalArticle } from 'src/app/shared/services/contentful.service'

@Component({
  selector: 'app-external-article',
  templateUrl: './external-article.component.html',
})
export class ExternalArticleComponent{

  @Input() externalArticle: ExternalArticle

  constructor() { }
}
