import { HttpInterceptor } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AuthService } from '../../auth/services/auth.service'
import { IS_SNACKBAR_SHOWED } from '../../shared/http-contexts/show-snackbar.context'
import { SnackbarErrorMattermostComponent } from '../components/snackbar-error-mattermost/snackbar-error-mattermost.component'
import { SnackbarErrorComponent } from '../components/snackbar-error/snackbar-error.component'
import { SnackbarHTTPErrorComponent } from '../components/snackbar-http-error/snackbar-http-error.component'
@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  mattermostOnError = false

  constructor(
    private snackBar: MatSnackBar,
    private injector: Injector,
    private readonly authService: AuthService,
    private router: Router,
  ) {}

  intercept(request, next) {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.authService.clearStorage()

          return void window.location.reload()
        }

        if (!request.context.get(IS_SNACKBAR_SHOWED)) return throwError(() => error)

        if (request.url.split('?')[0].includes('/v4') && !request.url.includes('attempt=1') && error.status !== 403) {
          this.mattermostOnError = true
          const ref = this.snackBar.openFromComponent(SnackbarErrorMattermostComponent, {
            verticalPosition: 'top',
            panelClass: 'snackbar-error-wrapper',
            duration: 15000,
            data: { error },
          })
          this.mattermostOnError = false
          ref.dismiss()
          // return here to avoid showing the snackbar twice
          // we dont refact for now, obscure part. DANGER
          return throwError(() => error)
        }

        if (error.status === 400 && request.url.includes('/api/rest-auth/user/')) {
          this.snackBar.openFromComponent(SnackbarErrorComponent, {
            verticalPosition: 'top',
            panelClass: 'snackbar-error-wrapper',
            data: error.error,
            duration: 3000,
          })
          // return here to avoid showing the snackbar twice
          // we dont refact for now, obscure part. DANGER
          return throwError(() => error)
        }

        if (!request.url.includes('/api/rest-auth/user/') && !request.url.includes('accounts/')) {
          this.snackBar.openFromComponent(SnackbarErrorComponent, {
            verticalPosition: 'top',
            panelClass: 'snackbar-error-wrapper',
            data: error.error,
            duration: 3000,
          })
          // return here to avoid showing the snackbar twice
          // we dont refact for now, obscure part. DANGER
          return throwError(() => error)
        }

        this.snackBar.openFromComponent(SnackbarHTTPErrorComponent, {
          verticalPosition: 'top',
          panelClass: 'snackbar-error-wrapper',
          duration: 4500,
          data: error.status,
        })

        return throwError(() => error)
      }),
    )
  }
}
