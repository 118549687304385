import { HttpInterceptor, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { getToken } from 'src/app/auth/utils/token-utils'

export class TokenInterceptor implements HttpInterceptor {
  constructor() { }
  cookieService = inject(CookieService)
  router = inject(Router)

  intercept(request: HttpRequest<any>, next) {
    const token = getToken()
    const curUrl = request.url.split('?')[0]
    if (
      !curUrl.includes('/rest-auth/login') &&
      !curUrl.includes('/reset') &&
      !curUrl.includes('/i18n') &&
      !curUrl.includes('/v4') &&
      token
    ) {
      let newHeaders = request.headers.set('Authorization', `Token ${token}`)
      if (this.cookieService.get('nf_lang')) {
        newHeaders = newHeaders.set('luci-lang', this.cookieService.get('nf_lang'))
      }
      request = request.clone({
        headers: newHeaders,
      })
    }
    return next.handle(request)
  }
}
