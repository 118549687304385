import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, map } from 'rxjs'
import { ExternalLinkDialogComponent } from './external-link-dialog.component'

const SNOOZE_DURATION_DAY = 30

@Injectable()
export class LinkService {
  private static readonly WHITELIST_DOMAINS = [
    'lucilab.ca',
    'luciapp.ca',
  ]

  private static readonly DEFAULT_BYPASS_DIALOG_FN = (url: string) =>
    url.startsWith('mailto:') || url.endsWith('.pdf')

  private static Dialog = class {
    public static readonly SNOOZE_DURATION = SNOOZE_DURATION_DAY

    public static isSnoozed(): boolean {
      const lastSnooze = localStorage.getItem('hideExternalLinkDialog')

      try {
        if (!lastSnooze) {
          return false
        }

        const lastSnoozeDate = new Date(+lastSnooze)
        const snoozeUntil = lastSnoozeDate.setDate(lastSnoozeDate.getDate() + this.SNOOZE_DURATION)

        return new Date().getTime() > snoozeUntil
      } catch {
        return false
      }
    }

    public static open(dialog: MatDialog): Observable<boolean> {
      return dialog.open(ExternalLinkDialogComponent, {
        data: this.SNOOZE_DURATION,
      }).afterClosed().pipe(map((res: boolean | undefined) => {
        if (res === undefined) { // dismiss
          return false
        }

        if (res) {
          localStorage.setItem('hideExternalLinkDialog', JSON.stringify(new Date().getTime()))
        } else {
          localStorage.removeItem('hideExternalLinkDialog')
        }

        return true
      }))
    }

  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    protected dialog: MatDialog,
  ) { }

  public open(url: string, bypassDialogFn: (url: string) => boolean = LinkService.DEFAULT_BYPASS_DIALOG_FN): void {
    const isExternalLink = isPlatformBrowser(this.platformId) && !url.includes(location.hostname)
    const isDialogSnoozed = LinkService.Dialog.isSnoozed()
    const isWhiteList = () => {
      const hostname = new URL(url).hostname
      return LinkService.WHITELIST_DOMAINS.find(domain => hostname.includes(domain))
    }

    const openLinkFn = () => window.open(url, '_blank', 'noopener')

    if (!isExternalLink || bypassDialogFn(url) || isDialogSnoozed || isWhiteList()) {
      openLinkFn()
    } else {
      LinkService.Dialog.open(this.dialog).subscribe(res => {
        if (res) {
          openLinkFn()
        }
      })
    }
  }

}
