<div class='bg-green-1100 rounded-2xl py-6 px-4 flex'>
  <div class='mr-4 md:mr-8 max-w-[120px]'>
    <img src='./assets/img/cta/contact-coach.png' class='w-full min-w-[78px] min-h-[70px]' />
  </div>
  <div class='text-green-1000 theme-promo'>
    <p title-1 bold>{{contactCoachCtaInfo.title}}</p>
    <p paragraph-2>{{contactCoachCtaInfo.description}}</p>
    <button secondary rounded class='!mt-4' (click)='openContactCoachDialog()'
      pendoClass="cta-contact-coach">{{contactCoachCtaInfo.buttonLabel}}</button>
  </div>
</div>
