import { AfterViewInit, Directive, ElementRef, Inject, Input } from '@angular/core'
import { getRouterComponentNamePageContext } from '../../helpers/get-router-page-context'
import { BasePendoCta } from '../pendo-cta'
import { PENDO_MODULE_CONTEXT } from '../pendo-injection-token'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]',
  standalone: true,
})
export class PendoRouterLinkCtaDirective extends BasePendoCta implements AfterViewInit {

  @Input() routerLink: string | string[]

  componentContextName: string
  element: ElementRef

  constructor(
    private elementRef: ElementRef,
    @Inject(PENDO_MODULE_CONTEXT) private moduleContext: string,
  ) {
    super(elementRef, 'router-link')
    this.componentContextName = getRouterComponentNamePageContext()
  }

  ngAfterViewInit(): void {
    const link = (typeof this.routerLink === 'string') ? [this.routerLink] : (this.routerLink as string[])
    if(link){
      const content = [this.moduleContext, this.componentContextName, ...link]

      this.applyClass(content)
    }
  }

}
