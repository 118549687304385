/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[paragraph-1]',
})
export class Paragraph1Directive {
  @HostBinding('class') class = 'paragraph-1'
}

@Directive({
  standalone: true,
  selector: '[paragraph-2]',
})
export class Paragraph2Directive {
  @HostBinding('class') class = 'paragraph-2'
}

@Directive({
  standalone: true,
  selector: '[paragraph-3]',
})
export class Paragraph3Directive {
  @HostBinding('class') class = 'paragraph-3'
}

