import { NgModule } from '@angular/core'
import { UikitModule } from '@ui'
import { LinkModule } from '../link/link.module'
@NgModule({
  imports: [
    LinkModule,
    UikitModule,
  ],
  exports: [
    LinkModule,
    UikitModule,
  ],
})
export class LucilabCommonModule { }
