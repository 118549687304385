import { Component, inject, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MarkdownService } from 'ngx-markdown'
import { UserService } from 'src/app/misc/services/user/user.service'

@Component({
  selector: 'app-reference-block',
  templateUrl: './reference-block.component.html',
  styleUrls: ['./reference-block.component.sass'],
})
export class ReferenceBlockComponent implements OnInit {
  @Input() idArticle
  @Input() content
  @Input() index
  @Input() fromCoach

  private router = inject(Router)
  private markD = inject(MarkdownService)
  private userService = inject(UserService)

  constructor() { }

  ngOnInit(): void {
    if (this.content?.fields?.externalResources) {
      this.content?.fields?.externalResources.forEach((element: any) => {
        if (element?.fields?.bibliographicRecord) {
          element.fields.bibliographicRecord = this.markedToHtml(element?.fields?.bibliographicRecord).replace('href=', 'target="_blank" href=')
        }
      })
    }

    if (this.content?.fields?.internalResources?.length) { // format to be like article interface ...
      this.content.fields.internalResources = this.content?.fields?.internalResources.map((r: any) => {
        const inf = {
          ...r,
          already_opened: null,
          article: r?.sys?.id,
          excerpt: r?.fields?.excerpt,
          image: r?.fields?.image?.fields?.file?.url,
          pdf: r?.fields?.pdf?.fields?.file?.url,
          title: r?.fields?.title,
          url: r?.fields?.url,
          bookmarked: null,
          created: null,
        }
        return ({
          ...inf,
          article_data: inf,
          data: inf,
        })
      })
    }
  }

  markedToHtml(string) {
    return this.markD.parse(string)
  }
}
