import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CheckboxSquareLabeledComponent } from './checkbox-square.component'



@NgModule({
  declarations: [
    CheckboxSquareLabeledComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    CheckboxSquareLabeledComponent,
  ],
})
export class CheckboxSquareLabeledModule { }
