/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { UikitModule } from '../../uikit.module'

@Component({
  selector: 'uikit-demo-font',
  templateUrl: './font.component.html',
  standalone: true,
  imports: [CommonModule, UikitModule],
})
export class FontComponent {

}
