import { Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { PendoHrefCtaDirective } from '../../shared/Pendo/directives/pendo-href-cta.directive'
import { LucilabCommonModule } from '../../shared/lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-registered-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [PendoHrefCtaDirective, LucilabCommonModule, RouterLink],
})
export class RegisteredFooterComponent {
  currentYear = new Date().getUTCFullYear()
}
