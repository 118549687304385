import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-warning',
  standalone: true,
  imports: [CommonModule, MatIconModule, LucilabCommonModule],
  templateUrl: './warning.component.html',
})
export class WarningComponent {

  @Input() withIcon = true

  constructor() { }

}
