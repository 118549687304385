import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-facebook-share',
  templateUrl: './facebook-share.component.html',
  styles: [
  ],
})
export class FacebookShareComponent{

  @Input() sharedUrl: string

  constructor() { }
  share(event?: Event){
    event?.stopPropagation()
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.sharedUrl}`, '_blank')
  }
}
