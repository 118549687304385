<div [class.fromCoach]="router.url.includes(' /resources/view/')  || router.url.includes('/coach/')" class='relative'
  [class.block]="ready" [class.hidden]="!ready">
  <!-- desktop bg image -->
  <div *ngIf="currentArticle?.article_data?.image?.fields?.file?.url"
    class='hidden lg:block absolute top-0 left-0 right-0 h-[450px]'>
    <picture>
      <source [srcset]='currentArticle?.article_data?.image?.fields?.file?.url + "?w=1200&fm=avif"' type='image/avif'>
      <source [srcset]='currentArticle?.article_data?.image?.fields?.file?.url + "?w=1200&fm=webp"' type='image/webp'>
      <img [src]='currentArticle?.article_data?.image?.fields?.file?.url +"?w=1200"' alt='article-bg'>
    </picture>
  </div>

  <div class="page-wrapper max-w-[960px] flex flex-col justify-center" id='container-article'>
    <div class='article-wrapper lg:pt-40 overflow-y-auto'>
      <!-- mobile ui -->
      <div *ngIf="ready" class='relative rounded-b-3xl px-3 md:px-7 lg:hidden'>
        <div *ngIf="currentArticle?.article_data?.image?.fields?.file?.url"
          class='absolute top-0 left-0 bottom-0 right-0 lg:hidden'>
          <picture>
            <source [srcset]='currentArticle?.article_data?.image?.fields?.file?.url + "?w=500&fm=avif"'
              type='image/avif'>
            <source [srcset]='currentArticle?.article_data?.image?.fields?.file?.url + "?w=500&fm=webp"'
              type='image/webp'>
            <img [src]='currentArticle?.article_data?.image?.fields?.file?.url +"?w=500"' class='rounded-b-3xl'
              alt='article-bg'>
          </picture>
          <div class='absolute top-0 left-0 bottom-0 right-0 bg-grey-900 opacity-70 rounded-b-3xl'></div>
        </div>
        <div class='relative flex flex-col justify-end'>
          <div>
            <app-breadcrumb *ngIf="ready" [style]="true" [breadcrumbItems]='breadcrumbItems'></app-breadcrumb>
            <h1 class='art-title !text-4xl !text-white'>{{ currentArticle?.article_data?.title }}</h1>
            <p class='text-xl !text-white'>{{ currentArticle?.article_data?.excerpt }}</p>
          </div>

          <ng-container *ngIf="currentArticle.video" [ngTemplateOutlet]="videoTemplate" />

          <div *ngIf="currentArticle" class='flex justify-between w-full mb-2'>
            <a (click)='routerLocation.back()' class='!text-white flex items-center mb-2.5 cursor-pointer'
              pendoClass='navigate-to-previous-page'>
              <mat-icon class='text-white'>keyboard_arrow_left</mat-icon>
              <span i18n>Retour</span>
            </a>
            <div class='flex gap-2'>
              <app-share-methods-container [ngClass]='{"mr-5": currentArticle?.userIsAnon}'
                [emailTitle]='currentArticle?.article_data?.title' [emailMessageContent]='emailMessageContent'
                [urlToShare]='currentUrl'></app-share-methods-container>
              <ng-template *ngIf="!currentArticle?.userIsAnon" [ngTemplateOutlet]="bookmark"></ng-template>
            </div>
          </div>

        </div>
      </div>
      <!-- end of mobile ui -->
      <div class='px-3 lg:px-8 py-8 lg:py-12 lg:bg-white lg:rounded-3xl lg:mb-16'>
        <!-- desktop ui -->
        <div *ngIf="ready" class='hidden lg:block'>
          <div class='flex justify-between'>
            <app-breadcrumb [style]="true" [breadcrumbItems]='breadcrumbItems'></app-breadcrumb>

            <div class='flex gap-4'>
              <app-share-methods-container [emailTitle]='currentArticle?.article_data?.title'
                [emailMessageContent]='emailMessageContent' [urlToShare]='currentUrl'></app-share-methods-container>
              <ng-template *ngIf="!currentArticle?.userIsAnon" [ngTemplateOutlet]="bookmarkDesktop"></ng-template>
            </div>
          </div>
          <h1 class='art-title !text-4xl'>{{ currentArticle?.article_data?.title }}</h1>
          <p class='text-2xl'>{{ currentArticle?.article_data?.excerpt }}</p>
          <ng-container *ngIf="currentArticle.video" [ngTemplateOutlet]="videoTemplate" />
        </div>
        <!-- end of desktop ui -->

        <div *ngIf="currentArticle && (user$ | async) as user" class='flex flex-col gap-8'>
          <div class='flex flex-wrap gap-2'>

            <app-tag *ngFor='let domain of currentArticle?.article_data?.domains'
              [route]='["/categories","search", domain.fields.title]' [title]="domain.fields.title"></app-tag>

            <app-tag *ngFor='let category of currentArticle?.article_data?.categories'
              [route]='["/categories","search", category.fields.title]' [title]="category.fields.title"></app-tag>

            <ng-container *ngFor='let tag of currentArticle?.article_data?.tags'>
              <app-tag [route]='["/themes", tag.path_segment]' [title]="tag.name"></app-tag>
            </ng-container>

          </div>

          <div *ngIf='currentArticle.audio_available' class='lg:w-[464px]'>
            <app-article-audio [articleId]='currentArticle.article_id'></app-article-audio>
          </div>
        </div>


        <ul class='list-none p-0 my-8 lg:w-3/5'>
          <p class='font-bold' i18n>Dans cet article</p>
          <ng-container *ngIf='currentArticle?.article_data?.sections?.length'>
            <li *ngFor="let section of currentArticle?.article_data?.sections; let i = index"
              pendoClass='scroll-to-article-section' (click)='scrollToSection(i)'
              class='font-sans font-medium border-2 border-blue-500 rounded-lg bg-white px-4 py-3 mb-2 flex items-center cursor-pointer hover:opacity-75'>
              <span class='flex justify-center items-center bg-grey-1001 rounded-full w-8 h-8 leading-8 px-3 mr-4'>
                {{ i + 1 }}</span>
              <span [innerHTML]='section?.fields?.title'></span>
            </li>
          </ng-container>
          <ng-container *ngIf='currentArticle?.article_data?.referenceSection?.fields'>
            <li (click)='scrollToSection(currentArticle?.article_data?.sections?.length)'
              pendoClass='scroll-to-article-section'
              class='font-sans font-medium border-2 border-blue-500 rounded-lg bg-white px-4 py-3 mb-2 flex items-center cursor-pointer hover:opacity-75'>
              <span class='flex justify-center items-center bg-grey-1001 rounded-full w-8 h-8 leading-8 px-3 mr-4'>
                {{ currentArticle?.article_data?.sections?.length + 1 }}</span>
              <span [innerHTML]='currentArticle?.article_data?.referenceSection?.fields?.title'></span>
            </li>
          </ng-container>
        </ul>
      </div>
      <section>
        <div class='contents'>
          <div
            *ngFor="let section of currentArticle?.article_data?.sections; let i = index; last as isLast; first as isFirst"
            class='content-block content-wrapper' #i>
            <div class='theme-promo font-bold pb-2 mb-6 flex flex-col'>
              <span class='mr-4 text-6xl'>{{ i + 1 }}</span>
              <h2 class='!pt-4' [innerHTML]='section?.fields?.title'></h2>
            </div>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "content2Columns"' class='font-sans'>
              <app-two-columns [content]='section.fields' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
              </app-two-columns>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "frames"' class='font-sans'>
              <app-frames [content]='section' [position]='i' [isLast]='isLast' [isFirst]="isFirst"
                [parentsHistory]="setPH(i)">
              </app-frames>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentSections"' class='font-sans'>
              <app-section-block [content]='section' [position]='i' [idArticle]='currentArticle?.article_data?.id'
                [parentsHistory]="setPH(i)">
              </app-section-block>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentText"' class='font-sans'>
              <app-text-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
              </app-text-block>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentImages"' class='font-sans'>
              <app-image-block [content]='section' [isLast]='isLast' [position]='i' [parentsHistory]="setPH(i)">
              </app-image-block>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "article_section"' class='font-sans'>
              <app-article-section-block [content]='section' [isLast]='isLast' [position]='i'
                [parentsHistory]="setPH(i)">
              </app-article-section-block>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentContainerImages"' class='font-sans'>
              <app-container-image-block [content]='section' [isLast]='isLast' [position]='i'
                [parentsHistory]="setPH(i)">
              </app-container-image-block>
            </ng-container>
            <ng-container *ngIf='section?.sys?.contentType?.sys?.id === "contentTable"' class='font-sans'>
              <app-table-block [sectionBackgroundColor]='false' [content]='section' [isLast]='isLast' [position]='i'
                [parentsHistory]="setPH(i)">
              </app-table-block>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- Pour en savoir plus et create an account unregistred -->
      <ng-container *ngIf='currentArticle?.userIsAnon'>
        <div class='-mt-12 lg:mt-0'>
          <!--  create an account unregistred-->
          <ng-container *ngIf="currentArticle?.userIsAnon">
            <app-cta-create-account [content]='currentArticle'></app-cta-create-account>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf='currentArticle?.article_data?.referenceSection?.fields'>
        <div #i>
          <app-reference-block [content]='currentArticle?.article_data?.referenceSection'
            [idArticle]='currentArticle?.article_data?.id' [index]='currentArticle?.article_data?.sections?.length'
            [fromCoach]="router.url.includes('/coach/')">
          </app-reference-block>
        </div>
      </ng-container>

      <!-- rating block -->
      <div class='mt-16'
        *ngIf="feedback !== null && (feedback.rating !== undefined) && !router.url.includes('/coach/')">
        <app-rating-article [articleId]="currentArticle?.article_id" [rating]='feedback.rating' [feedBack]="feedback">
        </app-rating-article>
      </div>

      <ng-container *ngIf="currentArticle?.article_data?.updatedAt">
        <ng-container *ngIf="!currentArticle?.article_data?.date_created && !currentArticle?.article_data?.date_edited">
          <p class="px-4 pt-8 lg:px-8 mb-0 font-sans">
            <span i18n>Année de publication: </span>
            {{currentArticle?.article_data?.updatedAt | date:'YYYY'}}
          </p>
        </ng-container>
        <ng-container *ngIf="currentArticle?.article_data?.date_created && !currentArticle?.article_data?.date_edited">
          <p class="px-4 pt-8 lg:px-8 mb-0 font-sans">
            <span i18n>Année de publication: </span>
            {{currentArticle?.article_data?.date_created | date:'YYYY'}}
          </p>
        </ng-container>
        <ng-container *ngIf="currentArticle?.article_data?.date_edited">
          <p class="px-4 pt-8 lg:px-8 mb-0 font-sans">
            <span i18n>Dernière mise à jour: </span>
            {{currentArticle?.article_data?.date_edited | date:'YYYY'}}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf='currentArticle?.article_data?.disclaimer?.fields?.content'>
        <app-disclaimer-block [content]='currentArticle?.article_data?.disclaimer'></app-disclaimer-block>
      </ng-container>
      <!-- desktop btn to top -->
      <div class='hidden lg:block'>
        <button class='lu-btn-round-icon lu-btn-round-icon-green ml-auto mr-4' (click)='scrollToTop()'
          pendoClass='scroll-to-article-top'>
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
      <!-- mobile btn to top -->
      <button
        class='fixed invisible lg:hidden bottom-4 right-2 lu-btn lu-btn-white shadow-[8px_8px_13px_rgba(0,0,0,.2)] flex items-center ease-out z-50'
        (click)='scrollToTop()' #btnBackToTop pendoClass='scroll-to-article-top'>
        <span class="material-icons mr-2">arrow_upward</span>
        <span class='text-lg font-bold' i18n>HAUT DE PAGE</span>
      </button>
    </div>
  </div>
</div>
<ng-template #bookmark>
  <mat-icon *ngIf="currentArticle?.bookmarked else notBookmarked" class='text-white cursor-pointer'
    (click)='changeBookmark(currentArticle)' pendoClass='bookmark-article'>
    bookmark</mat-icon>
  <ng-template #notBookmarked>
    <mat-icon class='text-white cursor-pointer' (click)='changeBookmark(currentArticle)'
      pendoClass='bookmark-article'>bookmark_border</mat-icon>
  </ng-template>
</ng-template>

<ng-template #bookmarkDesktop>
  <mat-icon *ngIf="currentArticle?.bookmarked else notBookmarked" class='cursor-pointer scale-[1.5]'
    (click)='changeBookmark(currentArticle)' pendoClass='bookmark-article'>bookmark</mat-icon>
  <ng-template #notBookmarked>
    <mat-icon class='cursor-pointer scale-[1.5]' (click)='changeBookmark(currentArticle)'
      pendoClass='bookmark-article'>bookmark_border</mat-icon>
  </ng-template>
</ng-template>
<!-- </ng-container> -->

<!-- <ng-template #loading> -->
<div class='w-full h-full flex justify-center items-center' [class.block]="!ready" [class.hidden]="ready">
  <mat-spinner color="primary" diameter="60"></mat-spinner>
</div>
<!-- </ng-template>-->

<ng-template #videoTemplate>
  <div class='theme-promo my-6 md:my-8 lg:my-10'>
    <p title-2 bold class='!text-white !mb-6 md:!mb-8 lg:!mb-10 lg:!text-blue-500'>{{currentArticle.video.title}}
    </p>
    <app-video-player
      [video]="{ src: currentArticle.video.video.video_url, poster: currentArticle.video.video.thumbnail_url }"
      [rounded]="true" />
  </div>
</ng-template>
