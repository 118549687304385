<a (click)='onClick()' [pendoClass]='"article-" + article.data.short_id'
  class='theme-promo cursor-pointer !no-underline flex flex-col'>
  <ng-container [ngSwitch]='cardType'>
    <ng-container *ngSwitchCase='"vertical"' [ngTemplateOutlet]='vertical' />
    <ng-container *ngSwitchCase='"list"' [ngTemplateOutlet]='list' />
    <ng-container *ngSwitchCase='"giant"' [ngTemplateOutlet]='giant' />
  </ng-container>
</a>

<ng-template #vertical>
  <div class='shadow-[4px_4px_10px_0px_rgba(0,0,0,0.12)] rounded-2xl overflow-hidden grid grid-rows-[auto_1fr] h-[260px] desktop:h-[280px]
    w-full min-w-[136px] max-w-[376px] desktop:min-w-[228px] desktop:max-w-[336px]'>
    <div class='h-[110px] w-full'>
      <ng-container [ngTemplateOutlet]='imageTemplate' />
    </div>
    <div class='bg-white p-2 desktop:p-3 flex flex-col justify-between'>
      <div>
        <p label-3 class="!mb-1 !text-grey-1000"> <ng-container *ngTemplateOutlet="labelType" /> </p>
        <p class="line-clamp-4 title-4 desktop:title-3" [ngClass]='{"bold": !handleVisited || !article.already_opened}'>
          {{article.data.title}}
        </p>
      </div>
      <div>
        <ng-container [ngTemplateOutlet]="pdfAndBookMarkTemplate" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #list>
  <div class="container-list">
    <div
      class='bg-white wrapper shadow-[4px_4px_10px_0px_rgba(0,0,0,0.12)] rounded-2xl grid grid-cols-[auto_1fr] overflow-hidden h-[138px]'>
      <div class='thumbnail w-[169px] min-h-fit'>
        <ng-container [ngTemplateOutlet]='imageTemplate' />
      </div>
      <div class='p-3 flex flex-col justify-between' [ngClass]='{"border rounded-r-2xl": withBorder}'>
        <div class='flex flex-col gap-1'>
          <span class="title line-clamp-1 title-4 desktop:title-3"
            [ngClass]='{"bold": !handleVisited || !article.already_opened}'>
            {{article.data.title}}
          </span>
          <span caption-4 class="description line-clamp-2">{{article.data.excerpt }}</span>
        </div>
        <ng-container [ngTemplateOutlet]="pdfAndBookMarkTemplate" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #giant>
  <!-- Mobile -->
  <div class='bg-white shadow-[4px_4px_10px_0px_rgba(0,0,0,0.12)] rounded-2xl overflow-hidden tablet:hidden grid grid-rows-[auto_1fr]
    h-[248px] w-full min-w-[136px]'>
    <div class='h-[110px] w-full'>
      <ng-container [ngTemplateOutlet]='imageTemplate' />
    </div>
    <div class='p-2 flex flex-col justify-between h-full'>
      <div>
        <p label-3 class="!mb-1 !text-grey-1000"> <ng-container *ngTemplateOutlet="labelType" /> </p>
        <p class="line-clamp-3 title-3" [ngClass]='{"bold": !handleVisited || !article.already_opened}'>
          {{article.data.title}}
        </p>
      </div>
      <div>
        <ng-container [ngTemplateOutlet]="pdfAndBookMarkTemplate" />
      </div>
    </div>
  </div>

  <!-- Tablet + Desktop -->
  <div
    class='shadow-[4px_4px_10px_0px_rgba(0,0,0,0.12)] rounded-2xl hidden tablet:grid grid-cols-[auto_1fr] overflow-hidden h-[205px] desktop:h-[337px]'>
    <div class='w-[250px] desktop:w-[356px] min-h-fit'>
      <ng-container [ngTemplateOutlet]='imageTemplate' />
    </div>
    <div class='bg-white p-3 flex flex-col justify-between' [ngClass]='{"border rounded-r-2xl": withBorder}'>
      <div class='flex flex-col gap-1.5'>
        <p label-3 class="!text-grey-1000"> <ng-container *ngTemplateOutlet="labelType" /> </p>
        <p class="line-clamp-2 title-2 desktop:line-clamp-3 desktop:title-1"
          [ngClass]='{"bold": !handleVisited || !article.already_opened}'>
          {{article.data.title}}
        </p>
        <p class="line-clamp-3 paragraph-2 desktop:paragraph-2">{{article.data.excerpt }}</p>
      </div>
      <ng-container [ngTemplateOutlet]="pdfAndBookMarkTemplate" />
    </div>
  </div>
</ng-template>

<ng-template #imageTemplate>
  <div class='relative w-full h-full'>
    <div *ngIf='article.data.video'
      class='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[1]
        min-w-[3.625rem] min-h-[3.625rem] rounded-full bg-black bg-opacity-50 flex flex-col justify-center items-center'>
      <img src='/assets/img/play.svg' class='w-6 ml-1' />
    </div>
    <img [class.opacity-70]='handleVisited && article.already_opened' [src]='resizedImage'
      class='w-full h-full object-cover article-image'>
  </div>
</ng-template>

<ng-template #pdfAndBookMarkTemplate>
  <div class='flex justify-between'>
    <div class="flex items-center gap-1">
      <ng-container *ngIf="article.data.pdf">
        <mat-icon fontIcon="picture_as_pdf" />
        <span class='font-sans text-sm mt-[2px] sm:!text-blue-500' style='color: #2f5057 !important'
          i18n>imprimable</span>
      </ng-container>
    </div>

    <mat-icon pendoClass="bookmark-article" (click)='toggleBookmark($event, article)' *ngIf='!userIsAnonymous'
      [fontIcon]="article.bookmarked ? 'bookmarked' : 'bookmark_outline'" />
  </div>
</ng-template>

<ng-template #labelType>
  <ng-container *ngIf="article.data.video; else hasNoVideo"> <span i18n>Vidéo</span> </ng-container>
  <ng-template #hasNoVideo>Article</ng-template>
</ng-template>
