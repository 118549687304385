import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { BreadcrumbItem } from '../breadcrumb/breadcrumb.component'

@Component({
  selector: 'app-new-breadcrumb',
  templateUrl: './new-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewBreadcrumbComponent {

  @Input() breadcrumbItems: BreadcrumbItem[]

  constructor(private router: Router) { }

  libRootUrl = this.router.url.substring(0, this.router.url.indexOf('/categories')) + '/categories'


}
