
<div class="px-3">
  <div class='bg-white px-5 md:px-[7.5rem] py-10 rounded-[46px] max-w-[60rem] mx-auto w-full'>
    <ng-container *ngIf='icon'>
      <div class='flex justify-center mb-8'>
        <img [src]='icon.path' [alt]='icon?.alt' class='w-[70px] h-auto'>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>