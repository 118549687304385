import { HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'
import { IS_SNACKBAR_SHOWED } from '../../shared/http-contexts/show-snackbar.context'
import { SnackbarSuccessComponent } from '../components/snackbar-success/snackbar-success.component'


@Injectable()
export class SuccessInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, private router: Router) { }
  already = false
  counselor = false

  intercept(request: HttpRequest<any>, next) {
    if (
      (
        (request.url.includes('/rest-auth/user/') && request.method === 'PATCH') ||
        this.router.url.includes('/counselor-introduction?show=true')
      ) && this.already === false
      && request.context.get(IS_SNACKBAR_SHOWED) === true
    ) {
      if (this.router.url.includes('/counselor-introduction')) {
        this.counselor = true
      }
      this.already = true
      return next.handle(request).pipe(
        tap(data => {
          if (data['status'] === 200 && !this.router.url.includes('rencontre') && !this.router.url.includes('auth')) {
            this.snackBar.openFromComponent(SnackbarSuccessComponent, {
              verticalPosition: 'top',
              panelClass: 'snackbar-success-wrapper',
              duration: 3500,
              data: this.counselor,
            })
          }
        }),
      )
    }
    return next.handle(request)
  }
}
