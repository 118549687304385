import { Component, Inject, Optional } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
  selector: 'app-snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['./snackbar-success.component.sass'],
})

export class SnackbarSuccessComponent {
  constructor(@Optional() @Inject(MAT_SNACK_BAR_DATA) public data: { customMessage?: string }) { }
}
