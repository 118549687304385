import { FormGroup } from '@angular/forms'
import { QuestionControl, QuestionError } from '../interfaces/multiple-question-form'


/**
 * It will add an error in the form if there is an error
 * with an id that match in the FormGroup
 *
 * @param form
 * @param error
 */
export const addErrorsInQuestionsForm = (form: FormGroup<{
  [x: string]: FormGroup<QuestionControl>
}>, errors: QuestionError[]) => {

  for (const error of errors) {

    if (!error.valid) {

      const formKeys = Object.keys(form.controls)

      for (const formKey of formKeys) {

        if (form.controls[formKey].controls.id.value === error.id) {
          form.controls[formKey].setErrors({ [error.id]: error.answer_data[0] })

          if (form.controls[formKey].controls.other?.enabled) {
            form.controls[formKey].controls.other?.setErrors({ [error.id]: error.answer_data[0] })
          } else {
            form.controls[formKey].controls.value?.setErrors({ [error.id]: error.answer_data[0] })
          }
        }
      }
    }
  }
}
