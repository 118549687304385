<footer class='theme-promo bg-blue-600 p-6 flex justify-center items-center flex-wrap gap-3 !text-white'>
  <span label-3 i18n='@@footer.connected.copyright' class='!text-white text-center w-full desktop:w-auto'>&copy;
    {{currentYear}} - Lucilab. Tous droits réservés.</span>
  <span class='hidden desktop:inline-block'>|</span>
  <a label-3 routerLink='/a-propos' class='!text-white !no-underline whitespace-nowrap' i18n='@@footer.about'>À
    propos</a>
  <span>|</span>
  <a label-3 routerLink='/avis-juridiques' class='!text-white !no-underline whitespace-nowrap'
    i18n='@@footer.legal-notice'>Avis juridiques</a>
</footer>
