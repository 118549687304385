<div class='' [class.mobile-only]='mobile' [class.twoLettersOnly]='twoLettersOnly'>
  <mat-form-field class='w-40 language-switch-form' [class.!w-full]='twoLettersOnly' appearance="outline">
    <mat-icon matPrefix class='!overflow-visible !p-0'>language</mat-icon>
    <mat-select [(value)]='locale' class='rounded' (selectionChange)='languageSwitched.emit($event.value)'>
      <mat-option disabled>
        <mat-icon>language</mat-icon>
      </mat-option>
      <mat-option *ngFor='let l of languages$ | async | keyvalue' [value]='l.key'>
        {{twoLettersOnly ? (l.value | slice:0:2) : l.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
