import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
})
export class IconRegistryModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('objMedal', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/medal.svg'))
    iconRegistry.addSvgIcon('heart', sanitizer.bypassSecurityTrustResourceUrl('assets/img/heart.svg'))
    iconRegistry.addSvgIcon('heartFull', sanitizer.bypassSecurityTrustResourceUrl('assets/img/heart-full.svg'))
    iconRegistry.addSvgIcon('apple', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/action_plan/fruits.svg'))
    iconRegistry.addSvgIcon('shoe', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/action_plan/chaussure.svg'))
    iconRegistry.addSvgIcon('horse-chess', sanitizer.bypassSecurityTrustResourceUrl('/assets/imgaction_plan/jeux.svg'))
    iconRegistry.addSvgIcon('exclamation-rounded', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/exclamation_rounded.svg'))
    iconRegistry.addSvgIcon('happy-smile', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/Happy_smile.svg'))
    iconRegistry.addSvgIcon('lib', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/lib.svg'))
  }
}
