import { CommonModule } from '@angular/common'
import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import Plyr from 'plyr'
import { Observable, finalize } from 'rxjs'
import { PendoButtonCtaDirective } from '../../../../shared/Pendo/directives/pendo-button-cta.directive'
import { LucilabCommonModule } from '../../../../shared/lucilab-common/lucilab-common.module'
import { ArticleAudioService } from './audio.service'

@Component({
  selector: 'app-article-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.sass'],
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule, PendoButtonCtaDirective, LucilabCommonModule],
  standalone: true,
})

export class ArticleAudioComponent {

  @Input()
  public readonly articleId: string

  public link$: Observable<string>


  @ViewChild('audioPlayer')
  private readonly audioPlayerRef: ElementRef<HTMLAudioElement>

  private readonly articleAudioService = inject(ArticleAudioService)

  private plyrPlayer: Plyr

  get showPlayer() {
    return !!this.link$
  }
  set showPlayer(_val: boolean) {
    this.link$ = this.articleAudioService.getLink(this.articleId).pipe(finalize(() => {
      // setTimeout allows the event loop to complete and render the HTML element, making it accessible through the nativeElement property.
      setTimeout(() => {
        this.plyrPlayer = new Plyr(this.audioPlayerRef.nativeElement, {
          i18n: {
            speed: $localize`Vitesse`,
            quality: $localize`Qualité`,
            normal: $localize`Normale`,
          },
        })
        this.plyrPlayer.play()
      })
    }))
  }

}
