import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, LOCALE_ID, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { Asset } from 'contentful'
import { delay, iif, of, tap } from 'rxjs'
import { LibraryContentService } from 'src/app/article-library/services/library-content.service'
import { UserService } from '../../../../misc/services/user/user.service'
import { resizeContentfulImage } from '../../../helpers/resize-contentful-image'
import { Article } from '../../../interfaces/articles'
import { LinkService } from '../../../link/link.service'
import { LucilabCommonModule } from '../../../lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../../Pendo/directives/pendo-button-cta.directive'
import { PendoHrefCtaDirective } from '../../../Pendo/directives/pendo-href-cta.directive'
import { ReplacePipe } from '../../../pipes/replace.pipe'
import { ArticleService } from '../../../services/article.service'

type cardType = 'vertical' | 'list' | 'giant'

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./article-card.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    PendoHrefCtaDirective,
    PendoButtonCtaDirective,
    ReplacePipe,
    RouterLink,
    MatIconModule,
    LucilabCommonModule,
  ],
})
export class ArticleCardComponent implements OnInit {
  userIsAnonymous = inject(UserService).user.anonymous
  private readonly locale = inject(LOCALE_ID)

  @Input() article: Article
  @Input() withBorder: boolean = false
  @Input() cardType: cardType = 'vertical'
  @Input() handleVisited: boolean = true

  resizedImage: string | Asset

  get imgSrc() {
    return this.article.data.image.fields?.file?.url ?? this.article.data.image
  }

  constructor(
    private readonly libServ: LibraryContentService,
    private readonly articleService: ArticleService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly linkService: LinkService,
  ) {}

  ngOnInit(): void {
    const imageSizes = (): { width: number; height: number } => {
      const imageSizes: { [Property in cardType]: { width: number; height: number } } = {
        giant: { width: 350, height: 350 },
        list: { width: 300, height: 300 },
        vertical: { width: 300, height: 300 },
      }
      return imageSizes[this.cardType]
    }

    const imageSize = imageSizes()
    const imageSource = this.imgSrc
    this.resizedImage =
      typeof imageSource === 'string'
        ? resizeContentfulImage(imageSource, imageSize.width, imageSize.height)
        : imageSource
  }

  toggleBookmark(e: any, article: Article) {
    e.preventDefault()
    e.stopPropagation()
    this.libServ.updateBookmark({ article_id: article.article_id, bookmarked: article.bookmarked }).subscribe()
    article.bookmarked = !article.bookmarked
  }

  onClick() {
    return iif(
      () => this.article.already_opened,
      of(null),
      this.articleService
        .updateResourcesInteraction(this.article.article_id, { type: 'open', viewed_completely: true })
        .pipe(
          tap(() => {
            this.article.already_opened = true
            this.changeDetectorRef.detectChanges()
          }),
          // On Safari iOS, window.open does not work properly when we call articleService.updateResourcesInteraction()
          // This issue is likely caused by a bug related to RXJS and the iif function, and the JavaScriptCore event loop (Javascript Apple's engine).
          delay(0),
        ),
    ).subscribe(() => {
      if (this.article.data.pdf ?? this.article.data.url) {
        return this.linkService.open(this.article.data.pdf ?? this.article.data.url)
      }
      return this.router.navigate(['/articles', this.article.data.path_segments[this.locale]])
    })
  }
}
