import { Component, inject, OnInit } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'


type SnackbarError = string | object | any[]


@Component({
  selector: 'app-snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['./snackbar-error.component.sass'],
})
export class SnackbarErrorComponent implements OnInit {
  private data: SnackbarError = inject(MAT_SNACK_BAR_DATA)

  errors: string[] = []

  ngOnInit(): void {
    this.extractErrors(this.data)
  }

  extractErrors(data: SnackbarError) {
    if (typeof data === 'string') {
      this.errors.push(data)
    }
    else if (Array.isArray(data)) {
      data.forEach(value => this.extractErrors(value))
    }
    else if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        this.extractErrors(data[key])
      })
    }
  }

}
