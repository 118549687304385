/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  standalone: true,
  selector: 'button',
  templateUrl: './button.component.html',
  styleUrls: [
    './button.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

}
