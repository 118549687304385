<ng-container *ngIf="breadcrumb$ | async as bread">
  <div class="mb-4 lg:mb-14 flex items-center flex-wrap" [class.mt-5]="!articleTitle && withTopMargin">
    <ng-container *ngIf="!breadcrumbItems?.length; else customCrumbPath">
      <ng-container *ngFor="let b of bread; let last = last; let index = index">
        <a *ngIf="b?.title" [routerLink]="!last ? b?.url : null"
          class="flex items-center text-white lg:text-blue-500 mb-1 md:mb-4" [class.lg:text-green-500]="style">
          <span
            class="inline-block hover:cursor-pointer py-1 border-white md:border-blue-500 rounded-xl lg:mr-1 mb-1 font-sans font-medium"
            [ngClass]="{
              'hover:cursor-not-allowed !border-grey-1004 md:!border-grey-1002': last,
              'underline': style,
              'border px-5': !style
            }">
            {{ b?.title }}
          </span>
          <span *ngIf="!last && bread[index + 1]?.title"
            class="md:inline-block material-icons flex justify-center items-center w-[20px] md:w-auto"
            [class.text-3xl]="!style">chevron_right</span>
        </a>
      </ng-container>
    </ng-container>

    <ng-template #customCrumbPath>

      <a [routerLink]="bread[0].url" class="flex items-center text-white lg:text-blue-500 mb-1 md:mb-4"
        [class.lg:text-green-500]="style">
        <span
          class="inline-block hover:cursor-pointer py-1 border-white md:border-blue-500 rounded-xl lg:mr-1 mb-1 font-sans font-medium"
          [ngClass]="{
            'underline': style,
            'border px-5': !style
          }">
          {{ bread[0].title }}
        </span>
        <span class="md:inline-block material-icons flex justify-center items-center w-[20px] md:w-auto"
          [class.text-3xl]="!style">chevron_right</span>
      </a>

      <a *ngFor="let crumbItem of breadcrumbItems; let last = last" [routerLink]="crumbItem.url"
        class="flex items-center text-white lg:text-blue-500 mb-1 md:mb-4" [class.lg:text-green-500]="style">
        <span
          class="inline-block hover:cursor-pointer py-1 border-white md:border-blue-500 rounded-xl lg:mr-1 mb-1 font-sans font-medium"
          [ngClass]="{
            '!border-grey-1004 md:!border-grey-1002': last,
            'underline': style,
            'border px-5': !style
          }">
          {{ crumbItem.title }}
        </span>
        <span *ngIf="!last" class="md:inline-block material-icons flex justify-center items-center w-[20px] md:w-auto"
          [class.text-3xl]="!style">chevron_right</span>
      </a>
    </ng-template>

  </div>
</ng-container>
