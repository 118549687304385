import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { getToken } from 'src/app/auth/utils/token-utils'
import { UserService } from 'src/app/misc/services/user/user.service'

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.sass'],
})
export class MaintenanceComponent {

  constructor(private userService: UserService, private router: Router) { }

  refreshed = false

  refresh() {
    if (!getToken()) {
      this.router.navigate(['/'])
    }
    this.userService.getUser().subscribe((user: any) => {
      if (user.is_coach) {
        this.router.navigate(['/coach'])
      } else {
        this.router.navigate(['/dashboard'])
      }
    })
  }
}
