import Quill from 'quill'

const link = Quill.import('formats/link')


export class QuillLinkHelper extends link {
  static create(value) {
    const node = super.create(value)
    if (!value.startsWith('http')) {
      value = '//' + value
    }
    node.setAttribute('href', value)
    return node
  }
}
