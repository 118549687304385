import { Component, Input } from '@angular/core'
import { ActivatedRoute, Params, Router, convertToParamMap } from '@angular/router'
import { Observable, combineLatest, filter, map } from 'rxjs'
import { routeParamsName } from '../../../library-main-layout/route-params'
import { LibraryContentService } from '../../services/library-content.service'
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  @Input() articleTitle
  @Input() style
  @Input() withTopMargin = true
  @Input() breadcrumbItems: BreadcrumbItem[] = []

  breadcrumbVersion = this.route.snapshot.root.firstChild.data.breadcrumbVersion ?? 1

  breadcrumb$: Observable<{ title: string, url: string }[]> = combineLatest([this.route.params, this.libraryContentService.crumbs$.pipe(filter(crumbs => !!crumbs))]).pipe(
    map(([params, crumbs]) => {
      let breadcrumb = [{ title: $localize`Bibliothèque`, url: '/categories' }]

      if (params?.term) {
        const term = decodeURI(params.term)
        breadcrumb = [...breadcrumb, { title: $localize`Résultat de recherche:` + ' ' + `${term}`, url: `/categories/search/${term}` }]
        return breadcrumb
      } else {

        switch (this.breadcrumbVersion) {
          case 1:
            return this.parseCrumbVersion1(breadcrumb, params, crumbs)

          case 2:
            return this.parseCrumbVersion2(breadcrumb, params, crumbs)
        }
      }
      return []
    }),
  )

  constructor(
    private libraryContentService: LibraryContentService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  private parseCrumbVersion1(breadcrumb: { title: string, url: string }[], params: Params, crumbs: { [id: string]: string }) {
    let rebuildUrl = '/categories/'
    let tmp = Object.entries(params)

    if (tmp?.length > 0) {
      tmp.forEach((element: Array<any>) => {
        if (crumbs[element[1]]) { // categories and subcats
          if (this.router.url.includes('list')) {
            breadcrumb = [...breadcrumb, { title: crumbs[element[1]], url: `${rebuildUrl}list/${element[1]}/` }]
          }
          if (this.router.url.includes('category')) {
            breadcrumb = [...breadcrumb, { title: crumbs[element[1]], url: `${rebuildUrl}${element[1]}/` }]
          }
        } else { // article
          breadcrumb = [...breadcrumb, { title: this.articleTitle, url: this.router.url }]
        }
      })
      return breadcrumb
    }
    return breadcrumb
  }

  private parseCrumbVersion2(breadcrumb: { title: string, url: string }[], params: Params, crumbs: { [id: string]: string }) {

    const paramsMap = convertToParamMap(params)

    const computeCrumb = (id: string) => {
      const param = paramsMap.get(id)

      if (!param) {
        return undefined
      }

      return { title: crumbs[param], url: `/categories/${param}` }
    }

    const crumbCategory = computeCrumb(routeParamsName.categoryId)
    const crumbSubCategory = computeCrumb(routeParamsName.subCategoryId)

    return [...breadcrumb, crumbCategory, crumbSubCategory].filter(crumb => !!crumb)
  }

}



export interface BreadcrumbItem {
  title: string
  url: string
}
