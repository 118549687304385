import { Pipe, PipeTransform } from '@angular/core'
import { LifeHabitsService } from '../service'

@Pipe({
  name: 'getSubmissionById',
  standalone: true,
})
export class GetSubmissionByIdPipe implements PipeTransform {
  constructor(private readonly lifeHabitsService: LifeHabitsService) {}

  transform(id: number, userId: number) {
    return this.lifeHabitsService.getFormSubmission(id, { user: userId })
  }
}
