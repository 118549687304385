import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environment/environment'
import { BehaviorSubject, map, Observable, of, shareReplay, Subject, switchMap } from 'rxjs'
import { ActionPlanService } from 'src/app/dashboard/services/action-plan/action-plan.service'
import { camelToSnake } from 'src/app/shared/helpers/camel-to-snake'
import { Article, AssignedArticleToArticle } from 'src/app/shared/interfaces/articles'
import { ILibraryGroup } from '../../shared/interfaces/library-group'
import { Tag } from '../../shared/interfaces/tag'
import { AssignedArticleResponse, MappedAssignedArticleResponse } from './response-type'
@Injectable({
  providedIn: 'root',
})
export class LibraryContentService {

  private _prevTags: BehaviorSubject<any> = new BehaviorSubject({})
  prevTags$ = this._prevTags.asObservable()
  private _prevPage: BehaviorSubject<any> = new BehaviorSubject({})
  prevPage$ = this._prevPage.asObservable()
  private _articleIsOPen: Subject<any> = new Subject()
  articleIsOPen$ = this._articleIsOPen.asObservable()

  readonly baseTags$ = this.http.get<Tag[]>(`${environment.apiUrl}articles/library_base_tags/`).pipe(shareReplay())
  readonly baseGroups$ = this.http.get<Array<ILibraryGroup & { articles: Article[] }>>(`${environment.apiUrl}articles/library_groups/`).pipe(shareReplay())
  readonly crumbs$ = this.http.get<any>(`${environment.apiUrl}articles/library_groups_titles/`).pipe(shareReplay())

  constructor(
    private http: HttpClient,
    private planServ: ActionPlanService,
  ) { }

  setPrevPage(value) {
    this._prevPage.next(value)
  }
  setPrevTags(value) {
    this._prevTags.next(value)
  }
  setAticleIsOpen(val) {
    this._articleIsOPen.next(val)
  }

  getArticleGroupFromBackEnd(groupId) {
    return this.http.get<ILibraryGroup>(`${environment.apiUrl}articles/library_groups/?library_group_id=${groupId}`)
  }

  getArticleInfoFromBackEnd(id: string) {
    return this.http.get(`${environment.apiUrl}articles/${id}/`)
  }

  getAllArticleByCatIDFromBackEnd(url: string, id?: string, pageSize?: number) {
    const idAsked = id ? `?library_group=${id}` : ''
    const page_size = idAsked !== '' && pageSize ? `&page_size=${pageSize}` : idAsked === '' && pageSize ? `?page_size=${pageSize}` : ''
    const urlAsked = url ?? `${environment.apiUrl}articles/${idAsked}${page_size}`

    return this.http.get(urlAsked)
  }

  getMoultesArticles(ids) {
    return this.http.get(`${environment.apiUrl}articles/`, { params: { article_id: ids } })
  }

  getArticles(params, url?) {
    return this.http.get(url ? url : `${environment.apiUrl}articles/`, { params })
  }

  getArticlesByTag(id) {
    return this.http.get(`${environment.apiUrl}articles/?tags=${id}`)
  }


  getAssignedArticles(url?: string, params?): Observable<MappedAssignedArticleResponse> {
    const urlAsked = url ?? `${environment.apiUrl}resources/`
    return this.http.get(urlAsked, { params }).pipe(
      shareReplay(),
      map((response: AssignedArticleResponse) => {
        response.results = response.results.sort((a: any, b: any) => {
          return new Date(b?.created).getTime() - new Date(a?.created).getTime()
        })
        return {
          ...response,
          results: response.results.map(assArticle => AssignedArticleToArticle(assArticle)),
        }
      }),
    )
  }


  getRecomArticles(orderByDateAsc = false): Observable<any> {
    return this.http.get(`${environment.apiUrl}resources/`).pipe(
      switchMap((res: any) => {
        //  if no articles assigned by coach, get articles based on participant active domain
        if (res.count) {
          return of({ ...res, recom: true, results: res.results.map(a => (AssignedArticleToArticle(a))) })
        } else {
          return this.planServ.getActionPlanChoices().pipe(
            map(({ domain }: any) => {
              return domain.map(({ suggested_article_id }) => suggested_article_id)
            },
            ),
            switchMap(ids => this.getMoultesArticles(ids)),
            map(res => ({ ...res, recom: false })),
          )
        }
      }),
      map((rec: any) => {
        if (orderByDateAsc) {
          rec.results = rec?.results.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
        }
        return rec
      }),
    )
  }

  getSavedArticles() {
    return this.http.get(`${environment.apiUrl}articles/`, { params: { bookmark: true } })
  }

  getRecentReadArticles() {
    return this.http.get(`${environment.apiUrl}articles/?recent=true`)
  }

  getArticlePreview(id: string) {
    return this.http.get(`${environment.apiUrl}articles/preview/?article_id=${id}`)
  }

  postSearchSuggestion(term: string) {
    return this.http.post<{ results: string[] }>(`${environment.apiUrl}articles/search_suggestions/`, { search: term })
      .pipe(
        map(response => {
          return response.results
        }),
      )
  }

  sendArticleFeedback(articleId: string, articleRawFeedback: any) {

    const transformedValue = this.transformRawFeedbackToFeedback(articleRawFeedback)

    return this.http.post(`${environment.apiUrl}articles/${articleId}/feedback/`, transformedValue)

  }

  private transformRawFeedbackToFeedback(values: any) {

    let newFeedback: { rating: number, comment: string, choices: string[] } = {
      rating: values.rating,
      comment: values.comment ? values.comment : '',
      choices: [],
    }

    for (const key in values) {
      if (key !== 'rating' && key !== 'comment' && values[key]) {
        newFeedback = { ...newFeedback, choices: [...newFeedback.choices, camelToSnake(key)] }
      }
    }

    return newFeedback
  }

  updateBookmark({ article_id, bookmarked }): Observable<void> {
    return bookmarked ?
      this.http.delete<void>(`${environment.apiUrl}articles/${article_id}/bookmark/`) :
      this.http.post<void>(`${environment.apiUrl}articles/${article_id}/bookmark/`, {})
  }

}
