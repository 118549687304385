import { HttpClient, HttpContext, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environment/environment'
import { MarkdownService } from 'ngx-markdown'
import { Observable, map } from 'rxjs'
import { ContentfulForm, ContentfulFormAnswers } from 'src/app/shared/interfaces/contentful'
import { IS_SNACKBAR_SHOWED } from '../../shared/http-contexts/show-snackbar.context'
import { HttpCacheClient } from '../../shared/services/http-cache-client.service'

@Injectable({
  providedIn: 'root',
})
export class GenericFormService {

  constructor(private http: HttpClient, private markdownService: MarkdownService, private readonly httpCacheClient: HttpCacheClient) {
  }

  getFormByInternalId(internalId: string): Observable<ContentfulForm[]> {
    return this.httpCacheClient.get<ContentfulFormsResponse>(`${environment.apiUrl}forms/`, {
      params: new HttpParams().append('internal_id', internalId),
    }, 1500).pipe(map(res => res.results))
  }

  getCogStateFormFromAssignation() {
    return this.httpCacheClient.get(`${environment.apiUrl}forms/assignments/`)
  }

  postCloseCogStateForm(status?) {
    return this.http.post(`${environment.apiUrl}formanswers/`, { form_id: 'cogstate', status })
  }

  getForm(formId, questionId = ''): Observable<ContentfulFormAnswers> {
    const queryQuestionId = questionId !== '' ? `&question_id=${questionId}` : ''
    return this.http.get<ContentfulFormAnswers>(`${environment.apiUrl}formanswers/?form_id=${formId}${queryQuestionId}`)
  }

  replyQuestion(formId, answer, showInterceptorErrorSnackBar = true): Observable<ContentfulFormAnswers> {
    return this.http.post<ContentfulFormAnswers>(`${environment.apiUrl}formanswers/?form_id=${formId}`, answer, {
      context: new HttpContext().set(IS_SNACKBAR_SHOWED, showInterceptorErrorSnackBar),
    })
  }

  markedToHtml(string) {
    return this.markdownService.parse(string)
  }
}

interface ContentfulFormsResponse {
  next: any
  previous: any
  results: ContentfulForm[]
}

