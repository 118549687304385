import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-two-columns',
  templateUrl: './two-columns.component.html',
  styles: [
  ],
})
export class TwoColumnsComponent implements OnInit {
  @Input() content
  @Input() sectionBackgroundColor
  currentEntriesLeftColumn
  currentEntriesRightColumn
  @Input() isLast
  tmpArr = []

  @Input() parentsHistory: Array<any> | null
  @Input() position

  constructor() { }

  ngOnInit(): void {
    if ( this.content && this.content.leftColumn && this.content.leftColumn.length ) {
      this.content.leftColumn = this.content.leftColumn.map((ele: any, index: number) => ({...ele, order: index, from: 'left'}) )
    }
    if ( this.content && this.content.rightColumn && this.content.rightColumn.length ) {
      this.content.rightColumn = this.content.rightColumn.map((ele: any, index: number) => ({...ele, order: index, from: 'right'}) )
    }

    const smallestArray = this.content?.leftColumn?.length < this.content?.rightColumn?.length ? this.content?.leftColumn : this.content?.rightColumn
    const largestArray = this.content?.leftColumn?.length > this.content?.rightColumn?.length ? this.content?.leftColumn : this.content?.rightColumn

    for(let i = 0 ; i < smallestArray.length ; i++) {
      if (this.content.leftColumn) {
        this.tmpArr.push(this.content.leftColumn[i])
      }
      if (this.content.rightColumn) {
        this.tmpArr.push(this.content.rightColumn[i])
      }
    }
    this.tmpArr = [... this.tmpArr, ...largestArray.slice(smallestArray.length)]

    this.tmpArr.map((e: any, index: number) => {
      if (this.sectionBackgroundColor === '#5E7D6C') {
        if (index % 3 === 0){
          e['backgroundColor'] = 'bg2'
        }
        if (index % 3 === 1){
          e['backgroundColor'] = 'bg3'
        }
        if (index % 3 === 2){
          e['backgroundColor'] = 'bg4'
        }
      } else {
        if (index % 4 === 0){
          e['backgroundColor'] = 'bg1'
        }
        if (index % 4 === 1){
          e['backgroundColor'] = 'bg2'
        }
        if (index % 4 === 2){
          e['backgroundColor'] = 'bg3'
        }
        if (index % 4 === 3){
          e['backgroundColor'] = 'bg4'
        }
      }

      return e
    })

    if ( this.content && this.content.leftColumn && this.content.leftColumn.length ) {
      this.currentEntriesLeftColumn =  this.tmpArr.filter((e: any) => e?.from === 'left').sort((a, b) => a.order - b.order)
    }
    if ( this.content && this.content.rightColumn && this.content.rightColumn.length ) {
      this.currentEntriesRightColumn =  this.tmpArr.filter((e: any) => e?.from === 'right').sort((a, b) => a.order - b.order)
    }
  }

  setPH(i: number): Array<any> {
    return [...this.parentsHistory, {rang: this.parentsHistory.length, position: i, type: 'two-cols'}]
  }
}
