import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, ReplaySubject, shareReplay } from 'rxjs'

interface IRequestCache<T> {
  createdAt: Date
  result: Observable<T>
  ttl: number
}

@Injectable({
  providedIn: 'root',
})
export class HttpCacheClient {

  private readonly _cache: Map<string, IRequestCache<any>> = new Map()

  constructor(private readonly httpClient: HttpClient) { }

  public get<T>(url: string, options?: {
    headers?: HttpHeaders
    params?: HttpParams
    responseType?: 'json'
  }, ttl: number = 5000): Observable<T> {
    return this.execute(new HttpRequest('GET', url, options), ttl)
  }

  public execute<T>(httpRequest: HttpRequest<unknown>, ttl: number = 5000, cacheKey: string = httpRequest.urlWithParams): Observable<T> {

    // only GET requests are in cache
    if (httpRequest.method !== 'GET') {
      return this.send(httpRequest)
    }

    const cached: IRequestCache<T> = this._cache.get(cacheKey)
    if (cached && !this.isExpired(cached)) {
      return cached.result
    }

    const result = new ReplaySubject<T>(1)
    this.send(httpRequest).pipe(shareReplay(1)).subscribe(result)

    this._cache.set(cacheKey, { createdAt: new Date(), result, ttl })

    return result
  }

  private send<T>({ method, url, ...options }: HttpRequest<unknown>): Observable<T> {
    return this.httpClient.request<T>(method, url, {
      ...options,
      responseType: 'json',
    })
  }

  private isExpired({ createdAt, ttl }: IRequestCache<unknown>): boolean {
    return ((new Date()).getTime() - createdAt.getTime()) > ttl
  }
}
