<div class='container-table' [class.isLastContent]='isLast'>
  <ng-container *ngIf='content?.fields?.title || content?.fields?.titleRichText'>
    <div class='font-bold mb-8 tableTitle' [innerHTML]='content?.fields?.titleRichText'></div>
  </ng-container>
  <div class='table w-full table-fixed'>
    <div class='table-header md:flex hidden'
      *ngIf='content?.fields?.firstColumnHeader || content?.fields?.secondColumnHeader || content?.fields?.firstColumnHeaderRichText || content?.fields?.secondColumnHeaderRichText'>
      <div class='pl-8 w-1/3 mb-2 !text-blue-500 ' [innerHTML]='content?.fields?.firstColumnHeaderRichText'></div>
      <div class='pl-9 w-2/3 mb-2 !text-blue-500 ' [innerHTML]='content?.fields?.secondColumnHeaderRichText'></div>
    </div>
    <div class='table-body'>
      <ng-container *ngIf='content?.fields?.listOfRows && content?.fields?.listOfRows?.length'>
        <div *ngFor="let cont of content?.fields?.listOfRows; index as i"
          [class.with-border]='sectionBackgroundColor === 2'
          class='row mb-4 flex !bg-grey-1001 rounded-3xl flex-col md:flex-row row-item'>
          <ng-container *ngIf='cont?.fields?.leftColumn'>
            <div class='cell cell-left imp overall font-medium pt-8 pb-5 px-6 md:px-7 md:w-1/3 w-full break-words'
              [innerHTML]='cont?.fields?.leftColumn'></div>
          </ng-container>
          <ng-container *ngIf='cont?.fields?.rightColumn'>
            <div
              class='cell cell-right font-medium pt-8 pb-5 px-6 md:px-7 sp-34 md:w-2/3 w-full text-blue-500 break-words'
              [innerHTML]='cont?.fields?.rightColumn'></div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf='content?.fields?.legend'>
    <div [innerHTML]='content?.fields?.legend' class='text-center text-base font-sans break-words'></div>
  </ng-container>
</div>
