/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { UikitModule } from '../../uikit.module'

@Component({
  selector: 'uikit-demo-button',
  templateUrl: './button.component.html',
  standalone: true,
  imports: [CommonModule, UikitModule],
})
export class ButtonComponent {

  shape = 'rounded'
  icon = 'arrow-left'
  inverted = false
  background = 'white'
  color = ''
}
