<app-dialog-wrapper>
  <div class='theme-dashboard px-1'>
    <p class='header-3 text-center' i18n='@@life-habits.components.assessment-update-dialog.title'>Quand mettre à jour
      le bilan?</p>
    <p paragraph-2 class='!mt-6' i18n='@@life-habits.components.assessment-update-dialog.sentence'>
      Nous vous recommandons de mettre à jour votre bilan régulièrement, notamment si :</p>
    <ul paragraph-2 class='list-disc !pl-10 !mt-2'>
      <li i18n='@@life-habits.components.assessment-update-dialog.error'>Vous avez détecté des erreurs,</li>
      <li i18n='@@life-habits.components.assessment-update-dialog.reflection'>
        Il ne reflète plus votre situation actuelle,
      </li>
      <li i18n='@@life-habits.components.assessment-update-dialog.last-update'>
        Plusieurs mois se sont écoulés depuis votre dernière mise à jour.
      </li>
    </ul>

    <button primary rounded medium icon="arrow-right" iconPosition="right" class='mt-6 mx-auto' [mat-dialog-close]
      i18n='@@life-habits.components.assessment-update-dialog.update' routerLink="/habitudes-de-vie">
      Mettre à jour
    </button>
  </div>

</app-dialog-wrapper>
