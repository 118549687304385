/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core'


@Directive({
  standalone: true,
  selector: '[underline]',
})
export class UnderlineDirective {
  @HostBinding('class') class = 'underline'
}

