<div class='flex flex-col' class="input-component"
  [ngClass]="{'input-component--number': templateInputType === 'number', 'input-component--row': templateInputType === 'number' && !helpText }">

  <div class='flex flex-col gap-1 input-label-input-element'>
    <div class='flex items-end'>
      <div class='flex flex-col gap-1'>
        <div class='flex gap-1'>
          <label label-2 bold *ngIf="label" [for]='formControlName'>{{label}}</label>
          <span label-2 bold class='required-text' *ngIf="required">*</span>
        </div>
        <span caption-4 *ngIf="helpText" class='assistance-text'>{{helpText}}</span>
      </div>
      <button label-3 *ngIf="type === 'password'" class='!ml-auto border-none !text-green-500 underline'
        (click)='togglePasswordVisibility()'>{{passwordLabel}}</button>
    </div>

    <input caption-3 [type]='templateInputType' class='input-element' [name]="formControlName" [id]='formControlName'
      [ngClass]="{'input-element--error': hasError, 'input-element--disabled': disabled || control!.disabled}"
      [(ngModel)]="value" [disabled]="disabled || control!.disabled" [placeholder]="placeholder" [min]='min' [max]='max'
      (blur)='onTouched()'>
  </div>

  <ng-container *ngIf="hasError">
    <ng-content select="lib-validation-indicator"></ng-content>
  </ng-container>

</div>
