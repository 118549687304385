<lib-accordion (isExpanded)="isExpanded = $event">
  <div ngProjectAs='header' class='flex justify-between items-center'>
    <h3>header</h3>
    <img src='./assets/img/arrow-bottom.svg' [ngStyle]="isExpanded && {'transform': 'rotate(180deg)'}">
  </div>
  <div ngProjectAs='content'> content </div>
</lib-accordion>

<lib-accordion (isExpanded)="isSecondExpanded = $event">
  <div ngProjectAs='header' class='flex justify-between items-center'>
    <h3>header #2</h3>
    <img src='./assets/img/arrow-bottom.svg' [ngStyle]="isSecondExpanded && {'transform': 'rotate(180deg)'}">
  </div>
  <div ngProjectAs='content'> content #2 </div>
</lib-accordion>
