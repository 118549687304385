import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit, Self } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { IInputColors } from '../checkbox-square/checkbox-square-colors'

@Component({
  selector: 'app-radio-input-container',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule],
  templateUrl: './radio-input-container.component.html',
})
export class RadioInputContainerComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() colors: IInputColors = {
    border: 'white',
    background: 'white',
    selectedBorder: '#E68B30',
    selectedBackground: '#FFF5EB',
    textColor: '',
    selectedTextColor: '',
  }
  @Input() value: T
  formValue: T
  valuechangesSubs = Subscription.EMPTY
  onChange = (value: T) => { }
  onTouched = () => { }
  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this
  }

  ngOnInit() {
    this.valuechangesSubs = this.ngControl.control.valueChanges.subscribe(value => {
      // Check to ensure the value wasn't already set (Template driven forms)
      if (this.formValue === value) return
      this.writeValue(value)
    })
  }

  ngOnDestroy(): void {
    this.valuechangesSubs.unsubscribe()
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  writeValue(value: T) {
    this.formValue = value
  }

  //This gets called on click of the element
  select() {
    this.formValue = this.formValue === this.value ? null : this.value
    this.onChange(this.formValue)
  }
}
