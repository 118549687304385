<form class="w-full mb-3 trending-search-form Pendo_trending-search"
  [ngClass]='{"border-[1px] border-blue-500 border-solid rounded-md" : !borderless}' (submit)="onSubmit()">
  <mat-form-field class="w-full trending-serch-form" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input #input type="text" matInput [formControl]="search" [matAutocomplete]="auto">
    <mat-icon *ngIf='search.value.length' class='cursor-pointer' pendoClass='clear-trending-search' (click)='clearSearchedValue(input)'>close</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [autoActiveFirstOption]='false'
      (optionActivated)='onActivatedOption($event)' (optionSelected)="onSubmit()">
      <div *ngIf='(optionsStream$ | async)?.resultType === "default"'
        class='flex items-center gap-6 mt-4 m-2 mx-4 text-sm'>
        <mat-icon>trending_up</mat-icon>
        <ng-container i18n>Recherches fréquentes</ng-container>
      </div>
      <mat-option *ngFor="let option of (optionsStream$ | async)?.results" [value]="option">
        <mat-icon>search</mat-icon>
        <span class='font-sans'>{{option}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
