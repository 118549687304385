import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-container-image-block',
  templateUrl: './container-image-block.component.html',
  styleUrls: ['./container-image-block.component.sass'],
})
export class ContainerImageBlockComponent {
  @Input() content
  @Input() isLast

  @Input() parentsHistory: Array<any> | null
  @Input() position

  constructor() { }

  setPH(i: number): Array<any> {
    return [...this.parentsHistory, {rang: this.parentsHistory.length, position: i, type: 'container-image-block'}]
  }
}
