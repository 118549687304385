import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ConvertDateTimeToTimezoneModule } from 'src/app/shared/pipes/convert-date-time-to-timezone/convert-date-time-to-timezone.module'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-timezone-view',
  standalone: true,
  imports: [MatIconModule, ConvertDateTimeToTimezoneModule, CommonModule, LucilabCommonModule],
  templateUrl: './timezone-view.component.html',
  styleUrls: [
    './timezone-view.component.sass',
  ],
})
export class TimezoneViewComponent {

  @Input() selectedTimezone: string

  @Input() time

  currentTime = new Date().toUTCString()

  constructor() { }




}
