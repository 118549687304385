import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { MatDialog } from '@angular/material/dialog'
import { LucilabCommonModule } from '../../../shared/lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../../shared/Pendo/directives/pendo-button-cta.directive'
import { AssessmentUpdateDialogComponent } from './update-dialog/update-dialog.component'
@Component({
  selector: 'app-assessment-update-cta',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, PendoButtonCtaDirective, AssessmentUpdateDialogComponent],
  templateUrl: './assessment-update-cta.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentUpdateCtaComponent {
  @Input({ required: true }) latestAssessmentDate: Date

  constructor(private readonly matDialog: MatDialog) {}

  public openDialog() {
    this.matDialog.open(AssessmentUpdateDialogComponent)
  }
}
