<ng-container *ngIf='content'>
  <div class='w-full flex items-center justify-center px-4 lg:w-[850px] mx-auto mb-18 mt-0 lg:px-0'>
    <div class='w-full grid grid-cols-1 gap-0  lg:grid-cols-2   lg:w-full'>
      <div
        class='bg-white bg-white/50 backdrop-blur-xl  -mt-20 order-2 w-11/12   rounded-3xl z-10 h-[348px] lg:order-1  lg:w-[530px] lg:h-[320px] lg:px-10 lg:py-14 lg:mt-0'>
        <div class="max-w-[90%] my-16 mx-auto lg:max-w-none lg:my-0 lg:mx-0">
          <h2 class='!font-bold !text-3xl !leading-9'>{{content?.title}}</h2>
          <p class='text-2xl leading-7'>{{content?.subtitle}}</p>
          <a [routerLink]='content?.buttonLink'
            class='inline-block mt-4 lu-btn lu-btn-orange pendo_cta_create_account_unregistred_button_article'>{{content?.buttonLabel}}</a>
        </div>
      </div>
      <div class='relative ml-6 order-1 w-11/12 lg:z-0 lg:w-[450px] h-[320px] rounded-3xl lg:order-2 lg:mt-8 lg:ml-0'>
        <picture>
          <source [srcset]='content?.image?.fields?.file?.url + "?w=950&fm=avif"' type='image/avif'>
          <source [srcset]='content?.image?.fields?.file?.url + "?w=950&fm=webp"' type='image/webp'>
          <img [src]='content?.image?.fields?.file?.url +"?w=950"' class='w-full h-full rounded-3xl
          top-0 left-0 right-0 bottom-0 absolute object-cover'>
        </picture>
      </div>
    </div>
  </div>
</ng-container>
