import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { PendoRouterLinkCtaDirective } from '../../Pendo/directives/pendo-routerlink-cta.directive'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [CommonModule, RouterModule, PendoRouterLinkCtaDirective, LucilabCommonModule],
  templateUrl: './tag.component.html',
})
export class TagComponent {

  @Input() route: string | string[]
  @Input() title: string

  constructor() { }

}
