import { CommonModule } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { EMPTY, Subscription, catchError, finalize, switchMap } from 'rxjs'
import { GenericFormService } from 'src/app/generic-form/services/generic-form.service'
import { ContentfulForm, ContentfulFormAnswers, ContentfulQuestionData } from 'src/app/shared/interfaces/contentful'
import { DocumentTohtmlPipe } from 'src/app/shared/pipes/document-to-html.pipe'
import { DocumentTohtmlText } from 'src/app/shared/pipes/document-to-text.pipe'
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component'
import { SnackbarErrorComponent } from '../misc/components/snackbar-error/snackbar-error.component'
import { addErrorsInQuestionsForm } from '../shared/helpers/multiple-questions-forms-error-handler'
import { NoWhitespaceValidator } from '../shared/helpers/validators/white-space.validator'
import { QuestionControl } from '../shared/interfaces/multiple-question-form'


export const USER_EXTRA_INFO_INTERNAL_ID = 'user_extra_info'

@Component({
  standalone: true,
  templateUrl: './user-extra-info.component.html',
  styles: [
    `.overlay:before {
          content: '';
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          background-color: rgba(255,255,255,0.5);
        }
        .mat-form-field {
          width: 100%
        }
        `,
  ],
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MainLayoutComponent, DocumentTohtmlPipe, DocumentTohtmlText, MatProgressSpinnerModule],
})
export class UserExtraInfoComponent implements OnInit, OnDestroy {
  formId = ''
  loading = false

  contentfulForm: ContentfulFormAnswers | null = null
  titleQuestion: ContentfulQuestionData
  ageQuestion: ContentfulQuestionData
  genderQuestion: ContentfulQuestionData
  customerAcquisitionQuestion: ContentfulQuestionData

  form = this.fb.nonNullable.group<{ [key: string]: FormGroup<QuestionControl<any, any>> }>({
    age: this.fb.group<QuestionControl<string>>({
      id: new FormControl(''),
      value: new FormControl('', Validators.required),
    }),
    gender: this.fb.group<QuestionControl<string, string>>({
      id: new FormControl(''),
      value: new FormControl('', Validators.required),
      other: new FormControl({ value: '', disabled: true }, [Validators.required, NoWhitespaceValidator]),
    }),
    customerAcquisition: this.fb.group<QuestionControl<string, string>>({
      id: new FormControl(''),
      value: new FormControl('', Validators.required),
      other: new FormControl({ value: '', disabled: true }, [Validators.required, NoWhitespaceValidator]),
    }),
  })

  private subscriptions = new Subscription()


  constructor(
    private genericFormService: GenericFormService,
    private fb: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
  ) { }

  get f() { return this.form.controls }

  ngOnInit(): void {
    this.genericFormService.getFormByInternalId(USER_EXTRA_INFO_INTERNAL_ID)
      .pipe(
        switchMap((forms: ContentfulForm[]) => {
          this.formId = forms[0].form_id
          return this.genericFormService.getForm(forms[0].form_id)
        }))
      .subscribe((contentfulForm: ContentfulFormAnswers) => {
        this.contentfulForm = contentfulForm
        this.titleQuestion = contentfulForm.question_data[0]
        this.ageQuestion = contentfulForm.question_data[1]
        this.genderQuestion = contentfulForm.question_data[2]
        this.customerAcquisitionQuestion = contentfulForm.question_data[3]
        this.initForm(contentfulForm)
      })

    this.subscriptions.add(this.form.controls.gender.controls.value.valueChanges.subscribe((value) => {
      const selectedOption = this.genderQuestion.allowed_answers.find((answer) => answer.label === value)
      if (selectedOption?.require_user_input) {
        this.form.controls.gender.controls.other.enable()
      } else {
        this.form.controls.gender.controls.other.reset('')
        this.form.controls.gender.controls.other.disable()
      }
    }))

    this.subscriptions.add(this.form.controls.customerAcquisition.controls.value.valueChanges.subscribe((value) => {
      const selectedOption = this.customerAcquisitionQuestion.allowed_answers.find((answer) => answer.label === value)
      if (selectedOption?.require_user_input) {
        this.form.controls.customerAcquisition.controls.other.enable()
      } else {
        this.form.controls.customerAcquisition.controls.other.reset('')
        this.form.controls.customerAcquisition.controls.other.disable()
      }
    }))
  }

  private initForm(contentfulForm: ContentfulFormAnswers) {
    this.form.controls.age.controls.id.setValue(contentfulForm.question_data[1].id)
    this.form.controls.gender.controls.id.setValue(contentfulForm.question_data[2].id)
    this.form.controls.customerAcquisition.controls.id.setValue(contentfulForm.question_data[3].id)

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true
      const answers =
        [
          // Message question must be sent back as empty
          {
            question_id: this.titleQuestion.id,
            form: this.formId,
            answer_data: {},
          },
          ...Object.keys(this.form.controls).map(control => {
            const id = this.form.get(control).get('id').value
            const value = this.form.get(control).get('value')?.value
            const other = this.form.get(control).get('other')?.value?.trim()
            return {
              question_id: id,
              form: this.formId,
              answer_data: {
                choices: [value],
                values: other ? { [value]: other } : {},
              },
            }
          }),
        ]

      this.genericFormService.replyQuestion(this.formId, answers, false)
        .pipe(
          catchError((error: HttpErrorResponse) => {

            if (error.status !== 400) {
              this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
                verticalPosition: 'top',
                panelClass: 'snackbar-error-wrapper',
                data: error.error,
                duration: 3000,
              })
            } else {
              addErrorsInQuestionsForm(this.form, error.error)
            }

            return EMPTY
          }),
          finalize(() => this.loading = false),
        )
        .subscribe(res => {
          this.router.navigate(['/guided-tour'])
        })
    }
  }

}

