import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { emailValidator, Form, passwordValidators } from '@utils'
import { of } from 'rxjs'
import { RadioWithTextComponent } from '../../../public-api'
import { CheckboxComponent } from '../../inputs/checkbox/checkbox.component'
import { InputFieldComponent } from '../../inputs/input/input.component'
import { DefaultRadioComponent } from '../../inputs/radio/default/default-radio.component'
import { UikitModule } from '../../uikit.module'
import { ValidationIndicatorComponent } from '../../validation-indicator/validation-indicator.component'

@Component({
  selector: 'lib-uikit-demo-input',
  templateUrl: './input.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldComponent,
    ValidationIndicatorComponent,
    UikitModule,
    CheckboxComponent,
    DefaultRadioComponent,
    RadioWithTextComponent,
  ],
})
export class DemoInputComponent {
  form = new Form((values) => of(values), {
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required, emailValidator]),
    password: new FormControl('', passwordValidators),
    disabled: new FormControl({ disabled: true, value: '' }),
    age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
    number: new FormControl('', [Validators.required, Validators.min(22), Validators.max(222)]),
    checkbox: new FormControl(false),
    radio: new FormControl('', [Validators.required]),
  })
}
