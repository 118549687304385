import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-email-share',
  templateUrl: './email-share.component.html',
  styles: [
  ],
})
export class EmailShareComponent implements OnInit{

  @Input() body: string

  @Input() subject: string

  emailContent = ''

  constructor() { }

  ngOnInit(){
    this.emailContent =
      (this.subject ? (
        `subject=${decodeURI(this.subject)}` + ( this.body ? `&body=${this.body}` : '')
        )
      : '' )
  }

}
