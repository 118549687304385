
export const removeQueryParam = (route: string, queryParamName: string): string => {
  const newRoute = route.split('?').map(segment => {
    return segment.split('&').filter(param => {
      return param.split('=')[0] !== queryParamName
    }).join('&')
  })

  return newRoute[1]?.length > 1 ? newRoute.join('?') : newRoute.join('')
}
