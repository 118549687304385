import { NgModule } from '@angular/core'
import { ConvertDateTimeToTimezonePipe } from './convert-date-time-to-timezone.pipe'



@NgModule({
  declarations: [
    ConvertDateTimeToTimezonePipe,
  ],
  exports: [
    ConvertDateTimeToTimezonePipe,
  ],
  
})
export class ConvertDateTimeToTimezoneModule { }
