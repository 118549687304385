import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatAutocompleteActivatedEvent } from '@angular/material/autocomplete'
import { Router } from '@angular/router'
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs'
import { LibraryContentService } from 'src/app/article-library/services/library-content.service'
import { ConfigService } from 'src/app/misc/config/config.service'
import { SearchOption } from '../../interfaces/search-option'

@Component({
  selector: 'app-trending-search',
  templateUrl: './trending-search.component.html',
  styleUrls: [
    'trending-search.component.sass',
  ],
})
export class TrendingSearchComponent {

  @Input() borderless = true

  @Input() triggerRedirection = true

  @Output() optionSelectEvent = new EventEmitter<string>()

  search = new UntypedFormControl('')

  private readonly defaultOptions$ = this.configService.config$.pipe(map(({ trending_searches }) => ({ results: trending_searches, resultType: 'default' })))

  optionsStream$ = this.search.valueChanges.pipe(
    startWith(''),
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap(value => {
      if (value === '') {
        return this.defaultOptions$
      } else {
        return this.libraryContentService.postSearchSuggestion(value).pipe(
          map(response => {
            return {
              results: response,
              resultType: 'searched',
            } as SearchOption
          }),
        )
      }
    }),
    shareReplay(),
  )

  constructor(
    private configService: ConfigService,
    private libraryContentService: LibraryContentService,
    private router: Router,
  ) { }

  onSubmit() {
    this.optionSelectEvent.emit(this.search.value)
    this.goSearch()
  }

  goSearch() {
    if (this.triggerRedirection && this.search.value !== '') {
      this.router.navigate([`${this.router.url.split('categories')[0]}categories/search/${this.search.value}`])
    }
  }

  onActivatedOption(option: MatAutocompleteActivatedEvent) {
    if (option && option.option) {
      this.search.setValue(option.option.value, {
        emitEvent: false,
      })
    }
  }

  clearSearchedValue(textInput) {
    textInput.focus()
    this.search.setValue('')
    this.optionSelectEvent.emit(this.search.value)
  }
}
