<div id='fonts' class='flex flex-col'>
  <h2 class='my-8'>Fonts</h2>
  <div class='flex flex-col gap-16'>

    <div class='flex flex-col gap-2 divide-y-2'>
      <h3>Headers</h3>
      <div class='flex flex-row divide-x-2'>
        <div class='flex flex-col px-4'>
          <span title-1>h1</span>
          <h1>Pour un cerveau en santé</h1>
        </div>

        <div class='flex flex-col px-4'>
          <span title-1>h2</span>
          <h2>Pour un cerveau en santé</h2>
        </div>

        <div class='flex flex-col px-4'>
          <span title-1>h3</span>
          <h3>Pour un cerveau en santé</h3>
        </div>
      </div>
    </div>

    <div class='flex flex-col gap-2 divide-y-2'>
      <h3>Paragraphs</h3>
      <div class='flex flex-row divide-x-2'>
        <div class='flex flex-col px-4'>
          <span title-1>Paragraph-1</span>
          <span paragraph-1>Et si le mode de vie pouvait contribuer à préserver notre énergie, notre vitalité d’esprit
            et notre bonheur au fil du temps? Découvrez dans chacun des épisodes comment les petits gestes quotidiens
            sont intimement liés à la santé physique, mentale et cognitive.</span>
        </div>

        <div class='flex flex-col px-4'>
          <span title-1>Paragraph-2</span>
          <span paragraph-2>Et si le mode de vie pouvait contribuer à préserver notre énergie, notre vitalité d’esprit
            et notre bonheur au fil du temps? Découvrez dans chacun des épisodes comment les petits gestes quotidiens
            sont intimement liés à la santé physique, mentale et cognitive.</span>
        </div>

        <div class='flex flex-col px-4'>
          <span title-1>Paragraph-3</span>
          <span paragraph-3>Et si le mode de vie pouvait contribuer à préserver notre énergie, notre vitalité d’esprit
            et notre bonheur au fil du temps? Découvrez dans chacun des épisodes comment les petits gestes quotidiens
            sont intimement liés à la santé physique, mentale et cognitive.</span>
        </div>
      </div>
    </div>

    <div class='flex flex-col w-fit gap-2 divide-y-2'>
      <h3>Captions</h3>
      <div class='flex flex-row divide-x-2'>
        <div class='flex flex-col w-fit px-4'>
          <span title-1>Caption-1</span>
          <span caption-1>Normal</span>
          <span caption-1 semi-bold>Semi-bold</span>
          <span caption-1 bold>Bold</span>
          <span caption-1 underline>Underline</span>
          <span caption-1 semi-bold underline>Semi-bold + Underline</span>
          <span caption-1 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Caption-2</span>
          <span caption-2>Normal</span>
          <span caption-2 semi-bold>Semi-bold</span>
          <span caption-2 bold>Bold</span>
          <span caption-2 underline>Underline</span>
          <span caption-2 semi-bold underline>Semi-bold + Underline</span>
          <span caption-2 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Caption-3</span>
          <span caption-3>Normal</span>
          <span caption-3 semi-bold>Semi-bold</span>
          <span caption-3 bold>Bold</span>
          <span caption-3 underline>Underline</span>
          <span caption-3 semi-bold underline>Semi-bold + Underline</span>
          <span caption-3 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Caption-4</span>
          <span caption-4>Normal</span>
          <span caption-4 semi-bold>Semi-bold</span>
          <span caption-4 bold>Bold</span>
          <span caption-4 underline>Underline</span>
          <span caption-4 semi-bold underline>Semi-bold + Underline</span>
          <span caption-4 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Caption-5</span>
          <span caption-5>Normal</span>
          <span caption-5 semi-bold>Semi-bold</span>
          <span caption-5 bold>Bold</span>
          <span caption-5 underline>Underline</span>
          <span caption-5 semi-bold underline>Semi-bold + Underline</span>
          <span caption-5 bold underline>Bold + Underline</span>
        </div>
      </div>
    </div>

    <div class='flex flex-col w-fit gap-2 divide-y-2'>
      <h3>Titles</h3>
      <div class='flex flex-row divide-x-2'>
        <div class='flex flex-col w-fit px-4'>
          <span title-1>Title-1</span>
          <span title-1>Normal</span>
          <span title-1 semi-bold>Semi-bold</span>
          <span title-1 bold>Bold</span>
          <span title-1 underline>Underline</span>
          <span title-1 semi-bold underline>Semi-bold + Underline</span>
          <span title-1 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Title-2</span>
          <span title-2>Normal</span>
          <span title-2 semi-bold>Semi-bold</span>
          <span title-2 bold>Bold</span>
          <span title-2 underline>Underline</span>
          <span title-2 semi-bold underline>Semi-bold + Underline</span>
          <span title-2 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Title-3</span>
          <span title-3>Normal</span>
          <span title-3 semi-bold>Semi-bold</span>
          <span title-3 bold>Bold</span>
          <span title-3 underline>Underline</span>
          <span title-3 semi-bold underline>Semi-bold + Underline</span>
          <span title-3 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Title-4</span>
          <span title-4>Normal</span>
          <span title-4 semi-bold>Semi-bold</span>
          <span title-4 bold>Bold</span>
          <span title-4 underline>Underline</span>
          <span title-4 semi-bold underline>Semi-bold + Underline</span>
          <span title-4 bold underline>Bold + Underline</span>
        </div>
      </div>
    </div>

    <div class='flex flex-col w-fit gap-4 divide-y-2'>
      <h3>Labels</h3>
      <div class='flex flex-row divide-x-2'>
        <div class='flex flex-col w-fit px-4'>
          <span title-1>Label-1</span>
          <span label-1>Normal</span>
          <span label-1 semi-bold>Semi-bold</span>
          <span label-1 bold>Bold</span>
          <span label-1 underline>Underline</span>
          <span label-1 semi-bold underline>Semi-bold + Underline</span>
          <span label-1 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Label-2</span>
          <span label-2>Normal</span>
          <span label-2 semi-bold>Semi-bold</span>
          <span label-2 bold>Bold</span>
          <span label-2 underline>Underline</span>
          <span label-2 semi-bold underline>Semi-bold + Underline</span>
          <span label-2 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Label-3</span>
          <span label-3>Normal</span>
          <span label-3 semi-bold>Semi-bold</span>
          <span label-3 bold>Bold</span>
          <span label-3 underline>Underline</span>
          <span label-3 semi-bold underline>Semi-bold + Underline</span>
          <span label-3 bold underline>Bold + Underline</span>
        </div>

        <div class='flex flex-col w-fit px-4'>
          <span title-1>Label-4</span>
          <span label-4>Normal</span>
          <span label-4 semi-bold>Semi-bold</span>
          <span label-4 bold>Bold</span>
          <span label-4 underline>Underline</span>
          <span label-4 semi-bold underline>Semi-bold + Underline</span>
          <span label-4 bold underline>Bold + Underline</span>
        </div>
      </div>
    </div>

  </div>

</div>
