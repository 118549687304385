import { MediaMatcher } from '@angular/cdk/layout'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators'
import { UserService } from 'src/app/misc/services/user/user.service'
import { TCategory } from 'src/app/shared/interfaces/category'
import { ILibraryGroup } from 'src/app/shared/interfaces/library-group'
import { LibraryContentService } from '../../services/library-content.service'

@Component({
  selector: 'app-library-submenu',
  templateUrl: './library-submenu.component.html',
})
export class LibrarySubmenuComponent implements OnInit, OnDestroy {
  @Input() baseCategories
  @Input() category: ILibraryGroup
  @Input() baseTags
  @Input() parentTags
  @Input() tagRelativePath: boolean = false
  @Input() hideCats: boolean = false

  user$ = this.userService.user$
  libBaseTags$ = this.libraryContentService.baseTags$.pipe(filter(tags => !!tags), shareReplay())
  unsubscribe$: Subject<boolean> = new Subject<boolean>()

  sideMenuCateTags: TCategory[]
  keywordTags
  hideTags: boolean

  cateId = this.activatedRoute.snapshot.paramMap.get('catId') || this.activatedRoute.snapshot.paramMap.get('slugCategoryId')?.split('-')[0]
  subCateId = this.activatedRoute.snapshot.paramMap.get('subId') || this.activatedRoute.snapshot.paramMap.get('subSlugCategoryId')?.split('-')[0]
  tagId = this.activatedRoute.snapshot.queryParamMap.get('id')

  prevPage
  isDesktop = this.media.matchMedia('(min-width: 1024px)').matches
  mainPathSegment: string = ''
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private userService: UserService,
    private libraryContentService: LibraryContentService,
    public media: MediaMatcher,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.hideTags = this.isDesktop ? false : true
    // landing level
    if (!this.cateId && !this.subCateId) {
      // Jesus Marie Joseph en patins à glace il est 1h du mat' ich bin kaputt
      setTimeout(() => {
        this.sideMenuCateTags = this.baseCategories
        this.keywordTags = this.baseTags
      }, 0)
    }
    // 2nd level - cates and tags from parents or itself
    if (this.cateId && !this.subCateId) {
      if (this.category?.contains_sub_category) {
        this.sideMenuCateTags = [...this.category?.main_categories, ...this.category?.secondary_categories]
      } else {
        this.sideMenuCateTags = this.baseCategories
      }
      if (this.category?.tags?.length > 0) {
        this.keywordTags = this.category?.tags
      } else {
        this.keywordTags = this.baseTags
      }
      // Jesus Marie Joseph en patins à glace il est 2h du mat' ich bin kaputt x2
      if (this.baseCategories && this.baseCategories.length) {
        setTimeout(() => {
          this.sideMenuCateTags = this.baseCategories
          this.keywordTags = this.baseTags
        }, 0)
      }
    }

    // 3rd level - cates from parents, tags from parents or itself
    if (this.subCateId) {
      this.libraryContentService.getArticleGroupFromBackEnd(this.cateId).subscribe((parentCate: any) => {
        this.sideMenuCateTags = [...parentCate.main_categories, ...parentCate.secondary_categories]
        if (this.category?.tags?.length > 0) {
          this.keywordTags = this.category?.tags
        } else if (parentCate.tags) {
          this.keywordTags = parentCate.tags
        } else {
          this.keywordTags = this.baseTags
        }
      })
    }

    if (this.cateId) {
      this.mainPathSegment = this.activatedRoute.snapshot.paramMap.get('slugCategoryId')
    }
    // from tag, use previous page tags and cates
    if (this.router.url.includes('tag')) {
      this.libraryContentService.prevTags$.pipe(
        tap(prevTags => {
          this.sideMenuCateTags = prevTags[0]
          this.keywordTags = prevTags[1]
        }),
        switchMap(() => this.libraryContentService.prevPage$),
      ).subscribe(page => this.prevPage = page)
    }
  }

  showTagsOnMobile() {
    this.hideTags = !this.hideTags
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }
}
