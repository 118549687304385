import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { PendoButtonCtaDirective } from '../../Pendo/directives/pendo-button-cta.directive'
import { ClickOutsideElementModule } from '../../directives/click-outside-element/click-outside-element.module'
import { TrendingSearchComponent } from './trending-search.component'



@NgModule({
  declarations: [TrendingSearchComponent],
  imports: [
    CommonModule,
    ClickOutsideElementModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    PendoButtonCtaDirective,
  ],
  exports: [
    TrendingSearchComponent,
  ],
})
export class TrendingSearchModule { }
