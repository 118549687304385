import { ElementRef } from '@angular/core'


export abstract class AbstractPendoCta{
  ctaName: string
  element: ElementRef

  constructor(element: ElementRef, ctaName: string){
    this.element = element
    this.ctaName = ctaName
  }

  abstract generateClass(link: string | string[]): string | string[]
  abstract applyClass(content: string | string[]): void
}

export class BasePendoCta extends AbstractPendoCta{
  generateClass(content: string | string[]): string | string[] {
    content = ( typeof content ) === 'string' ? content as string : (content as string[]).filter(routerPart => !routerPart.includes('.')).join('-')

    content = content.split('/')
    .filter(redirectionPart => !redirectionPart.includes('.'))
    .join('-')
    .replace(/[-\s]+/g, '-')

    return [`Pendo-${this.ctaName}-cta-${content}`]
  }

  applyClass(content: string | string[]): void {
    this.element.nativeElement.classList.add(...this.generateClass(content))
  }
}
