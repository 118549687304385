import { CommonModule } from '@angular/common'
import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'lib-radio-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-indicator.component.html',
})
export class RadioIndicatorComponent {
  @HostBinding('class') get theme() {
    return this.inverted ? 'inverted' : 'default'
  }

  @Input() inverted: boolean = false

  @Input({ required: true }) checked: boolean = false
}
