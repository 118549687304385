import { inject } from '@angular/core'
import { CanActivateFn, CanMatchFn, Router } from '@angular/router'
import { map, of, switchMap } from 'rxjs'
import { GenericFormService } from 'src/app/generic-form/services/generic-form.service'
import { UserService } from 'src/app/misc/services/user/user.service'
import { USER_EXTRA_INFO_INTERNAL_ID } from 'src/app/user-extra-info/user-extra-info.component'


export const CanMatchUserExtraInfo = (): CanMatchFn & CanActivateFn => {
  return () => {

    const genericFormService = inject(GenericFormService)
    const userService = inject(UserService)
    const router = inject(Router)

    return userService.user$.pipe(
      switchMap(user => {

        if (user.anonymous) {
          return of(router.createUrlTree(['/public']))
        }

        return genericFormService.getFormByInternalId(USER_EXTRA_INFO_INTERNAL_ID).pipe(
          map(forms => {
            const skipGuard = user.onboarding_done || !user.need_accept_terms || forms[0]?.latest_answer_status === 'finished'
            return skipGuard || router.createUrlTree(['/user-extra-info'])
          }),
        )
      }),
    )
  }
}

