import { EnvironmentInjector, inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router'
import { Observable, firstValueFrom } from 'rxjs'

export const sequentialGuardFn = (guards: (CanActivateFn)[]) => {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const injector = inject(EnvironmentInjector)

    const getResult = async (execution: Promise<unknown> | Observable<unknown> | unknown) => {
      if (execution instanceof Promise) {
        return await execution
      }

      if (execution instanceof Observable) {
        return await firstValueFrom(execution)
      }

      return execution
    }


    for (const guard of guards) {


      const guardExecution = injector.runInContext(() => guard(route, state))
      const result = await getResult(guardExecution)

      if (result !== true) {
        return result
      }
    }

    return true
  }
}
