
import { BreakpointObserver } from '@angular/cdk/layout'
import { Location } from '@angular/common'
import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, of, Subject, Subscription, timer } from 'rxjs'
import { map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { UserService } from 'src/app/misc/services/user/user.service'
import { snakeToCamel } from 'src/app/shared/helpers/snake-to-camel'
import { ArticleInteractionEvent, ArticleService } from 'src/app/shared/services/article.service'
import { ContentfulService } from 'src/app/shared/services/contentful.service'
import { Article, ArticleData, ArticlePathSegments } from '../../../shared/interfaces/articles'
import { MetaService } from '../../../shared/meta/meta.service'
import { LibraryContentService } from '../../services/library-content.service'
import { BreadcrumbItem } from '../breadcrumb/breadcrumb.component'

@Component({
  selector: 'app-single-page-article',
  templateUrl: './single-page-article.component.html',
  styleUrls: ['./single-page-article.component.sass'],
})

export class SinglePageArticleComponent implements OnInit, OnDestroy {
  @ViewChildren('i') allSections: QueryList<any>
  @ViewChild('btnBackToTop') btnBackToTop: ElementRef<HTMLButtonElement>

  tabIsFocus = true
  currentArticle: SinglePageArticleData
  ready: boolean = false
  reachBottom: boolean = false
  cancelSub$ = new Subject()
  allSub: Subscription = new Subscription()
  allData: any = {}
  keys: any = {
    Entry: 'entries',
    Asset: 'assets',
  }
  isScrolling

  user$ = this.userService.user$

  feedback: any = null
  currentUrl = location.href
  emailMessageContent: string

  breadcrumbItems: BreadcrumbItem[] = []

  constructor(
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private contentfulService: ContentfulService,
    public router: Router,
    private bp: BreakpointObserver,
    private libraryContentService: LibraryContentService,
    private userService: UserService,
    public routerLocation: Location,
    private metaService: MetaService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (!this.route?.snapshot?.queryParams?.preview && (!this.router.url.includes('/resources/view/') && !this.router.url.includes('/coach/'))) {
      this.updateResourceInteration('close')
      this.cancelSub$.next(true)
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange({ returnValue }) {
    this.tabIsFocus = returnValue
  }

  @HostListener('window:scroll', ['$event'])
  onScrollWindow(event: any): void {
    this.checkIsOngoing()
    this.displayScrollButton()
  }

  private checkIsOngoing() {
    if (!this.route?.snapshot?.queryParams?.preview && (!this.router.url.includes('/resources/view/') && !this.router.url.includes('/coach/'))) {
      const section = document.getElementById('container-article')
      if (section) {
        const offset = section?.getBoundingClientRect().top - section?.offsetParent?.getBoundingClientRect().top
        const top = window.pageYOffset + window.innerHeight - offset
        if ((top >= section.scrollHeight) && !this.reachBottom) {
          this.reachBottom = true
          this.updateResourceInteration('ongoing')
        }
      }
    }
  }

  updateResourceInteration(event: ArticleInteractionEvent): void {
    if (!this.route?.snapshot?.queryParams?.preview && this.ready && this.tabIsFocus) {
      this.articleService.updateResourcesInteraction(this.allData.article_id, {
        type: event,
        viewed_completely: this.reachBottom,
      }).subscribe()
    }
  }

  private displayScrollButton() {
    if (!this.bp.isMatched('(min-width: 1025px)')) {
      window.clearTimeout(this.isScrolling)
      const pageTop = window.scrollY
      const pageBottom = window.scrollY + window.innerHeight

      const hideBtn = () => {
        if (pageBottom < 36000) {
          this.btnBackToTop.nativeElement.style.visibility = 'hidden'
        }
      }

      if (scroll) {
        if (pageTop > 300) {
          this.btnBackToTop.nativeElement.style.visibility = 'visible'
          this.isScrolling = setTimeout(hideBtn, 5000)
        } else {
          this.btnBackToTop.nativeElement.style.visibility = 'hidden'
        }
      }
    }
  }

  fillObj(obj: any = {}, from?, depth = 0) {
    if (depth < 7) {
      if (Object.values(obj).find((e: any) => e && (((typeof e === 'object') && ('sys' in e || 'linkType' in e) && !e?.defined) || Array.isArray(e) && e.length),
      )) {
        for (const property in obj) {
          if (Array.isArray(obj[property]) && obj[property].length) {
            obj[property].forEach((element: any, index: number) => {
              obj[property][index] = this.fillObj(element, true, depth + 1)
            })
          }
          if (!from) { // obj
            if (typeof obj[property] === 'object') {
              if ('sys' in obj[property] && !('fields' in obj[property])) {
                obj[property] = { ...obj[property], fields: {} }
                obj[property]['fields'] = obj[property].sys?.id === this.allData?.article_id ? this.allData.data?.article_data : this.fillObj(this.allData.data[this.keys[obj[property].sys?.linkType]][obj[property].sys?.id], false, depth + 1)
                obj[property].sys['contentType'] = { sys: { id: obj[property]['fields']?.type } }
                obj[property].sys['defined'] = true
              }
            }
          } else {
            if (typeof obj === 'object') {
              if ('sys' in obj && !('fields' in obj)) {
                obj = { ...obj, fields: {} }
                obj['fields'] = obj?.sys?.id === this.allData?.article_id ? this.allData.data?.article_data : this.fillObj(this.allData.data[this.keys[obj?.sys?.linkType]][obj?.sys?.id], false, depth + 1)
                obj.sys['contentType'] = { sys: { id: obj['fields']?.type } }
                obj.sys['defined'] = true
              }
            }
          }
        }
      }
    }
    return obj
  }

  ngOnInit(): void {
    const artId = this.route.snapshot.data['articleId']
    this.allSub.add(
      this.route.params.pipe(
        tap(() => {
          this.ready = false
          this.currentArticle = null
        }),
        switchMap(() => this.userService.user$),
        switchMap((user: any) => {
          if (this.route?.snapshot?.queryParams?.preview) {
            this.cancelSub$.next(true)
          }
          return combineLatest([
            (this.route?.snapshot?.queryParams?.preview ? this.libraryContentService.getArticlePreview(artId) : this.contentfulService.getArticleInfoFromBackEnd(artId)),
            of(user.anonymous),
          ])
        }),
        map(([articleData, userIsAnonymous]) => {
          this.allData = articleData
          articleData = { ...articleData, article_data: this.fillObj(this.allData?.data?.article_data), userIsAnon: userIsAnonymous }
          this.contentfulService.setCurrentArticle(this.allData)
          this.setFeedback(this.allData.user_feedback)
          return articleData
        }),
      ).subscribe((articleData: SinglePageArticleData) => {
        this.checkAndUpdateUrl(articleData)
        this.loadEmailBody()
        this.currentArticle = articleData
        this.currentArticle.article_data.excerpt = this.contentfulService.transformHTMLEntities(this.currentArticle?.article_data?.excerpt, [{ entity: '&nbsp;', value: '\n' }])
        this.setMetaData(articleData)
        this.buildBreadcrumbItems()
        this.ready = true
        this.updateResourceInteration('open')
      }),
    )

    this.allSub.add(timer(0, 120000).pipe(
      tap(() => {
        this.updateResourceInteration('ongoing')
      }),
      takeUntil(this.cancelSub$),
    ).subscribe())
  }



  scrollToSection(index) {
    const section = this.allSections['_results'][index].nativeElement
    const headerOffset = 70
    const sectionPosition = section.getBoundingClientRect().top
    const offsetPosition = sectionPosition + window.scrollY - headerOffset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  ngOnDestroy(): void {
    this.allSub.unsubscribe()
    if (!this.route?.snapshot?.queryParams?.preview && (!this.router.url.includes('/resources/view/') && !this.router.url.includes('/coach/'))) {
      this.updateResourceInteration('close')
      this.cancelSub$.next(true)
      this.contentfulService.setCurrentArticle(null)
    }
  }

  changeBookmark(article: any) {
    if (!article.bookmarked) {
      this.allSub.add(this.articleService.addArticleToBookmark(article?.article_data?.id).subscribe(_ => article.bookmarked = true))
    } else {
      this.allSub.add(this.articleService.removeArticleFromBookmark(article?.article_data?.id).subscribe(_ => article.bookmarked = false))
    }
  }

  private setFeedback(rawFeedback: { rating: number, comment: string, choices: string[] }) {
    rawFeedback = rawFeedback ? rawFeedback : { rating: 0, comment: '', choices: [] }
    this.feedback = rawFeedback

    for (const string of rawFeedback.choices) {
      this.feedback[snakeToCamel(string)] = true
    }
  }

  setPH(i: number): Array<any> {
    return [{ rang: 0, position: i, type: 'sections', bg: i % 3 === 0 ? '#5E7D6C' : i % 3 === 1 ? '#EDEFEC' : '#D6D6D6' }]
  }

  buildBreadcrumbItems(): void {
    if (!this.currentArticle?.breadcrumb_library_category) return


    this.breadcrumbItems = this.currentArticle?.breadcrumb_library_category?.map(({ name, path_segment }) => {
      return { title: name, url: `/categories/${path_segment}` }
    })

  }

  private checkAndUpdateUrl(article: SinglePageArticleData) {

    if (article.path_segments && !this.currentUrl.includes(article.path_segments[this.locale])) {
      this.routerLocation.replaceState(`articles/${article.path_segments[this.locale]}`)
      this.currentUrl = location.origin + this.routerLocation.path()
    }
  }

  private loadEmailBody() {

    const emailBody = $localize`Bonjour,\nJe crois que cet article de l’application Luci pourrait t’intéresser.\nVoici le lien : ${this.currentUrl}\nBonne journée!`

    this.emailMessageContent = encodeURIComponent(emailBody)
  }

  setMetaData(article_data: SinglePageArticleData) {

    if (!article_data.path_segments) return

    this.metaService.updatePageMetadata({
      title: article_data.article_data.title,
      description: article_data.article_data.metadescription || article_data.article_data.excerpt,
      type: 'article',
      thumbnailUrl: article_data.article_data.image.fields.file.url,
      links: {
        fr: `articles/${article_data.path_segments.fr}`,
        en: `articles/${article_data.path_segments.en}`,
      },
    })
  }
}


type SinglePageArticleData = Article & {
  article_data: ArticleData
  userIsAnon: boolean
  breadcrumb_library_category: { name: string, path_segment: string }[] | undefined
  path_segments: ArticlePathSegments
  tags: string[]
}
