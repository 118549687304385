import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { CopyLinkComponent } from './copy-link.component'



@NgModule({
  declarations: [CopyLinkComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports:[
    CopyLinkComponent,
  ],
})
export class CopyLinkModule { }
