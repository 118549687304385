import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { UikitModule } from '@ui'
import { PendoButtonCtaDirective } from '../shared/Pendo/directives/pendo-button-cta.directive'
import { PendoHrefCtaDirective } from '../shared/Pendo/directives/pendo-href-cta.directive'
import { PendoRouterLinkCtaDirective } from '../shared/Pendo/directives/pendo-routerlink-cta.directive'
import { PENDO_MODULE_CONTEXT } from '../shared/Pendo/pendo-injection-token'
import { CheckboxSquareLabeledModule } from '../shared/components/checkbox-square/checkbox-square.module'
import { LabelForSquareCheckboxModule } from '../shared/components/label-for-square-checkbox/label-for-square-checkbox.module'
import { RecommendedForAnonymModule } from '../shared/components/recommended-for-anonym/recommended-for-anonym.module'
import { ShareMethodsContainerModule } from '../shared/components/share-methods-container/share-methods-container.module'
import { StarRatingComponent } from '../shared/components/star-rating/star-rating.component'
import { TagComponent } from '../shared/components/tag/tag.component'
import { TrendingSearchModule } from '../shared/components/trending-search/trending-search.module'
import { ContentfulArticleToArticlePipe } from '../shared/pipes/content-ful-article-to-article.pipe'
import { SharedModule } from '../shared/shared.module'
import { ArticlesCategoryComponent } from './components/articles-category/articles-category.component'
import { ArticlesListComponent } from './components/articles-list/articles-list.component'
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { CategorySearchComponent } from './components/category-search/category-search.component'
import { CategoryComponent } from './components/category/category.component'
import { ContainerImageBlockComponent } from './components/container-block/container-image-block/container-image-block.component'
import { SectionBlockComponent } from './components/container-block/section-block/section-block.component'
import { TwoColumnsComponent } from './components/container-block/two-columns/two-columns.component'
import { ArticleSectionBlockComponent } from './components/content-block/article-section-block/article-section-block.component'
import { DisclaimerBlockComponent } from './components/content-block/disclaimer-block/disclaimer-block.component'
import { FramesComponent } from './components/content-block/frames/frames.component'
import { ImageBlockComponent } from './components/content-block/image-block/image-block.component'
import { TableBlockComponent } from './components/content-block/table-block/table-block.component'
import { TextBlockComponent } from './components/content-block/text-block/text-block.component'
import { CtaCreateAccountComponent } from './components/cta-create-account/cta-create-account.component'
import { ExternalArticleComponent } from './components/external-article/external-article.component'
import { LibraryArticleCardComponent } from './components/library-article-card/library-article-card.component'
import { LibrarySubmenuComponent } from './components/library-submenu/library-submenu.component'
import { ListCatComponent } from './components/list-cat/list-cat.component'
import { NewBreadcrumbComponent } from './components/new-breadcrumb/new-breadcrumb.component'
import { RecentListComponent } from './components/recent-list/recent-list.component'
import { RecomListComponent } from './components/recom-list/recom-list.component'
import { SavedListComponent } from './components/saved-list/saved-list.component'
import { ArticleAudioComponent } from './components/single-page-article/audio/audio.component'
import { RatingArticleComponent } from './components/single-page-article/rating-article/rating-article.component'
import { SinglePageArticleComponent } from './components/single-page-article/single-page-article.component'
import { ReferenceBlockComponent } from './components/special-block/reference-block/reference-block.component'
import { TagsListComponent } from './components/tags-list/tags-list.component'


@NgModule({
  declarations: [
    CategoryComponent,
    CategorySearchComponent,
    SinglePageArticleComponent,
    ArticlesListComponent,
    TwoColumnsComponent,
    FramesComponent,
    TextBlockComponent,
    SectionBlockComponent,
    ImageBlockComponent,
    ContainerImageBlockComponent,
    ArticleSectionBlockComponent,
    DisclaimerBlockComponent,
    ReferenceBlockComponent,
    TableBlockComponent,
    BreadcrumbComponent,
    RecomListComponent,
    LibrarySubmenuComponent,
    TagsListComponent,
    ArticlesCategoryComponent,
    SavedListComponent,
    RecentListComponent,
    RatingArticleComponent,
    ListCatComponent,
    NewBreadcrumbComponent,
    CtaCreateAccountComponent,
    LibraryArticleCardComponent,
    ExternalArticleComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ShareMethodsContainerModule,
    TrendingSearchModule,
    CheckboxSquareLabeledModule,
    LabelForSquareCheckboxModule,
    RecommendedForAnonymModule,
    StarRatingComponent,
    PendoRouterLinkCtaDirective,
    PendoHrefCtaDirective,
    PendoButtonCtaDirective,
    ContentfulArticleToArticlePipe,
    TagComponent,
    ArticleAudioComponent,
    RouterModule,
    UikitModule,
  ],
  providers: [
    {
      provide: PENDO_MODULE_CONTEXT,
      useValue: 'library',
    },
    TagComponent,
  ],
})
export class ArticleLibraryModule { }
