<button *ngIf='!showPlayer; else playerTemplate' (click)='showPlayer=true' role='button'
  aria-labelledby='listen-to-this-article' class='flex items-center gap-2' pendoClass='article_audio_enable_player'>
  <mat-icon class='rounded-full bg-green-500 p-4 !w-14 !h-14 text-white'>volume_up</mat-icon>
  <span id="listen-to-this-article" class='text-green-500 font-sans font-medium underline' i18n>
    Écouter cet article
  </span>
</button>


<ng-template #playerTemplate>
  <div *ngIf='(link$ | async) as link; else loading' class='border rounded-lg bg-white p-0.5 player'>
    <audio #audioPlayer>
      <source [src]="link" type="audio/mp3" />
    </audio>
  </div>
</ng-template>

<ng-template #loading>
  <mat-spinner color='primary' diameter='60'></mat-spinner>
</ng-template>
