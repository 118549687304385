import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'projects/environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ActionPlan, ActionPlanChoice } from 'src/app/shared/interfaces/action-plan'
import { PaginatedObject } from 'src/app/shared/interfaces/paginated-object'

@Injectable({
  providedIn: 'root',
})
export class ActionPlanService {
  actionPlan: ActionPlan[]
  _allObjectivesIds = new BehaviorSubject([])
  allObjectivesIds$ = this._allObjectivesIds.asObservable()

  constructor(private http: HttpClient) { }

  setAllObjectivesIds(ids) {
    this._allObjectivesIds.next(ids)
  }

  getAllActionPlan() {
    return this.http.get<PaginatedObject<ActionPlan>>(`${environment.apiUrl}action_plan/`).pipe(
      tap(({ results }) => this.actionPlan = results),
    )
  }

  confirmReadOfArticle(obj) {
    return this.http.post(`${environment.apiUrl}resources/assignments/`, obj)
  }

  getActionPlanChoices() {
    return this.http.get<ActionPlanChoice>(`${environment.apiUrl}action_plan_choices/`)
  }

  getResourceActionPlan(id) {
    return this.http.get(`${environment.apiUrl}resources/?action_plan=${id}`)
  }

  getDatesHistory(actionPlanId) {
    return this.http.get(`${environment.apiUrl}action_plan/${actionPlanId}/history_dates/`)
  }

  getDetailHistoryByDate(actionPlanId, date) {
    return this.http.get(`${environment.apiUrl}action_plan/${actionPlanId}/changes/?date=${date}`)
  }

  getAllObjectives() {
    return this.http.get<PaginatedObject<any>>(`${environment.apiUrl}objectives/`)
  }

  getAllObjectivesNext(el) {
    return this.http.get(el)
  }

  getOneObjectiveDetails(id) {
    return this.http.get(`${environment.apiUrl}objectives/${id}/`)
  }

  getObjectivesByFilter(domain) {
    return this.http.get(`${environment.apiUrl}objectives/?domain=${domain}`)
  }

  getAllMonitoringsOfOneObjective(id) {
    return this.http.get(`${environment.apiUrl}objectives/${id}/monitorings/`)
  }

  getObjectivesHistory(id) {
    return this.http.get(`${environment.apiUrl}objectives/${id}/changes/`)
  }

  updateObjective(objective, id, userId) {
    return this.http.put(`${environment.apiUrl}objectives/${id}/?user=${userId}`, objective)
  }

  updateMonitoring(monitoring, id, userId?) {
    if (userId) {
      return this.http.post(`${environment.apiUrl}objectives/${id}/monitoring/?user=${userId}`, monitoring)
    } else {
      return this.http.post(`${environment.apiUrl}objectives/${id}/monitoring/`, monitoring)
    }
  }

  updateTodoTask(id, taskId, completed) {
    return this.http.patch(`${environment.apiUrl}objectives/${id}/task/`, {
      id: taskId,
      completed,
    })
  }

  getConstants() {
    return this.http.get(`${environment.apiUrl}objectives/constants/`)
  }

  getActionPlanAsLabels(): Observable<{
    frequencyList: any,
    momentsByDomainList: any,
    quantityUnitByDomainList: any
    obstaclesOptionsList: any
    domainCategories: any
  }> {

    const frequencyList = {}
    const momentsByDomainList = {}
    const quantityUnitByDomainList = {}
    const obstaclesOptionsList = {}
    const domainCats = {}


    return this.getActionPlanChoices().pipe(
      map(res => {
        res['frequency_unit'].map(fre => {
          frequencyList[fre.label] = fre.name
        })

        for (const [key, values] of Object.entries(res['moments_by_domain'])) {
          const valuesArray: any = values
          valuesArray.forEach(val => {
            momentsByDomainList[val.title] = val.description
          })
        }

        for (const [key, values] of Object.entries(res['quantity_unit_by_domain'])) {
          const valuesArray: any = values
          valuesArray.forEach(val => {
            quantityUnitByDomainList[val.label] = val.name
          })
        }

        for (const [key, values] of Object.entries(res['obstacles'])) {
          const valuesArray: any = values
          valuesArray.forEach(val => {
            obstaclesOptionsList[val.id] = val.label
          })
        }



        res['domain'].map(domain => {
          const categoryById = {}
          const iconById = {}
          domain.categories.map(category => {
            categoryById[category.id] = category.label
            iconById[category.id] = category.icon
          })

          domainCats[domain.id] = {
            categories: domain.categories,
            categoriesLabel: categoryById,
            categoriesIcon: iconById,
            label: domain.label,
            difficulty: domain.difficulty_intensity.options,
          }
        })

        return {
          frequencyList: frequencyList,
          momentsByDomainList: momentsByDomainList,
          domainCategories: domainCats,
          quantityUnitByDomainList: quantityUnitByDomainList,
          obstaclesOptionsList: obstaclesOptionsList,
        }
      }),
    )
  }
}
