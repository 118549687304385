import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from 'environment/environment'
import { Observable, map } from 'rxjs'

interface IArticleLink {
  link: {
    url: string
    expires_at: Date
  }
}

@Injectable({
  providedIn: 'root',
})
export class ArticleAudioService {
  private httpClient = inject(HttpClient)

  public getLink(articleId: string): Observable<string> {
    return this.httpClient.get<IArticleLink>(`${environment.apiUrl}articles/${articleId}/audios/`).pipe(map(result => result.link.url))
  }
}
