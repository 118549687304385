import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'
import { Observable, map, of, switchMap } from 'rxjs'
import { UserService } from 'src/app/misc/services/user/user.service'
/**
 * If it's the first time a login user navigate to the library
 * the libraryExplored field is updated
 * else return true
 */
export const UserConsultedLibraryResolverFn: ResolveFn<Observable<boolean> | boolean> = () => {

  const userServ = inject(UserService)

  return userServ.user$.pipe(
    switchMap(user => {
      if (user.fe_flags?.libraryExplored || user.anonymous) {
        return of(true)
      }
      return userServ.updateUser({ fe_flags: { libraryExplored: true } }, { showSuccessSnack: false }).pipe(
        map(() => true),
      )
    }),
  )

}
